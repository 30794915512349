define('candidio-app/controllers/admin/templates/industries/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    name: null,
    description: null,

    actions: {
      saveIndustry: function saveIndustry(callback) {
        var self = this,
            data = this.getProperties('name', 'description', 'isPublished');

        var industry = this.store.createRecord('industry', data);

        var promise = industry.save();

        callback(promise);

        return promise.then(function () {
          self.setProperties({
            name: null,
            description: null,
            isPublished: false
          });
          self.messages.showSuccess('New industry created');
          self.transitionToRoute('admin.templates.industries');
        }).catch(function (errors) {
          self.messages.showAPIError(errors);
        });
      }
    }
  });
});