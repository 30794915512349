define('candidio-app/controllers/admin/workspaces/workspace/users', ['exports', 'candidio-app/mixins/pagination-controller'], function (exports, _paginationController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationController.default, {
    users: Ember.computed.oneWay('model'),

    workspace: null,

    loadMoreFunction: function loadMoreFunction(paginationSettings) {
      var workspace = this.get('workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.query('user', paginationSettings);
    }
  });
});