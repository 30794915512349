define('candidio-app/routes/admin/productions/production', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'candidio-app/utils/candidio-paths'], function (exports, _authenticatedRouteMixin, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken(model) {
      return model.get('title');
    },


    adapterContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    current: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var includes = ['actions.user', 'brief', 'assets', 'workspace', 'created_by', 'video_series.assets', 'deliverables.assets', 'deliverables.created_by', 'descriptions.assets', 'revisions.assets', 'revisions.deliverables.assets', 'collaborators', 'template'].join(',');

      var url = (0, _candidioPaths.default)().url.api('productions', params.production_id) + '?include=' + includes;

      return this.get('ajax').request(url, {
        type: 'GET'
      }).then(function (response) {
        response['production'] = response.data;
        delete response.data;
        _this.store.pushPayload(response);
        return _this.store.findRecord('production', response.production.id);
      });
    },

    afterModel: function afterModel() {
      var _this2 = this;

      this.get('adapterContext').resetContext();
      return this.store.query('template', { limit: 1000, include: 'workspace,industry' }).then(function (templates) {
        return _this2.set('templates', templates);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('templates', this.get('templates'));
    },
    activate: function activate() {
      var production = this.modelFor(this.routeName);

      var _production$getProper = production.getProperties('workspace', 'videoSeries'),
          workspace = _production$getProper.workspace,
          videoSeries = _production$getProper.videoSeries;

      this.get('current').setCurrent({ workspace: workspace, videoSeries: videoSeries, production: production });
    },
    deactivate: function deactivate() {
      this.get('current').setCurrent();
    },


    actions: {
      deleteProduction: function deleteProduction() {
        var _this3 = this;

        var production = this.modelFor(this.routeName);
        var workspace = production.get('workspace');
        var videoSeries = production.get('videoSeries');
        var shouldDelete = confirm('Sure you want to delete this video? Can\'t go back.');
        if (shouldDelete) {
          production.destroyRecord().then(function () {
            _this3.messages.showSuccess('Video deleted!');
            _this3.transitionTo('admin.workspaces.workspace.video-series.video-series', workspace, videoSeries);
          }).catch(function (error) {
            return _this3.messages.showError(error);
          });
        }
      },
      deliverDeliverable: function deliverDeliverable(callback, deliverable) {
        var _this4 = this;

        deliverable.set('isDelivered', true);
        return callback(deliverable.save().then(function () {
          return _this4.messages.showSuccess('Sending an email to the Producer!');
        }).catch(function (errors) {
          return _this4.messages.showAPIError(errors);
        }));
      },
      uploadToProductionDeliverable: function uploadToProductionDeliverable(files, deliverable) {
        var _this5 = this;

        return this._createProductionDeliverable(deliverable).then(function (deliverable) {
          return _this5._uploadFiles(files, deliverable);
        });
      },
      uploadToRevisionDeliverable: function uploadToRevisionDeliverable(files, deliverable) {
        var _this6 = this;

        return this._createRevisionDeliverable(deliverable).then(function (deliverable) {
          return _this6._uploadFiles(files, deliverable);
        });
      },
      updateDeliverableStatus: function updateDeliverableStatus(callback, deliverable, newStatus) {
        var _this7 = this;

        return callback(deliverable.set('status', newStatus).save().then(function (model) {
          return model.set('reviewedBy', _this7.get('current.user'));
        }).catch(function (errors) {
          return _this7.messages.showAPIError(errors);
        }));
      },
      saveDeliverable: function saveDeliverable(deliverable) {
        var _this8 = this;

        return deliverable.save().then(function () {
          return _this8.messages.showSuccess('Notes for Producer saved!');
        }).catch(function (errors) {
          return _this8.messages.showAPIError(errors);
        });
      },
      deleteAsset: function deleteAsset(asset) {
        return this._deleteAsset(asset);
      },
      toggleSubmitted: function toggleSubmitted(submitted) {
        return this._toggleSubmitted(submitted);
      }
    },

    _createProductionDeliverable: function _createProductionDeliverable(deliverable) {
      var _this9 = this;

      if (Ember.isPresent(deliverable)) {
        return Ember.RSVP.resolve(deliverable);
      }

      deliverable = this.store.createRecord('deliverable', {
        production: this.modelFor(this.routeName),
        createdBy: this.get('current.user')
      });

      this.get('adapterContext').resetContext();
      return deliverable.save().catch(function (errors) {
        return _this9.messages.showAPIError(errors);
      });
    },
    _createRevisionDeliverable: function _createRevisionDeliverable(deliverable) {
      var _this10 = this;

      if (Ember.isPresent(deliverable)) {
        return Ember.RSVP.resolve(deliverable);
      }

      deliverable = this.store.createRecord('deliverable', {
        production: this.modelFor(this.routeName),
        revision: this._getLastClosedRevision(),
        createdBy: this.get('current.user')
      });

      this.get('adapterContext').resetContext();
      return deliverable.save().catch(function (errors) {
        return _this10.messages.showAPIError(errors);
      });
    },
    _deleteAsset: function _deleteAsset(asset) {
      var _this11 = this;

      return asset.destroyRecord().then(function () {
        return _this11.messages.showSuccess('Asset deleted');
      }).catch(function (errors) {
        _this11.messages.showAPIError(errors);
      });
    },
    _uploadFiles: function _uploadFiles(files, deliverable) {
      var _this12 = this;

      return _.each(files, function (file) {
        return _this12._uploadFile(file, deliverable);
      });
    },
    _uploadFile: function _uploadFile(file, deliverable) {
      var _this13 = this;

      var production = this.modelFor(this.routeName);
      var path = production.get('workspace.id') + '/' + production.get('id') + '/assets';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, { model: deliverable }, {
        onComplete: function onComplete(res) {
          return _this13._completeFileUpload(res, deliverable);
        },
        onError: function onError() {
          return _this13.messages.showError('Error uploading file.');
        }
      });
    },
    _completeFileUpload: function _completeFileUpload(payload, deliverable) {
      var _this14 = this;

      var asset = this.store.createRecord('asset', {
        bucket: payload.bucket,
        fileName: payload.file_name,
        fileType: payload.file_type,
        objectKey: payload.object_key,
        size: payload.size,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype,
        isCompletedProduction: true,
        deliverable: deliverable,
        workspace: deliverable.get('production.workspace')
      });

      this.get('adapterContext').resetContext();
      return asset.save().catch(function (errors) {
        return _this14.messages.showAPIError(errors);
      });
    },
    _getLastClosedRevision: function _getLastClosedRevision() {
      return this.controller.get('latestClosedRevision');
    },
    _toggleSubmitted: function _toggleSubmitted(submitted) {
      var _this15 = this;

      var production = this.modelFor(this.routeName);
      production.set('isSubmitted', submitted);
      this.get('adapterContext').resetContext();
      return production.save().catch(function (errors) {
        return _this15.messages.showAPIError(errors);
      });
    }
  });
});