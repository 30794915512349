define('candidio-app/components/video-production-phase-badge', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var VideoProductionPhaseBadge = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.phaseLookup = {
        'development': 'Development',
        'pre-production': 'Pre-Production',
        'production': 'Production',
        'post-production': 'Post-Production',
        'distribution': 'Distribution'
      };
    },


    attributeBindings: ['title'],
    classNames: ['c-badge'],

    formattedIcon: Ember.computed('phase', {
      get: function get() {
        return 'phase-small-' + this.get('phase');
      }
    }),

    formattedPhase: Ember.computed('phase', {
      get: function get() {
        return this.get('phaseLookup.' + this.get('phase'));
      }
    }),

    title: Ember.computed('formattedPhase', {
      get: function get() {
        return 'This video is in the ' + this.get('formattedPhase') + ' phase';
      }
    })
  });

  VideoProductionPhaseBadge.reopenClass({
    positionalParams: ['phase']
  });

  exports.default = VideoProductionPhaseBadge;
});