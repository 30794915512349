define('candidio-app/components/navigation-drawer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    navigation: Ember.inject.service(),

    classNames: ['c__navigation-drawer'],
    classNameBindings: ['navigation.isVisible:is-visible'],

    click: function click() {
      this.send('hideNav');
    },


    actions: {
      hideNav: function hideNav() {
        this.get('navigation').hideNav();
      }
    }
  });
});