define('candidio-app/components/shot-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    description: null,

    hasEditingInstructions: Ember.computed.notEmpty('description.fields.field2'),
    showEditingInstructions: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('hasEditingInstructions')) {
        this.set('showEditingInstructions', true);
      }

      /** Init the jQuery UI Droppable widget */
      this.$().droppable({
        accept: '.c__asset-preview',
        hoverClass: 'is-dragging-over',
        drop: Ember.run.bind(this, this.handleDrop)
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      /** Destroy the droppable widget */
      this.$().droppable('destroy');
    },
    handleDrop: function handleDrop(e, ui) {
      e.preventDefault(); // stops for browser from redirecting - should be stopPropagation()?
      var description = this.get('description');
      var assetId = Ember.$(ui.draggable).data('asset').get('id');
      return this.get('assetDropped')(assetId, description);
    },


    actions: {
      toggleEditingInstructions: function toggleEditingInstructions() {
        this.toggleProperty('showEditingInstructions');
      }
    }
  });
});