define('candidio-app/mixins/description-sorting', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var GAP = 1024;

  exports.default = Ember.Mixin.create({

    /**
     * Stores a read-only version of the sorted shot array
     * @type {Ember.Array}
     */
    readOnlyShots: Ember.computed('sortedShots.[]', function () {
      return [].concat(_toConsumableArray(this.get('sortedShots')));
    }),

    actions: {

      /**
       * This action should be bound to the ui-sortable moved handler
       * ```
       * {{#ui-sortable moved="(action moveDescription)"}}
       * ```
       */
      moveDescription: function moveDescription(description, oldIndex, newIndex) {
        var shots = this.get('readOnlyShots');

        if (oldIndex === newIndex) {
          return;
        }

        // Now we can easily calculate get the items new siblings
        // and calculate its new position value, without worrying about
        // its own position in the array.

        var _getSiblingComponents = this.getSiblingComponents(shots, oldIndex, newIndex),
            prevItem = _getSiblingComponents.prevItem,
            nextItem = _getSiblingComponents.nextItem;

        var newPos = this.getNewPosition(prevItem, nextItem);

        description.set('position', newPos);
        this.get('saveDescriptionTask').perform(description);
      }
    },

    getSiblingComponents: function getSiblingComponents(shots, oldIndex, newIndex) {
      // Remove item from the read-only array
      shots.removeAt(oldIndex);
      var prevItem = shots.objectAt(newIndex - 1);
      var nextItem = shots.objectAt(newIndex);
      return { prevItem: prevItem, nextItem: nextItem };
    },
    getNewPosition: function getNewPosition(prevItem, nextItem) {
      var prevPos = Ember.isEmpty(prevItem) ? null : Ember.get(prevItem, 'position');
      var nextPos = Ember.isEmpty(nextItem) ? null : Ember.get(nextItem, 'position');

      /** New position is at the beginning of the list */
      if (Ember.isEmpty(prevItem)) {
        return nextPos - GAP;
      }

      /** New position is at the end of the list */
      if (Ember.isEmpty(nextItem)) {
        return prevPos + GAP;
      }

      return (prevPos + nextPos) / 2;
    }
  });
});