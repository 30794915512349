define('candidio-app/routes/admin/workspaces/workspace/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.modelFor('admin.workspaces.workspace');
    },
    activate: function activate() {
      var workspace = this.modelFor(this.routeName);
      this.get('current').setCurrent({ workspace: workspace });
    }
  });
});