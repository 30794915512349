define('candidio-app/routes/admin/workspaces/workspace/video-series/index', ['exports', 'ember-infinity/mixins/route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _route, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, {
    adapterContext: Ember.inject.service(),

    title: function title() {
      return 'Video Series - ' + this.modelFor('admin.workspaces.workspace').get('workspaceName');
    },


    perPageParam: 'limit',
    pageParam: 'page',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace });

      return this.infinityModel('video-series', {
        startingPage: 1,
        perPage: 10,
        sort: 'title',
        direction: 'asc'
      });
    }
  });
});