define('candidio-app/services/file-saver', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    save: function save(fileContents, mimeType, filename) {
      window.saveAs(new Blob([fileContents], { type: mimeType }), filename);
    }
  });
});