define("candidio-app/utils/upload-progress", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = uploadProgress;
  function uploadProgress(loaded, total) {
    return Math.ceil(loaded / total * 100);
  }
});