define('candidio-app/serializers/production', ['exports', 'candidio-app/serializers/application', 'candidio-app/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      videoSeries: { deserialize: 'records', serialize: 'id' },
      brief: { deserialize: 'records', serialize: false },
      actions: { deserialize: 'records', serialize: false },
      assets: { deserialize: 'records', serialize: false },
      deliverables: { deserialize: 'records', serialize: false },
      descriptions: { deserialize: 'records', serialize: false },
      createdBy: { deserialize: 'records', serialize: false },
      collaborators: { deserialize: 'records', serialize: false },
      workspace: { deserialize: 'records', serialize: false },
      revisions: { deserialize: 'records', serialize: false },
      snapshots: { deserialize: 'records', serialize: false },
      template: { deserialize: 'records', serialize: 'id' },
      createdAt: { serialize: false },
      updatedAt: { serialize: false },
      firstSubmittedAt: { serialize: false },
      lastSubmittedAt: { serialize: false },
      phase: { serialize: false },
      type: { serialize: false }
    }

    /**
     * Override keyForRelationship to append `_id` to serialized relationship keys
     */
    // keyForRelationship(key, typeClass, method) {
    //   if (method === 'serialize' &&
    //       this.hasSerializeIdsOption(key) &&
    //       _.includes(['template'], key)
    //   ) {
    //     return `${key}_id`;
    //   }
    //   return this._super(...arguments);
    // }
  });
});