define('candidio-app/models/workspace', ['exports', 'ember-data', 'candidio-app/models/actionable'], function (exports, _emberData, _actionable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _actionable.default.extend({
    // Attributes
    createdAt: attr('moment-date'),
    internalNotes: attr('string'),
    isInvite: attr('boolean', { defaultValue: false }),
    canManage: attr('boolean', { defaultValue: true }),
    phone: attr('string'),
    workspaceName: attr('string'),
    updatedAt: attr('moment-date'),

    // Relationships
    assets: hasMany('assets', { async: false }),
    productions: hasMany('production', { async: false }),
    users: hasMany('user', { async: false }),

    // Computed Properties
    pusherChannel: Ember.computed('id', function () {
      return 'workspace_' + this.get('id');
    }),

    managingUsers: Ember.computed.filterBy('users', 'canManageCurrentWorkspace', true)
  });
});