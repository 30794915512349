define('candidio-app/controllers/reset', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/mixins/validation-engine'], function (exports, _candidioPaths, _validationEngine) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validationEngine.default, {

        ajax: Ember.inject.service(),

        password: '',
        passwordConfirmation: '',
        token: '',
        submitting: false,

        validationType: 'reset',

        actions: {
            submit: function submit() {
                var _this = this;

                var data = this.getProperties('password', 'token');

                this.toggleProperty('submitting');

                this.validate({}).then(function () {
                    _this.get('ajax').request((0, _candidioPaths.default)().url.api('users', 'reset-password'), {
                        type: 'POST',
                        data: JSON.stringify({
                            data: {
                                password: data.password,
                                token: data.token
                            }
                        })
                    }).then(function () {
                        _this.toggleProperty('submitting');
                        _this.messages.showInfo('Success! Your password has been changed. Go ahead and login to your account.');
                        _this.transitionToRoute('login');
                    }).catch(function (errors) {
                        this.messages.showAPIError(errors);
                        this.toggleProperty('submitting');
                    });
                }).catch(function (errors) {
                    _this.toggleProperty('submitting');
                    _this.messages.showErrors(errors);
                });
            }
        }
    });
});