define('candidio-app/controllers/modals/invite-co-producers-to-production', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/utils/format-response-for-push'], function (exports, _candidioPaths, _formatResponseForPush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    tracking: Ember.inject.service(),

    title: Ember.computed('model', function () {
      return 'Share this ' + this.get('model.type');
    }),

    workspace: Ember.computed.alias('model.workspace'),

    users: Ember.computed.alias('workspace.users'),

    init: function init() {
      this._super.apply(this, arguments);
      this.confirm = {
        accept: {
          text: 'Done'
        },
        reject: {
          buttonClass: 'is-hidden'
        }
      };
    },


    actions: {
      goToBilling: function goToBilling() {
        this.send('closeModal');
        this.transitionToRoute('workspace.settings.billing', this.get('workspace'));
      },
      inviteByEmail: function inviteByEmail(callback) {
        var _this = this;

        var email = this.get('email');

        if (!validator.isEmail(email)) {
          this.messages.showError('Whoops! Something is wrong with the email address you entered.');
          return callback(Ember.RSVP.reject());
        }

        var promise = this.inviteByEmail(email).then(function (user) {
          return _this.shareWithUser(user);
        }).finally(function () {
          return _this.set('email', '');
        });

        callback(promise);
        return promise;
      },


      shareWithUser: function shareWithUser(callback, user) {
        var promise = this.shareWithUser(user);
        callback(promise);
        return promise;
      },

      unshareWithUser: function unshareWithUser(callback, user) {
        var promise = this.unshareWithUser(user);
        callback(promise);
        return promise;
      },

      confirmAccept: function confirmAccept() {
        this.send('closeModal');
      },

      confirmReject: function confirmReject() {
        return false;
      }
    },

    inviteByEmail: function inviteByEmail(email) {
      var _this2 = this;

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('workspace.id'), 'users', 'invite'), {
        type: 'POST',
        data: JSON.stringify({
          data: {
            email: email,
            can_manage: false
          }
        })
      }).then(function (res) {
        // Track the event
        _this2.get('tracking').trackEvent({
          event: 'Invited User',
          category: 'workspace',
          action: 'invited-user',
          invitedDate: moment(),
          inviteeEmail: email
        });

        var json = (0, _formatResponseForPush.default)('user', res);
        _this2.store.pushPayload(json);

        var user = _this2.store.getById('user', json.user.id);

        // Add user to the workspace
        _this2.get('workspace.users').pushObject(user);

        return user;
      }).catch(function (errors) {
        return _this2.messages.showAPIError(errors);
      });
    },
    shareWithUser: function shareWithUser(user) {
      var _this3 = this;

      var type = this.get('model.type');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('workspace.id'), 'users', user.get('id'), type + 's', this.get('model.id'), 'attach'), {
        type: 'PUT',
        data: JSON.stringify({
          data: {
            workspace_id: this.get('workspace.id')
          }
        })
      }).then(function () {
        _this3.get('tracking').trackEvent({
          event: 'Shared ' + type.capitalize(),
          category: type,
          action: 'shared',
          sharedWith: user.get('fullName')
        });
        _this3.get('model.sharingUsers').pushObject(user);
      }).catch(function (errors) {
        return _this3.messages.showAPIError(errors);
      });
    },
    unshareWithUser: function unshareWithUser(user) {
      var _this4 = this;

      var type = this.get('model.type');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('workspace.id'), 'users', user.get('id'), type + 's', this.get('model.id'), 'detach'), {
        type: 'DELETE'
      }).then(function () {
        _this4.get('tracking').trackEvent({
          event: 'Unshared ' + type.capitalize(),
          category: type,
          action: 'unshared',
          sharedWith: user.get('fullName')
        });
        _this4.get('model.sharingUsers').removeObject(user);
      }).catch(function (errors) {
        return _this4.messages.showAPIError(errors);
      });
    }
  });
});