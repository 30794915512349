define('candidio-app/mixins/dirty-confirm-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        var model = this.get('currentModel');
        if (model.get('hasDirtyAttributes')) {
          if (confirm('Leaving this page will lose your changes. Are you sure?')) {
            model.rollbackAttributes();
          } else {
            transition.abort();
          }
        }
      }
    }
  });
});