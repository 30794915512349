define('candidio-app/components/candidio-shot-item-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['empty-item'],
    typeClass: Ember.computed('type', function () {
      return 'empty-item--' + this.get('type');
    }),
    classNameBindings: ['typeClass'],

    isShotList: Ember.computed.equal('type', 'shot-list'),
    isStoryboard: Ember.computed.equal('type', 'storyboard')
  });
});