define('candidio-app/routes/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'candidio-app/mixins/admin-route'], function (exports, _authenticatedRouteMixin, _adminRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _adminRoute.default, {
    titleToken: 'Admin',

    activate: function activate() {
      this.get('current').resetCurrent();
      this.set('current.inAdmin', true);
    },
    deactivate: function deactivate() {
      this.set('current.inAdmin', false);
    }
  });
});