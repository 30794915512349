define('candidio-app/components/asset-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c__asset-preview', 'test-asset'],
    classNameBindings: ['showAssetInfo:c__asset-preview--block', 'canPreview:c__asset-preview--has-preview', 'disabled:is__disabled'],

    tracking: Ember.inject.service(),

    /**
     * Handle clicks on the thumbnail
     * @param {Event} e
     */
    click: function click(e) {
      if (!_.contains(e.target.classList, 'js-open-preview')) {
        return;
      }

      if (!this.get('canPreview') && this.get('canDownload')) {
        // track asset download
        Ember.run.once(this, function () {
          this.get('tracking').trackEvent({
            event: 'Asset Preview Downloaded',
            category: 'asset',
            action: 'downloaded',
            label: this.get('asset.fullFileName') || null
          });
        });

        window.open(this.get('asset.linkDownload'), '_blank');
      } else {
        // track asset preview
        Ember.run.once(this, function () {
          this.get('tracking').trackEvent({
            event: 'Asset Preview Opened',
            category: 'asset',
            action: 'previewed',
            label: this.get('asset.fullFileName') || null
          });
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var disabled = this.get('disabled');

      /** Init jQuery UI Draggable widget */
      this.$().draggable({
        disabled: disabled,
        revert: true,
        revertDuration: 0,
        zIndex: 2000
      }).data('asset', this.get('asset'));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      /** Destroy draggable widget */
      this.$().draggable('destroy');
    },


    /** @type {Model} Asset model to display preview */
    asset: null,

    /** @type {Boolean} If true, the asset cannot be dragged or removed */
    disabled: false,

    showAssetInfo: false,

    canDownload: true,

    /*
        Computed Properties
     */
    canRemove: Ember.computed.bool('attrs.remove'),
    canPreview: Ember.computed.and('attrs.openModal', 'asset.hasPreview'),

    content: Ember.computed.reads('asset.id'),

    thumbnailStyle: Ember.computed('asset.linkThumbnail', function () {
      var thumbnailURL = this.get('asset.linkThumbnail');
      if (Ember.isNone(thumbnailURL)) {
        return Ember.String.htmlSafe('');
      }
      return new Ember.String.htmlSafe('background-image: url(' + thumbnailURL + ')');
    }),

    icon: Ember.computed(function () {
      if (this.get('asset.isVideo')) {
        return 'file-video';
      }
      if (this.get('asset.isAudio')) {
        return 'file-music';
      }
      if (this.get('asset.isImage')) {
        return 'file-photo';
      }
      return 'file-new';
    }),

    actions: {
      preview: function preview() {
        if (this.get('canPreview')) {
          // eslint-disable-next-line ember/closure-actions
          return this.sendAction('openModal', 'asset-preview', this.get('asset'));
        }
      },
      remove: function remove() {
        var asset = this.get('asset');
        if (this.get('canRemove')) {
          this.get('remove')(asset);
        }
      }
    }
  });
});