define('candidio-app/controllers/admin/productions/production', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    workspace: Ember.computed.reads('model.workspace'),
    production: Ember.computed.reads('model'),
    selectedTemplate: Ember.computed.reads('model.template'),
    brief: Ember.computed.alias('model.brief'),
    descriptions: Ember.computed.reads('model.descriptions'),

    productionSubmittedActions: Ember.computed.filterBy('model.actions', 'action', 'production-submitted'),
    sortedProductionSubmittedActions: Ember.computed.sort('productionSubmittedActions', function (dateX, dateY) {
      return dateX.get('createdAt').isBefore(dateY.get('createdAt')) ? 1 : -1;
    }),

    productionDeliverables: Ember.computed.filterBy('model.deliverables', 'revision', null),
    productionDeliverable: Ember.computed.reads('productionDeliverables.firstObject'),

    closedRevisions: Ember.computed.filterBy('model.revisions', 'isOpen', false),
    sortedClosedRevisions: Ember.computed.sort('closedRevisions', function (dateX, dateY) {
      return dateX.get('createdAt').isBefore(dateY.get('createdAt')) ? 1 : -1;
    }),
    latestClosedRevision: Ember.computed.reads('sortedClosedRevisions.firstObject'),

    actions: {
      saveTemplate: function saveTemplate(callback, template) {
        var production = this.get('production');

        if (confirm('Changing templates will reset the purpose statements and shot list. Do you still want to choose a new template?')) {
          production.set('template', template);
          return callback(production.save().then(function () {
            return window.location.reload(true);
          }));
        } else {
          return callback(Ember.RSVP.reject());
        }
      }
    }

  });
});