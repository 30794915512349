define('candidio-app/controllers/admin/templates/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.templatesSorting = ['name'];
    },


    sortedTemplates: Ember.computed.sort('model', 'templatesSorting'),
    filteredTemplates: Ember.computed.filter('sortedTemplates', function (template) {
      return Ember.isEmpty(template.get('workspace'));
    })
  });
});