define('candidio-app/routes/admin/templates/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    adapterContext: Ember.inject.service(),

    model: function model() {
      this.get('adapterContext').resetContext();
      return this.store.query('template', {
        limit: 200,
        include: ['template_type', 'workspace', 'industry'].join(',')
      });
    }
  });
});