define('candidio-app/routes/admin/workspaces/workspace', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    titleToken: function titleToken(model) {
      return model.get('workspaceName');
    },

    model: function model(params) {
      return this.store.findRecord('workspace', params.workspace_id);
    },
    activate: function activate() {
      var workspace = this.modelFor(this.routeName);
      this.get('current').setCurrent({ workspace: workspace });
    },
    deactivate: function deactivate() {
      this.get('current').setCurrent();
    }
  });
});