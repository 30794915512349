define('candidio-app/components/search-result-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['search-result__name'],
    objectType: Ember.computed(function () {
      return Ember.get(this, 'result.type');
    }),
    name: Ember.computed('objectType', function () {
      var type = void 0;
      switch (this.get('objectType')) {
        case 'workspace':
          type = Ember.get(this, 'result.workspace_name');
          break;
        case 'production':
          type = Ember.get(this, 'result.title');
          break;
        case 'user':
          type = Ember.isNone(Ember.get(this, 'result.full_name')) ? 'Invited User' : Ember.get(this, 'result.full_name');
          break;
        case 'project':
          type = Ember.get(this, 'result.name');
          break;
      }
      return type;
    })
  });
});