define('candidio-app/models/template-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string', { defaultValue: 'media-play-video' }),
    isPublished: _emberData.default.attr('boolean'),

    // Relationships
    templates: _emberData.default.hasMany('templates', { async: false }),

    // Computed Properties
    firstTemplateImage: Ember.computed('templates.[]', function () {
      var imageUrl = this.get('templates.firstObject.imageUrl');
      if (Ember.isEmpty(imageUrl)) {
        return null;
      }
      return Ember.String.htmlSafe('background-image: url(' + imageUrl + ');');
    })
  });
});