define('candidio-app/helpers/candidio-readable-file-size', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.candidioReadableFileSize = candidioReadableFileSize;
  function candidioReadableFileSize(fileSizeInBytes) {
    var i = -1,
        byteUnits = ['kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
      fileSizeInBytes = fileSizeInBytes / 1024;
      i++;
    } while (fileSizeInBytes > 1024);
    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  }

  exports.default = Ember.Helper.helper(candidioReadableFileSize);
});