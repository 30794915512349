define('candidio-app/models/production', ['exports', 'ember-data', 'candidio-app/models/actionable'], function (exports, _emberData, _actionable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _actionable.default.extend({
    allowPublicUpload: attr('boolean'),
    allowPublicShotlist: attr('boolean'),
    goalDate: attr('moment-date'),
    isSubmitted: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    phase: attr('string'),
    title: attr('string'),
    createdAt: attr('moment-date'),
    updatedAt: attr('moment-date'),
    firstSubmittedAt: attr('moment-date'),
    lastSubmittedAt: attr('moment-date'),

    workspace: belongsTo('workspace', { async: false }),
    videoSeries: belongsTo('videoSeries', { async: false }),
    brief: belongsTo('brief', { async: false }),
    createdBy: belongsTo('user', { async: false }),
    template: belongsTo('template', { async: false }),
    actions: hasMany('action', { async: false }),
    assets: hasMany('asset', { async: false }),
    deliverables: hasMany('deliverable', { async: false }),
    descriptions: hasMany('description', { async: false }),
    revisions: hasMany('revision', { async: false }),
    collaborators: hasMany('user', { async: false }),
    snapshots: hasMany('productionSnapshot', { async: false }),

    isUnsubmitted: Ember.computed.not('isSubmitted'),
    isLocked: Ember.computed.alias('isSubmitted'),
    isUnlocked: Ember.computed.not('isLocked'),

    sortedRevisions: Ember.computed.sort('revisions', function (a, b) {
      return a.get('createdAt').isBefore(b.get('createdAt')) ? 1 : -1;
    }),

    latestRevision: Ember.computed.reads('sortedRevisions.firstObject'),
    acceptedDeliverables: Ember.computed.filterBy('deliverables', 'isAccepted', true),
    hasAcceptedDeliverable: Ember.computed.bool('acceptedDeliverables.length')
  });
});