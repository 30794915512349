define('candidio-app/components/deliverables-actions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortedDeliverables: Ember.computed.sort('deliverables', function (dateX, dateY) {
      return dateX.get('createdAt').isBefore(dateY.get('createdAt')) ? 1 : -1;
    })
  });
});