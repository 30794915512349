define('candidio-app/validators/invite-user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('emails'),
                validationErrors = [];

            if (!validator.isEmail(data.emails)) {
                validationErrors.push({
                    message: 'Please enter a valid email address.'
                });
            }

            return validationErrors;
        }
    });
});