define('candidio-app/serializers/production-snapshot', ['exports', 'candidio-app/serializers/application', 'candidio-app/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      assets: { embedded: 'always' },
      brief: { embedded: 'always' },
      descriptions: { embedded: 'always' }
    },
    normalize: function normalize(typeClass, hash, prop) {

      /** Provide a fallback if the snapshot returns null */
      if (hash.snapshot === null) {
        delete hash.snapshot;
        return this._super(typeClass, hash, prop);
      }

      var data = hash.snapshot.data;
      delete data.id;

      _.each(data, function (value, key) {
        if (key !== 'created_at' && key !== 'updated_at') {
          hash[key] = value;
        }
      });

      delete hash.snapshot;

      this.normalizeBrief(hash);
      this.normalizeAssets(hash);

      return this._super(typeClass, hash, prop);
    },

    normalizeBrief: function normalizeBrief(hash) {
      var ts = this.makeTimestamp(hash);
      hash.brief.id = hash.brief.id + '_snp_' + ts;
    },
    normalizeAssets: function normalizeAssets(hash) {
      _.forEach(hash.assets, function (asset, idx) {
        hash.assets[idx].id = 'snp_' + hash.id + '_' + asset.id;
      });
    },
    makeTimestamp: function makeTimestamp(hash) {
      return new Date(hash.created_at).getTime() / 1000;
    }
  });
});