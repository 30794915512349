define('candidio-app/routes/public-upload', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/utils/format-response-for-push'], function (exports, _candidioPaths, _formatResponseForPush) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    titleToken: "Public Upload",

    model: function model(params) {
      var _this = this;

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('public', 'productions', params.production_id), {
        type: 'GET'
      }).then(function (res) {
        var store = _this.store;
        var production = store.push(store.normalize('production', res.data));

        if (production.get('isSubmitted')) {

          // return this.transitionTo('login');
        }

        return production;
      });
    },

    afterModel: function afterModel(production) {
      var _this2 = this;

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('public', 'workspaces', production.get('workspace.id'), 'productions', production.get('id'), 'descriptions') + '?limit=100', {
        type: 'GET'
      }).then(function (res) {
        _this2.store.pushPayload((0, _formatResponseForPush.default)('description', res));
        _this2.set('descriptions', _this2.store.peekAll('description'));
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('descriptions', this.get('descriptions'));
    }
  });
});