define('candidio-app/services/current', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    user: null,
    workspace: null,
    production: null,
    videoSeries: null,
    inAdmin: false,

    setCurrent: function setCurrent() {
      var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this.setProperties({
        workspace: obj.workspace,
        production: obj.production,
        videoSeries: obj.videoSeries
      });
    },
    resetCurrent: function resetCurrent() {
      this.setProperties({
        workspace: null,
        production: null,
        videoSeries: null
      });
    }
  });
});