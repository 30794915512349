define('candidio-app/components/candidio-pill-toggle', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['pill-toggle'],

    click: function click() {
      var value = this.get('value');
      this.get('update')(!value);
    }
  });
});