define('candidio-app/routes/admin/workspaces/workspace/templates/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('template', {
        template: {
          brief: {
            purpose: null,
            purpose_because: null
          },
          descriptions: Ember.A()
        },
        workspace: this.modelFor('admin.workspaces.workspace')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('workspace', this.modelFor('admin.workspaces.workspace'));
    },


    actions: {
      save: function save(callback, model) {
        var _this = this;

        var promise = model.save();

        callback(promise);

        return promise.then(function () {
          _this.messages.showSuccess('Template saved');
          _this.transitionTo('admin.workspaces.workspace.templates', _this.modelFor('admin.workspaces.workspace'));
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });
      },

      willTransition: function willTransition() {
        var controller = this.get('controller'),
            model = controller.get('model'),
            state = model.getProperties('isNew');

        if (state.isNew) {
          model.deleteRecord();
        }
      }
    }
  });
});