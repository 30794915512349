define('candidio-app/components/productions-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    sortedProductions: Ember.computed.sort('productions', function (a, b) {
      var aGoalDate = a.get('goalDate') || moment(null);
      var bGoalDate = b.get('goalDate') || moment(null);

      if (aGoalDate.isValid() && !bGoalDate.isValid()) {
        return -1;
      }

      if (!aGoalDate.isValid() && bGoalDate.isValid()) {
        return 1;
      }

      if (!aGoalDate.isValid() && !bGoalDate.isValid()) {
        return -1;
      }

      return aGoalDate.isBefore(bGoalDate) ? -1 : 1;
    })
  });
});