define('candidio-app/components/app-toolbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['c-toolbar', 'u__print-hide', 'z__1'],

    navigation: Ember.inject.service(),

    isMobile: false,

    resizeEventName: Ember.computed('elementId', function () {
      return 'resize.' + this.get('elementId');
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.$(window).on(this.get('resizeEventName'), Ember.run.bind(this, this.setIsMobile));
      this.setIsMobile();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.$(window).off(this.get('resizeEventName'));
    },
    setIsMobile: function setIsMobile() {
      Ember.run.throttle(this, function () {
        Ember.set(this, 'isMobile', window.innerWidth < 720);
      }, 50);
    },


    actions: {
      toggleNav: function toggleNav() {
        this.get('navigation').toggleNav();
      }
    }
  });
});