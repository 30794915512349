define('candidio-app/controllers/admin/new-producer', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/mixins/validation-engine'], function (exports, _candidioPaths, _validationEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationEngine.default, {

    ajax: Ember.inject.service(),

    validationType: 'newProducer',

    actions: {
      submit: function submit(callback, model) {
        var _this = this;

        var promise = this.validate({ model: model }).then(function () {
          return _this.get('ajax').post((0, _candidioPaths.default)().url.api('register'), {
            data: JSON.stringify({
              data: {
                first_name: model.get('firstName'),
                last_name: model.get('lastName'),
                email: model.get('email'),
                company: model.get('workspaceName')
              }
            })
          }).then(function () {
            return _this.transitionToRoute('admin.index');
          }).catch(function (errors) {
            return _this.messages.showAPIError(errors);
          });
        }).catch(function (errors) {
          return _this.messages.showErrors(errors);
        });

        callback(promise);

        return promise;
      }
    }
  });
});