define('candidio-app/routes/user/notifications', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    titleToken: 'Email Settings"',

    model: function model() {
      return this.modelFor('user');
    }
  });
});