define('candidio-app/mixins/pagination-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    // Set from the PaginationRouteMixin
    paginationSettings: null,

    // indicates whether we're currently loading the next page
    loadingMore: false,

    // This needs to be set from the controller calling this mixin
    // and _must_ return a resolvable promise
    loadMoreFunction: null,

    meta: Ember.computed('model.meta', function () {
      return this.get('model.meta.pagination');
    }),

    totalPages: Ember.computed.alias('meta.total_pages'),

    // We have more if there are more total records than there are records loaded
    hasMore: Ember.computed('paginationSettings.page', 'totalPages', function () {
      return this.get('paginationSettings.page') < this.get('totalPages');
    }),

    actions: {

      loadMore: function loadMore() {
        var self = this,
            query = _.clone(this.get('paginationSettings'));

        // We're starting to fetch data now
        this.set('loadingMore', true);

        query.page = this.get('paginationSettings.page') + 1;

        this.loadMoreFunction(query).then(function (data) {
          // We're done fetching data for the moment
          self.set('loadingMore', false);

          // We're merging its content with the models already fetched...
          self.get('model').pushObjects(data.get('content'));

          // And we're updating the current page we're on
          // as well as the total page count (in case it changed).
          self.set('totalPages', data.get('meta.pagination.total_pages'));
          self.incrementProperty('paginationSettings.page');
        }).catch(function (errors) {
          self.set('loadingMore', false);
          self.messages.showAPIError(errors);
        });
      },

      resetPagination: function resetPagination() {
        this.set('paginationSettings.page', 1);
      }
    }
  });
});