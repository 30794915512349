define('candidio-app/controllers/modals/public-upload-page', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var InviteCoProducersToProjectController = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    tracking: Ember.inject.service(),

    workspace: Ember.computed.alias('current.workspace'),

    uploadURL: Ember.computed('model.id', function () {
      return window.location.origin + '/public-upload/' + this.get('model.id');
    }),

    confirm: {
      accept: {
        text: 'Done'
      },
      reject: {
        buttonClass: 'is-hidden'
      }
    },

    actions: {

      setPublicUpload: function setPublicUpload(value) {
        var _this = this;

        var self = this;

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', self.get('current.workspace.id'), 'productions', self.get('model.id')), {
          type: 'PUT',
          data: JSON.stringify({
            data: {
              allow_public_upload: value,
              allow_public_shotlist: value
            }
          })
        }).then(function () {
          _this.get('tracking').trackEvent({
            event: 'Set Public Upload',
            category: 'production',
            action: 'set-public-upload',
            allowed: value
          });
        }).catch(function (errors) {
          self.messages.showAPIError(errors);
        });
      },

      confirmAccept: function confirmAccept() {
        this.send('closeModal');
      },

      confirmReject: function confirmReject() {
        return false;
      }
    }

  });

  exports.default = InviteCoProducersToProjectController;
});