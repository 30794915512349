define('candidio-app/controllers/forgotten', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/mixins/validation-engine'], function (exports, _candidioPaths, _validationEngine) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_validationEngine.default, {
        ajax: Ember.inject.service(),
        tracking: Ember.inject.service(),
        email: '',
        submitting: false,

        validationType: 'forgotten',

        actions: {
            submit: function submit() {
                var _this = this;

                this.toggleProperty('submitting');

                this.validate({}).then(function () {
                    return _this.get('ajax').request((0, _candidioPaths.default)().url.api('users', 'forgot-password'), {
                        type: 'POST',
                        data: JSON.stringify({
                            data: {
                                email: _this.get('email')
                            }
                        })
                    }).then(function () {
                        _this.toggleProperty('submitting');
                        _this.messages.showInfo('Please check your email for instructions on resetting your password.', { clickToDismiss: true });
                        _this.set('email', '');
                        _this.transitionToRoute('login');
                    }).then(function () {
                        _this.get('tracking').trackEvent({
                            event: 'Requested Password Reset',
                            category: 'user',
                            action: 'requested-password-reset',
                            email: _this.get('email')
                        });
                    }).catch(function (error) {
                        _this.messages.showAPIError(error);
                    });
                }).catch(function (errors) {
                    return _this.messages.showErrors(errors);
                }).finally(function () {
                    return _this.toggleProperty('submitting');
                });
            }
        }
    });
});