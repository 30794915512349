define('candidio-app/controllers/workspace/video-series/video-series', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isProducersModelShowing: false,

    collaboratingUsers: Ember.computed.union('current.workspace.managingUsers', 'model.collaborators')
  });
});