define('candidio-app/routes/admin/workspaces/workspace/templates/template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('template', params.template_id, {
        adapterOptions: {
          query: {
            include: ['template_type', 'workspace', 'industry'].join(',')
          }
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('workspace', this.modelFor('admin.workspaces.workspace'));
    },


    actions: {
      save: function save(callback, model) {
        var _this = this;

        var workspace = this.modelFor('admin.workspaces.workspace');
        var template = model.save();

        callback(template);

        return template.then(function () {
          _this.messages.showSuccess('Template saved');
          _this.transitionTo('admin.workspaces.workspace.templates', workspace);
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });
      },
      delete: function _delete(model) {
        var _this2 = this;

        if (confirm('Are you absolutely sure you want to delete this template?')) {
          return model.destroyRecord().then(function () {
            _this2.messages.showSuccess('Template has been deleted.');
            _this2.transitionTo('admin.workspaces.workspace.templates', _this2.modelFor('admin.workspaces.workspace'));
          }).catch(function (errors) {
            return _this2.messages.showAPIError(errors);
          });
        }
      }
    }
  });
});