define('candidio-app/adapters/asset', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/{pathForType}{/id}{?query*}',

    createRecordUrlTemplate: Ember.computed('adapterContext.{workspace,videoSeries,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'videoSeries') && Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{videoSeriesId}/assets';
      }

      if (Ember.get(adapterContext, 'production') && Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/assets';
      }

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/assets';
      }

      return '{+host}/{+namespace}/assets';
    }),

    findAllUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/assets{?include}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/assets{?include}';
      }

      return '{+host}/{+namespace}/assets{?include}';
    }),

    queryUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/assets{?query*}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/assets{?query*}';
      }

      return '{+host}/{+namespace}/assets{?query*}';
    }),

    deleteRecordUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace') && Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/assets/{id}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/assets/{id}';
      }
      return '{+host}/{+namespace}/assets/{id}';
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      productionId: function productionId() {
        return this.get('adapterContext.production.id');
      },
      videoSeriesId: function videoSeriesId() {
        return this.get('adapterContext.videoSeries.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }
  });
});