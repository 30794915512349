define('candidio-app/controllers/admin/templates/new', ['exports', 'candidio-app/mixins/description-sorting'], function (exports, _descriptionSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_descriptionSorting.default, {

    adapterContext: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),

    /* eslint-disable */
    name: null,
    description: null,
    template: {
      brief: {
        purpose: null,
        purpose_because: null
      }, descriptions: Ember.A()
    },
    type: null,
    industry: null,
    workspace: null,
    isPublished: false,
    editorPick: false,
    imageUrl: null,
    /* eslint-enable */

    // shots: computed.alias('template.descriptions'),
    sortedShots: Ember.computed.alias('template.descriptions'),

    actions: {
      addShot: function addShot() {
        this.get('template.descriptions').addObject(Ember.Object.create({
          type: 'broll',
          fields: {
            field1: '',
            field2: '',
            meta: []
          }
        }));
      },
      movedShot: function movedShot(shot, oldIndex, newIndex) {
        var shots = this.get('template.descriptions');
        shots.removeAt(oldIndex);
        shots.insertAt(newIndex, shot);
      },
      removeShot: function removeShot(shot) {
        this.get('template.descriptions').removeObject(shot);
      },
      uploadFiles: function uploadFiles(files) {
        var _this = this;

        _.forEach(files, function (file) {
          return _this.uploadFile(file);
        });
      },
      removeImage: function removeImage() {
        this.set('imageUrl', null);
      },
      saveTemplate: function saveTemplate(callback) {
        var _this2 = this;

        var data = this.getProperties('name', 'description', 'template', 'imageUrl', 'type', 'industry', 'workspace', 'isPublished', 'editorPick');

        var template = this.store.createRecord('template', {
          name: data.name,
          description: data.description,
          template: data.template,
          imageUrl: data.imageUrl,
          isPublished: data.isPublished,
          editorPick: data.editorPick,
          templateType: data.type,
          industry: data.industry,
          workspace: data.workspace
        });

        // Let's make sure we've got no workspace set as context
        this.get('adapterContext').resetContext();

        var promise = template.save();

        callback(promise);

        return promise.then(function () {
          _this2.messages.showSuccess('New template created');
          _this2.transitionToRoute('admin.templates');
          _this2.setProperties({
            name: null,
            description: null,
            template: {
              brief: {
                purpose: null,
                purposeBecause: null
              },
              descriptions: Ember.A()
            },
            type: null,
            industry: null,
            workspace: null,
            isPublished: false,
            editorPick: false,
            imageUrl: null
          });
        }).catch(function (errors) {
          return _this2.messages.showAPIError(errors);
        });
      },


      /*
        Override the same method on the DescriptionSortingMixin so position isn't saved
       */
      moveDescription: function moveDescription() {
        return;
      }
    },

    uploadFile: function uploadFile(file) {
      var _this3 = this;

      var path = 'template-images';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, {}, {
        onComplete: function onComplete(res) {
          return _this3.completeFileUpload(res);
        }
      });
    },
    completeFileUpload: function completeFileUpload(res) {
      this.set('imageUrl', res.file_path);
    }
  });
});