define('candidio-app/routes/admin/index', ['exports', 'ember-infinity/mixins/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_route.default, {

    titleToken: 'Activity',
    perPageParam: 'limit',
    pageParam: 'page',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      return this.infinityModel('action', {
        startingPage: 1,
        perPage: 10,
        sort: 'created_at',
        direction: 'desc',
        include: ['actionable', 'user', 'workspace'].join(',')
      });
    }
  });
});