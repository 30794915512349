define('candidio-app/routes/admin/workspaces/workspace/productions', ['exports', 'ember-infinity/mixins/route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _route, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, {

    adapterContext: Ember.inject.service(),

    perPageParam: 'limit',
    pageParam: 'page',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      var include = ['workspace', 'brief', 'created_by', 'revisions.assets', 'collaborators', 'template.template_type'].join(',');

      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace });

      return this.infinityModel('production', {
        startingPage: 1,
        perPage: 25,
        sort: 'created_at',
        direction: 'desc',
        include: include
      });
    }
  });
});