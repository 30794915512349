define('candidio-app/components/forms/video-series', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      updateTone: function updateTone(hash) {
        this.set('model.meta.tone', hash);
      }
    }
  });
});