define('candidio-app/components/tone-chooser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _tone: Ember.computed.reads('tone'),

    trueToneItems: Ember.computed('_tone.{action,brief,clean,clever,cool,emotional,fancy,fast,fresh,fun,funny,happy,inspiring,inviting,long,mysterious,powerful,serious,slow,thoughtProvoking}', function () {
      return _.invert(this.get('_tone'), true).true;
    }),

    actions: {
      toggleToneValue: function toggleToneValue(key) {
        var currentValue = Ember.get(this, '_tone.' + key);
        Ember.set(this, '_tone.' + key, !currentValue);
        this.get('update')(this.get('_tone'));
      }
    }
  });
});