define('candidio-app/routes/workspace/productions/production', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    adapterContext: Ember.inject.service(),
    tracking: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return model.get('title');
    },

    model: function model(params) {
      var workspace = this.modelFor('workspace');
      this.get('adapterContext').setContext({ workspace: workspace });

      return this.store.findRecord('production', params.production_id, {
        reload: true,
        adapterOptions: {
          query: {
            include: ['brief', 'collaborators', 'created_by', 'deliverables.assets', 'descriptions.assets', 'revisions.assets', 'revisions.deliverables.assets', 'template.template_type', 'video_series', 'workspace'].join(',')
          }
        }
      });
    },

    activate: function activate() {
      var current = this.get('current');
      var production = this.modelFor(this.routeName);
      var workspace = this.modelFor('workspace');
      var videoSeries = production.get('videoSeries');

      current.setCurrent({ workspace: workspace, production: production, videoSeries: videoSeries });
    },

    deactivate: function deactivate() {
      this.get('current').set('production', null);
    }
  });
});