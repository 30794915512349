define('candidio-app/mixins/sort-by-most-recent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    /**
     * Sort the moment date objects by most recent first
     *
     * @param  {Object} dateX First date object
     * @param  {Object} dateY Second date object
     * @return {Int} Sort order
     */
    arrangedContent: Ember.computed.sort('model', function (dateX, dateY) {
      return dateX.get('createdAt').isBefore(dateY.get('createdAt')) ? 1 : -1;
    })

  });
});