define('candidio-app/routes/admin/workspaces/workspace/users', ['exports', 'candidio-app/mixins/pagination-route'], function (exports, _paginationRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var paginationSettings = {
    limit: 20,
    page: 1,
    sort: 'email',
    direction: 'asc'
  };

  exports.default = Ember.Route.extend(_paginationRoute.default, {
    adapterContext: Ember.inject.service(),

    model: function model() {
      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.query('user', paginationSettings);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('workspace', this.modelFor('admin.workspaces.workspace'));
      this.setupPagination(paginationSettings);
    }
  });
});