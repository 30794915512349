define('candidio-app/serializers/description', ['exports', 'candidio-app/serializers/application', 'candidio-app/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      production: { deserialize: 'records', serialize: false },
      assets: { deserialize: 'records', serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      // API passes us `shot_type`, but expects `type`
      json.type = json.shot_type;
      delete json.shot_type;

      return json;
    }
  });
});