define('candidio-app/components/candidio-toggle-switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['toggle--square'],
    classNameBindings: ['value:checked'],

    // Value should be mapped to a boolean on the containing controller
    value: false,

    click: function click() {
      this.toggleProperty('value');
      this.sendAction('action', this.get('value')); // eslint-disable-line
    }
  });
});