define('candidio-app/components/candidio-load-more', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['load-more'],
    action: 'fetchMore',
    isFetching: false,
    hasMore: null,

    actions: {
      loadMore: function loadMore() {
        if (this.get('hasMore') && !this.get('isFetching')) {
          this.sendAction('action'); // eslint-disable-line
        }
      }
    }
  });
});