define('candidio-app/components/activity-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['activity'],
    action: null,

    message: Ember.computed('action', function () {
      return 'activity/' + this.get('action.action');
    })
  });
});