define('candidio-app/routes/admin/users/user', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    adapterContext: Ember.inject.service(),

    model: function model(params) {
      this.get('adapterContext').resetContext();
      return this.store.findRecord('user', params.user_id, {
        reload: true,
        adapterOptions: {
          query: {
            include: 'workspaces'
          }
        }
      });
    }

  });
});