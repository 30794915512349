define('candidio-app/serializers/permission', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    attrs: {
      workspaceId: 'workspace_id',
      canManage: 'can_manage'
    }
  });
});