define('candidio-app/adapters/production', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: Ember.computed('adaptercontext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions{/id}{?query*}';
      } else {
        return '{+host}/{+namespace}/productions{/id}{?query*}';
      }
    }),

    createRecordUrlTemplate: Ember.computed('adapterContext.{workspace,videoSeries}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'videoSeries') && Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{videoSeriesId}/productions';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions';
      } else {
        return '{+host}/{+namespace}/productions';
      }
    }),

    findRecordUrlTemplate: Ember.computed('adapterContext.{workspace,videoSeries}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'videoSeries')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{videoSeriesId}/productions/{id}{?include}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{id}{?include}';
      } else {
        return '{+host}/{+namespace}/productions/{id}{?include}';
      }
    }),

    queryUrlTemplate: Ember.computed('adapterContext.{workspace,videoSeries}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'videoSeries')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{videoSeriesId}/productions{?query*}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions{?query*}';
      } else {
        return '{+host}/{+namespace}/productions{?query*}';
      }
    }),

    updateRecordUrlTemplate: Ember.computed('adapterContext.{workspace,videoSeries}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'videoSeries') && Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{videoSeriesId}/productions/{id}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{id}';
      } else {
        return '{+host}/{+namespace}/productions/{id}';
      }
    }),

    deleteRecordUrlTemplate: Ember.computed('adapterContext.{workspace}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{id}';
      } else {
        return '{+host}/{+namespace}/productions/{id}';
      }
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      videoSeriesId: function videoSeriesId() {
        return this.get('adapterContext.videoSeries.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }
  });
});