define('candidio-app/mixins/validation-engine', ['exports', 'candidio-app/validators/login', 'candidio-app/validators/create-workspace', 'candidio-app/validators/forgotten', 'candidio-app/validators/reset', 'candidio-app/validators/user', 'candidio-app/validators/invite', 'candidio-app/validators/invite-user', 'candidio-app/validators/new-producer'], function (exports, _login, _createWorkspace, _forgotten, _reset, _user, _invite, _inviteUser, _newProducer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
    * The class that gets this mixin will receive these properties and functions.
    * It will be able to validate any properties on itself (or the model it passes to validate())
    * with the use of a declared validator.
    */
    var ValidationEngine = Ember.Mixin.create({

        // these validators can be passed a model to validate when the class that
        // mixes in the ValidationEngine declares a validationType equal to a key on this object.
        // the model is either passed in via `this.validate({ model: object })`
        // or by calling `this.validate()` without the model property.
        // in that case the model will be the class that the ValidationEngine
        // was mixed into, i.e. the controller or Ember Data model.
        validators: {
            login: _login.default,
            createWorkspace: _createWorkspace.default,
            forgotten: _forgotten.default,
            reset: _reset.default,
            user: _user.default,
            invite: _invite.default,
            inviteUser: _inviteUser.default,
            newProducer: _newProducer.default
        },

        /**
        * Passses the model to the validator specified by validationType.
        * Returns a promise that will resolve if validation succeeds, and reject if not.
        * Some options can be specified:
        *
        * `format: false` - doesn't use formatErrors to concatenate errors for notifications.showErrors.
        *                   will return whatever the specified validator returns.
        *                   since notifications are a common usecase, `format` is true by default.
        *
        * `model: Object` - you can specify the model to be validated, rather than pass the default value of `this`,
        *                   the class that mixes in this mixin.
        */
        validate: function validate() {
            var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

            var model = opts.model || this,
                type = this.get('validationType'),
                validator = this.get('validators.' + type);

            opts.validationType = type;

            return new Ember.RSVP.Promise(function (resolve, reject) {
                var validationErrors;

                if (!type || !validator) {
                    validationErrors = ['The validator specified, "' + type + '", did not exist!'];
                } else {
                    validationErrors = validator.check(model);
                }

                if (Ember.isEmpty(validationErrors)) {
                    return resolve();
                }

                // if (opts.format !== false) {
                //     validationErrors = formatErrors(validationErrors, opts);
                // }

                return reject(validationErrors);
            });
        }
    });

    exports.default = ValidationEngine;
});