define('candidio-app/validators/user', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var UserValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('firstName', 'lastName', 'email'),
                validationErrors = [];

            if (!validator.isEmail(data.email)) {
                validationErrors.push({ message: 'Ruh roh. Something is wrong with the email address you entered.' });
            }

            if (!validator.isLength(data.firstName, 0, 150)) {
                validationErrors.push({ message: 'We can\'t handle a first name longer than 150 characters. Sorry!' });
            }

            if (!validator.isLength(data.lastName, 0, 150)) {
                validationErrors.push({ message: 'We can\'t handle a last name longer than 150 characters. Sorry!' });
            }

            return validationErrors;
        }
    });

    exports.default = UserValidator;
});