define('candidio-app/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'candidio-app/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var API_HOST = _environment.default.APP.API_HOST;
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenRevocationEndpoint: null,
    serverTokenEndpoint: API_HOST + '/oauth/token',
    refreshAccessTokens: true,

    makeRequest: function makeRequest(url, data) {
      data.client_id = 'candidio_web_app';
      data.client_secret = 'sk_Rf44WowDtwXIpqGmHvFRZnFFBxZCoj9t';
      data.scope = '';
      return this._super.apply(this, arguments);
    }
  });
});