define('candidio-app/models/brief-meta', ['exports', 'ember-data', 'model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    lengthAdjustment: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return 'none';
      }
    }),
    lengthReason: _emberData.default.attr('string')
  });
});