define('candidio-app/services/tracking', ['exports', 'candidio-app/utils/can-use-dom', 'candidio-app/utils/object-transformations'], function (exports, _canUseDom, _objectTransformations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({

    /**
     * Indicated whether calls to the service will be forwarded to the adapters
     *
     * @type {Boolean}
     * @default true
     */
    enabled: true,

    init: function init() {
      var adapters = Ember.getWithDefault(this, 'options.adapters', Ember.A());

      /**
       * Active adapters
       *
       * @private
       * @type Array
       */
      Ember.set(this, '_adapters', []);

      Ember.set(this, 'logging', Ember.getWithDefault(this, 'options.logging', false));
      Ember.set(this, 'appEnvironment', Ember.getWithDefault(this, 'options.environment', 'development'));
      Ember.set(this, 'enabled', Ember.getWithDefault(this, 'options.enabled', true));
      this.activateAdapters(adapters);
      this._super.apply(this, arguments);
    },
    willDestroy: function willDestroy() {
      var adapters = Ember.get(this, '_adapters');

      _.forEach(adapters, function (adapterName) {
        Ember.get(adapters, adapterName).destroy();
      });
    },
    alias: function alias() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.invoke.apply(this, ['alias'].concat(_toConsumableArray(args)));
    },
    identifyUser: function identifyUser() {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this.invoke.apply(this, ['identifyUser'].concat(_toConsumableArray(args)));
    },
    identifyWorkspace: function identifyWorkspace() {
      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      this.invoke.apply(this, ['identifyWorkspace'].concat(_toConsumableArray(args)));
    },
    trackPage: function trackPage() {
      for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      this.invoke.apply(this, ['trackPage'].concat(_toConsumableArray(args)));
    },
    trackEvent: function trackEvent() {
      for (var _len5 = arguments.length, args = Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        args[_key5] = arguments[_key5];
      }

      this.invoke.apply(this, ['trackEvent'].concat(_toConsumableArray(args)));
    },
    invoke: function invoke(methodName) {
      var _this = this;

      for (var _len6 = arguments.length, args = Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }

      if (Ember.get(this, 'logging')) {
        this.logTracking.apply(this, [methodName].concat(_toConsumableArray(args)));
      }

      if (Ember.get(this, 'current.user.isAdmin')) {
        return;
      }

      if (!Ember.get(this, 'enabled')) {
        return;
      }

      var adapters = Ember.get(this, '_adapters');

      var _ref = args.length > 1 ? [args[0], args[1]] : [adapters, args[0]],
          _ref2 = _slicedToArray(_ref, 2),
          selectedAdapterNames = _ref2[0],
          options = _ref2[1];

      selectedAdapterNames.forEach(function (adapter) {
        _this[adapter][methodName](options);
      });
    },
    activateAdapters: function activateAdapters() {
      var _this2 = this;

      var adapterOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

      var appEnvironment = Ember.get(this, 'appEnvironment');
      var activatedAdapters = [];

      adapterOptions.filter(function (adapterOption) {
        return _this2._filterEnvironments(adapterOption, appEnvironment);
      }).forEach(function (adapterOption) {
        var name = adapterOption.name;

        _this2._activateAdapter(adapterOption);
        activatedAdapters.push(name);
      });

      return Ember.set(this, '_adapters', activatedAdapters);
    },
    _activateAdapter: function _activateAdapter() {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          name = _ref3.name,
          config = _ref3.config;

      this['' + name].init(config);
      return name;
    },
    _filterEnvironments: function _filterEnvironments(adapterOption, appEnvironment) {
      var environments = adapterOption.environments;

      var wrappedEnvironments = Ember.A(environments);
      return wrappedEnvironments.includes(appEnvironment);
    },


    logTracking: function logTracking() {},

    // logTracking: function(methodName, ...args) {
    //   Ember.Logger.info(`[TRACKING:${methodName.toUpperCase()}] `, ...args);
    // },

    /**
     * GOOGLE ANALYTICS ================================================================
     */

    googleAnalytics: {
      init: function init(config) {
        var id = config.id;


        if (_canUseDom.default) {
          /* jshint ignore:start */
          (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function () {
              (i[r].q = i[r].q || []).push(arguments);
            }, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m);
          })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
          /* jshint ignore:end */
        }

        window.ga('create', id, 'auto');
      },
      destroy: function destroy() {
        Ember.$('script[src*="google-analytics"]').remove();
        delete window.ga;
      },

      alias: function alias() {},
      identifyUser: function identifyUser() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var compactedOptions = (0, _objectTransformations.compact)(options);
        var id = compactedOptions.id;


        window.ga('set', 'userId', id);
      },

      identifyWorkspace: function identifyWorkspace() {},
      trackPage: function trackPage() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var compactedOptions = (0, _objectTransformations.compact)({ page: options.page, title: options.title });
        var sendEvent = { hitType: 'pageview' };

        var event = Ember.merge(sendEvent, compactedOptions);
        window.ga('send', event);

        return event;
      },
      trackEvent: function trackEvent() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        (false && !(options.category) && Ember.assert('[TRACKING] Event category is a required property.', options.category));
        (false && !(options.action) && Ember.assert('[TRACKING] Event action is a required property.', options.action));

        var compactedOptions = (0, _objectTransformations.compact)({
          eventCategory: options.category,
          eventAction: options.action,
          eventLabel: options.label,
          eventValue: options.value
        });
        var sendEvent = { hitType: 'event' };

        var event = Ember.merge(sendEvent, compactedOptions);
        window.ga('send', event);

        return event;
      }
    },

    /**
     * INTERCOM =============================================================================
     */
    intercom: {
      init: function init(config) {
        var appId = config.appId;
        (false && !(appId) && Ember.assert('You must pass a valid `appId` to the Intercom adapter', appId));


        if (_canUseDom.default) {
          /* eslint-disable */
          (function () {
            var w = window;var ic = w.Intercom;if (typeof ic === "function") {
              ic('reattach_activator');ic('update', intercomSettings);
            } else {
              var l = function l() {
                var s = d.createElement('script');s.type = 'text/javascript';s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + appId;
                var x = d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);
              }; /* CUSTOM: WE FIRE THE FUNCTION TO INJECT THE SCRIPT*/

              var d = document;var i = function i() {
                i.c(arguments);
              };i.q = [];i.c = function (args) {
                i.q.push(args);
              };w.Intercom = i;l(); /*if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}*/
            }
          })();
          /* eslint-enable */
        }

        Ember.set(this, 'intercomAppId', appId);
      },
      destroy: function destroy() {
        window.Intercom('shutdown');
        Ember.$('script[src*="intercom"]').remove();
        delete window.Intercom;
      },

      alias: function alias() {},
      identifyUser: function identifyUser() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var compactedOptions = (0, _objectTransformations.compact)({
          user_id: options.id,
          user_hash: options.userHash,
          name: options.firstName + ' ' + options.lastName,
          email: options.email,
          created_at: options.createdAt,
          is_admin: options.isAdmin,
          beta_tester: options.isBeta
        });
        var appIdObj = { app_id: Ember.get(this, 'intercomAppId') };

        window.Intercom('boot', Ember.merge(appIdObj, (0, _objectTransformations.momentValuesToUnix)(compactedOptions)));
      },
      identifyWorkspace: function identifyWorkspace() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var compactedOptions = (0, _objectTransformations.underscoreKeys)((0, _objectTransformations.momentValuesToUnix)((0, _objectTransformations.compact)(options)));

        window.Intercom('update', {
          company: compactedOptions
        });
      },
      trackPage: function trackPage() {
        window.Intercom('update');
      },
      trackEvent: function trackEvent() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        (false && !(options.event) && Ember.assert('[TRACKING] `event` is a required property.', options.event));

        var compactedOptions = (0, _objectTransformations.compact)(options);
        var event = options.event;

        var props = (0, _objectTransformations.momentValuesToUnix)((0, _objectTransformations.underscoreKeys)((0, _objectTransformations.without)(compactedOptions, ['event', 'category', 'action', 'label', 'value'])));

        if (Ember.isPresent(props)) {
          window.Intercom('trackEvent', event.dasherize(), (0, _objectTransformations.limitToNProperties)(props, 5));
        } else {
          window.Intercom('trackEvent', event.dasherize());
        }
      }
    }
  });
});