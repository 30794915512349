define('candidio-app/routes/activate-account', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    tracking: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var self = this;

      // Validate token
      if (!validator.isLength(params.token, 32, 64)) {
        self.messages.showError('The token is malformed. Please contact support@candidio.com');
        self.transitionTo('login');
      }

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('activate'), {
        type: 'POST',
        data: JSON.stringify({
          data: { 'token': params.token }
        })
      }).then(function () {
        self.messages.showSuccess('Your email address is now confirmed. You many now upload assets and add a credit card to your workspace.');

        // If authenticated, get the user and updated isConfirmed flag
        if (self.get('session.isAuthenticated')) {
          _this.store.findRecord('user', 'me', {
            adapterOptions: {
              query: {
                include: 'workspaces'
              }
            }
          }).then(function (user) {
            user.set('isConfirmed', true);

            self.get('tracking').trackEvent({
              event: 'Confirmed Email',
              category: 'user',
              action: 'confirmed-email'
            });

            self.transitionTo('workspaces');
          });
        } else {
          self.get('tracking').trackEvent({
            event: 'Confirmed Email',
            category: 'user',
            action: 'confirmed-email'
          });
          self.transitionTo('login');
        }
      }).catch(function (errors) {
        self.messages.showAPIError(errors);
        self.transitionTo('login');
      });
    }

  });
});