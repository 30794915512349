define('candidio-app/routes/reset', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    session: Ember.inject.service(),

    titleToken: 'Reset Password',

    beforeModel: function beforeModel() {
      if (this.get('session').get('isAuthenticated')) {
        this.messages.showAlert("You can't reset your password while you're signed in.");
        this.transitionTo('workspaces.index');
      }
    },
    setupController: function setupController(controller, params) {
      if (!validator.isLength(params.token, 32, 64)) {
        this.messages.showError('Invalid token.');
        this.transitionTo('login');
      } else {
        controller.token = params.token;
      }
    }
  });
});