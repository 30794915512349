define('candidio-app/initializers/current', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'current', 'service:current');
    application.inject('component', 'current', 'service:current');
    application.inject('adapter', 'current', 'service:current');
    application.inject('route', 'current', 'service:current');
    application.inject('repository', 'current', 'service:current');
    application.inject('ability', 'current', 'service:current');
    application.inject('model', 'current', 'service:current');
  }

  exports.default = {
    name: 'current',
    initialize: initialize
  };
});