define('candidio-app/routes/workspace/productions/production/pre-production', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var gap = 1024;

  exports.default = Ember.Route.extend({

    adapterContext: Ember.inject.service(),
    tracking: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var production = this.modelFor('workspace.productions.production');
      if (production.get('inDevelopment')) {
        this.transitionTo('workspace.productions.production.development');
      }
    },
    model: function model() {
      return this.modelFor('workspace.productions.production');
    },


    actions: {
      createDescription: function createDescription() {
        var _this = this;

        var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'broll';

        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');

        var firstShot = this.get('controller.sortedShots.firstObject');
        var newPos = Ember.isEmpty(firstShot) ? 0 : firstShot.get('position') - gap;

        var description = this.store.createRecord('description', {
          shotType: type,
          position: newPos,
          fields: {
            field1: '',
            field2: '',
            meta: []
          }
        });

        // Add the description to the UI prior to persisting
        production.get('descriptions').unshiftObject(description);

        this.get('adapterContext').setContext({ workspace: workspace, production: production });
        return description.save().then(function () {
          _this.get('tracking').trackEvent({
            event: 'Created Shot',
            category: 'production',
            action: 'created-shot',
            location: 'shot-list',
            productionTitle: production.get('title')
          });
        });
      },
      deleteDescription: function deleteDescription(description) {
        var _this2 = this;

        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');
        this.get('adapterContext').setContext({ workspace: workspace, production: production });
        description.destroyRecord().then(function () {
          _this2.get('tracking').trackEvent({
            event: 'Deleted Shot',
            category: 'production',
            action: 'deleted-shot',
            location: 'shot-list',
            productionTitle: production.get('title')
          });
        }).catch(function (errors) {
          return _this2.messages.showAPIError(errors);
        });
      },
      printShotList: function printShotList() {
        this.get('tracking').trackEvent({
          event: 'Printed Shot List',
          category: 'production',
          action: 'printed-shot-list',
          productionTitle: this.modelFor('workspace.productions.production').get('title')
        });
        window.print();
      },
      updateGoalDate: function updateGoalDate(newDate) {
        var _this3 = this;

        // debugger
        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');
        production.set('goalDate', moment(newDate));
        this.get('adapterContext').setContext({ workspace: workspace });
        return production.save().catch(function (errors) {
          return _this3.messages.showAPIError(errors);
        });
      }
    }
  });
});