define('candidio-app/mixins/admin-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({

        /**
            This mixin is for routes that require the authenticated user to be have an
            admin role. Including this mixin in the route automatically adds a hook that
            enforces that the user to have an admin role and redirects to the workspaces
            index.
        
            ```javascript
            // app/routes/admin-route.js
            import AdminRouteMixin from 'candidio-app/mixins/admin-route-mixin.js';
        
            export default Ember.Route.extend(AdminRouteMixin);
        
            `AdminRouteMixin` performs the redirect in the `beforeModel` method
            so that in all methods executed after that the user is guaranteed to be
            an admin. __If `beforeModel` is overridden, ensure that the custom
            implementation calls `this._super(transition)`.
        
            @class AdminRouteMixin
            @module candidio-app/mixins/admin-route-mixin
            @extends Ember.Mixin
            @static
         */

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            if (this.get('session').get('isAuthenticated') && !this.get('current.user.isAdmin')) {
                transition.abort();
                this.transitionTo('workspaces.index');
            }
        }
    });
});