define('candidio-app/routes/workspace/users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    adapterContext: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var workspace = this.modelFor('workspace');
      if (!this.get('current.user.canManageCurrentWorkspace')) {
        this.transitionTo('workspace', workspace);
      }
    },
    model: function model() {
      var workspace = this.modelFor('workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.query('user', { limit: 100, include: 'workspaces' }).then(function (users) {
        return users.toArray();
      });
    }
  });
});