define('candidio-app/services/navigation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    isVisible: false,

    showNav: function showNav() {
      this.set('isVisible', true);
    },
    hideNav: function hideNav() {
      this.set('isVisible', false);
    },
    toggleNav: function toggleNav() {
      this.toggleProperty('isVisible');
    }
  });
});