define('candidio-app/components/production-snapshot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.csvShotTypes = ['Deliverables', 'GFX', 'Music', 'Audio', 'Project', 'Footage'];
    },


    fileSaver: Ember.inject.service(),

    /** @type {DS.Model} The Production or Snapshot model */
    snapshot: null,

    /** @type {DS.Model} */
    workspace: null,

    // Computed Properties
    uncompletedAssets: Ember.computed('snapshot.descriptions', function () {
      var descriptions = this.get('snapshot.descriptions');
      var assets = Ember.A();
      descriptions.forEach(function (description) {
        assets.addObjects(description.get('assets'));
      });
      return assets;
    }),
    uncompletedAssetsSize: Ember.computed('uncompletedAssets.[]', function () {
      var sum = 0;
      var assets = this.get('uncompletedAssets');
      _.each(assets, function (asset) {
        sum += asset.get('size');
      });
      return sum;
    }),

    actions: {
      openModal: function openModal() {
        this.sendAction.apply(this, ['openModal'].concat(Array.prototype.slice.call(arguments))); // eslint-disable-line
      },
      downloadAssetsFile: function downloadAssetsFile() {
        // Get the array of all assets
        var filename,
            content = '';

        filename = this.get('snapshot.id') + '_assets.csv';

        // Build the standard CSV front matter
        content += this.generateCSVFrontMatter(this.get('snapshot'));

        // Iterate through each asset and add them all to the content with the correct prefix
        content += this.generateCSVAssets(this.get('snapshot'), this.get('uncompletedAssets'));

        return this.get('fileSaver').save(content, "text/plain", filename);
      }
    },

    generateCSVFrontMatter: function generateCSVFrontMatter(production) {
      var _this = this;

      var content = '';
      _.each(this.get('csvShotTypes'), function (key) {
        content += _this.generateFolderName(production, key) + '\\;;\r\n';
      });
      return content;
    },
    generateCSVAssets: function generateCSVAssets(production, assets) {
      var _this2 = this;

      var content = '';
      assets.forEach(function (asset) {
        content += _this2.generateFolderName(production, 'Footage');

        // decide which key it belongs in
        content += "\\" + _this2.getAssetKey(asset) + "\\";

        // Build the asset file name
        content += ';' + encodeURIComponent(asset.get('fileName')) + '.' + asset.get('fileType');

        // Now append the file storage location
        content += ';' + asset.get('linkDownload');

        // Close the line
        content += '\r\n';
      });

      return content;
    },
    generateFolderName: function generateFolderName(production, key) {
      var workspaceFolder = this.get('workspace.workspaceName'),
          createdFolder = production.get('createdAt').format('YYYY[\\]MMMM'),
          productionFolder = this.fsEncodeURIComponent(production.get('title')) + ' (' + production.get('id') + ')';
      return workspaceFolder + '\\' + createdFolder + '\\' + productionFolder + '\\' + key;
    },
    getAssetKey: function getAssetKey(asset) {
      if (Ember.isPresent(asset.get('description'))) {
        return asset.get('description.shotType').capitalize();
      }
      return 'Other';
    },
    fsEncodeURIComponent: function fsEncodeURIComponent(str) {
      return str.replace(/[/:*?"<>|\\]/g, '-');
    }
  });
});