define('candidio-app/serializers/user', ['exports', 'candidio-app/serializers/application', 'candidio-app/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    attrs: {
      workspaces: { deserialize: 'records', serialize: false },
      avatar: { serialize: false },
      userHash: { serialize: false },
      isConfirmed: { serialize: false },
      isInvite: { serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    }
  });
});