define('candidio-app/components/download-video-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tracking: Ember.inject.service(),

    tagName: 'a',
    classNames: ['btn', 'btn--primary', 'btn--with-icon'],
    attributeBindings: ['download', 'href'],

    video: null,
    production: null,
    download: Ember.computed(function () {
      return (moment(this.get('video.createdAt')).format('YYYY-MM-DD') + '-' + this.get('production.title') + '-edited-video.' + this.get('video.fileType')).dasherize();
    }),

    href: Ember.computed.readOnly('video.linkDownload'),

    click: function click() {
      Ember.run.once(this, function () {
        this.get('tracking').trackEvent({
          event: 'Downloaded Edited Production',
          category: 'production',
          action: 'downloaded',
          productionId: this.get('production.id'),
          productionTitle: this.get('production.title'),
          fileSize: this.get('video.formattedSize'),
          fileType: this.get('video.fileType')
        });
      });
    }
  });
});