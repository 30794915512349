define('candidio-app/models/permission', ['exports', 'ember-data', 'model-fragments/fragment'], function (exports, _emberData, _fragment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _fragment.default.extend({
    workspaceId: _emberData.default.attr('string'),
    canManage: _emberData.default.attr('boolean')
  });
});