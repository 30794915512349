define('candidio-app/serializers/embedded-records-mixin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_emberData.default.EmbeddedRecordsMixin, {
    /**
       Override serializeBelongsTo to set `undefined`.
       Our API errors on null property values.
        @class DS.EmbeddedRecordsMixin
       @method serializeBelongsTo
       @version 1.13.16
       @param {DS.Snapshot} snapshot
       @param {Object} json
       @param {Object} relationship
     */
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var attr = relationship.key;
      if (this.noSerializeOptionSpecified(attr)) {
        this._super(snapshot, json, relationship);
        return;
      }
      var includeIds = this.hasSerializeIdsOption(attr);
      var includeRecords = this.hasSerializeRecordsOption(attr);
      var embeddedSnapshot = snapshot.belongsTo(attr);
      var key;
      if (includeIds) {
        key = this.keyForRelationship(attr, relationship.kind, 'serialize');
        if (!embeddedSnapshot) {
          json[key] = undefined;
        } else {
          json[key] = embeddedSnapshot.id;

          if (relationship.options.polymorphic) {
            this.serializePolymorphicType(snapshot, json, relationship);
          }
        }
      } else if (includeRecords) {
        key = this.keyForAttribute(attr, 'serialize');
        if (!embeddedSnapshot) {
          json[key] = undefined;
        } else {
          json[key] = embeddedSnapshot.record.serialize({ includeId: true });
          this.removeEmbeddedForeignKey(snapshot, embeddedSnapshot, relationship, json[key]);

          if (relationship.options.polymorphic) {
            this.serializePolymorphicType(snapshot, json, relationship);
          }
        }
      }
    }
  });
});