define('candidio-app/serializers/asset', ['exports', 'candidio-app/serializers/application', 'candidio-app/serializers/embedded-records-mixin'], function (exports, _application, _embeddedRecordsMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {
    current: Ember.inject.service(),

    attrs: {
      deliverable: { deserialize: 'records', serialize: 'id' },
      description: { deserialize: 'records', serialize: 'id' },
      production: { deserialize: 'records', serialize: 'id' },
      workspace: { deserialize: 'records', serialize: 'id' },
      revision: { deserialize: 'records', serialize: 'id' },
      videoSeries: { deserialize: 'records', serialize: 'id' },
      createdAt: { serialize: false },
      updatedAt: { serialize: false },
      isTranscoded: { serialize: false },
      linkFullsize: { serialize: false },
      linkPreviewMp4: { serialize: false },
      linkPreviewWebm: { serialize: false },
      linkThumbnail: { serialize: false },
      videoDuration: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);

      // Modifying is_completed_production is reserved for admins
      if (!this.get('current.user.isAdmin')) {
        delete json.is_completed_production;
      }

      return json;
    }
  });
});