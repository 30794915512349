define('candidio-app/components/video-length', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c__video-length'],

    minutes: Ember.computed('videoSeriesLength', function () {
      var value = this.get('videoSeriesLength');
      return Math.floor(value);
    }),
    seconds: Ember.computed('videoSeriesLength', function () {
      var value = this.get('videoSeriesLength');
      return value % 1 === 0.5 ? '30' : '0';
    }),

    actions: {
      clear: function clear() {
        this.get('update')(null, null);
      },
      setLengthAdjustment: function setLengthAdjustment(length) {
        this.set('lengthAdjustment', length);
        return this.send('update');
      },
      update: function update() {
        return this.get('update')(this.get('lengthAdjustment'), this.get('lengthReason'));
      }
    }
  });
});