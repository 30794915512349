define('candidio-app/mixins/pusher-resource-subscriptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);
      this.initPusher();
    },

    initPusher: function initPusher() {
      this.subscribeToMyWorkspaceChannel();
    },

    /**
     * Requires a `workspace` property on the object referencing the workspace model
     * to be subscribed to
     */
    subscribeToMyWorkspaceChannel: Ember.observer('workspace', function () {
      var pusherChannel = Ember.get(this, 'workspace.pusherChannel');
      if (pusherChannel) {
        Ember.get(this, 'pusher').wire(this, pusherChannel, ['production_updated', 'asset_updated', 'description_updated']);
      }
    }),

    actions: {
      productionUpdated: function productionUpdated(data) {
        var workspace = this.get('workspace');
        var record = this.store.push(this.store.normalize('production', data.data));
        record.set('workspace', workspace);
      },
      assetUpdated: function assetUpdated(data) {
        var _this = this;

        var workspace = this.get('workspace');
        // Add the assets to the current workspace
        _.forEach(data.assets, function (asset) {
          // Push the asset into the store
          var record = _this.store.push(_this.store.normalize('asset', asset));
          // Set the current workspace as the model
          record.set('workspace', workspace);
        });
      },
      descriptionUpdated: function descriptionUpdated(data) {
        this.store.push(this.store.normalize('description', data.data));
      }
    }

  });
});