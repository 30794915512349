define('candidio-app/routes/admin/workspaces/workspace/video-series/new', ['exports', 'candidio-app/mixins/dirty-confirm-route'], function (exports, _dirtyConfirmRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_dirtyConfirmRoute.default, {
    adapterContext: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    model: function model() {
      var workspace = this.modelFor('admin.workspaces.workspace');
      return this.store.createRecord('video-series', {
        workspace: workspace,
        turnaround: 5
      });
    },

    actions: {
      save: function save(callback, model) {
        var _this = this;

        var workspace = this.modelFor('admin.workspaces.workspace');
        this.get('adapterContext').setContext({ workspace: workspace });
        return callback(model.save().then(function (vs) {
          _this.messages.showSuccess('Video series created!');
          _this.transitionTo('admin.workspaces.workspace.video-series.video-series', vs);
        }));
      }
    }
  });
});