define('candidio-app/initializers/messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'messages',
    initialize: function initialize(application) {
      application.inject('controller', 'messages', 'service:messages');
      application.inject('component', 'messages', 'service:messages');
      application.inject('router', 'messages', 'service:messages');
      application.inject('route', 'messages', 'service:messages');
      application.inject('repository', 'messages', 'service:messages');
    }
  };
});