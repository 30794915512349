define('candidio-app/adapters/deliverable', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/deliverables{/id}{?query*}',

    updateRecordUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/deliverables/{id}';
      } else {
        return '{+host}/{+namespace}/deliverables/{id}';
      }
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }

  });
});