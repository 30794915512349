define('candidio-app/components/uploads-in-progress', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scottyUploader: Ember.inject.service(),

    /** @type {Array} Uploaders currently in progress */
    uploaders: Ember.computed.reads('scottyUploader.uploaders'),

    /** @type {Object} The model to scope the uploaders filter to */
    model: null,

    /** @type {String} The name of the location to scope the uploaders */
    location: null,

    /** @type {Boolean} Show/hide the file info */
    showFileInfo: false,

    filteredUploaders: Ember.computed.filter('uploaders', function (uploader) {
      var model = this.get('model');
      var location = this.get('location');
      var value = true;

      value = Ember.isNone(model) ? value : Ember.get(uploader, 'context.model.id') === Ember.get(model, 'id');
      value = Ember.isNone(location) ? value : Ember.get(uploader, 'context.location') === location;

      return value;
    }),

    /* eslint-disable */
    testUploader: {
      id: 'the-uuid-1',
      uploader: {
        file: {
          name: 'image.png',
          size: 1024,
          type: 'image/png'
        }
      },
      progress: { loaded: 512, total: 1024 },
      context: {
        model: {
          id: 'the-model-id'
        },
        location: null
      }
      /* eslint-enable */
    } });
});