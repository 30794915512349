define('candidio-app/controllers/login', ['exports', 'candidio-app/mixins/validation-engine'], function (exports, _validationEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationEngine.default, {
    authenticator: 'simple-auth-authenticator:oauth2-password-grant',
    session: Ember.inject.service(),
    validationType: 'login',
    authError: false,

    actions: {
      authenticate: function authenticate(callback) {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        return callback(this.get('session').authenticate('authenticator:oauth2', identification, password).catch(function (reason) {
          if (reason.error === 'invalid_credentials') {
            _this.set('authError', true);
            return Ember.RSVP.reject();
          }
          _this.messages.showAPIError(reason.error);
        }));
      },

      validateAndAuthenticate: function validateAndAuthenticate(callback) {
        var _this2 = this;

        this.validate({}).then(function () {
          return _this2.send('authenticate', callback);
        }).catch(function (errors) {
          _this2.messages.showErrors(errors);
          return callback(Ember.RSVP.reject());
        });
      }

    }

  });
});