define('candidio-app/adapters/repository-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RepositoryAdapterMixin = Ember.Mixin.create({

    includes: [],
    params: [],

    buildURL: function buildURL(type, id, snapshot) {
      var url = this._super(type, id, snapshot);
      return this.buildIncludeURL(url);
    },

    buildIncludeURL: function buildIncludeURL(url) {
      if (this.includes.length) {
        url += '?include=' + this.includes.join(',');
      }
      return url;
    }

  });

  exports.default = RepositoryAdapterMixin;
});