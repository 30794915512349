define('candidio-app/controllers/user/profile', ['exports', 'candidio-app/mixins/validation-engine', 'candidio-app/utils/candidio-paths'], function (exports, _validationEngine, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var UserProfileController = Ember.Controller.extend(_validationEngine.default, {
    ajax: Ember.inject.service(),
    tracking: Ember.inject.service(),
    submitting: false,
    validationType: 'user',
    user: Ember.computed.alias('model'),
    actions: {
      save: function save(callback) {
        var _this = this;

        var user = this.get('user');
        var userId = user.get('id');

        var promise = this.validate({ model: user }).then(function () {
          return _this.get('ajax').request((0, _candidioPaths.default)().url.api('users', userId), {
            type: 'PUT',
            data: JSON.stringify({
              data: {
                first_name: user.get('firstName'),
                last_name: user.get('lastName'),
                email: user.get('email')
              }
            })
          }).then(function () {
            _this.messages.showSuccess('Profile successfully saved. If you changed your email, we\'ve sent you an email to confirm the new email address.');
            _this.toggleProperty('submitting');
            _this.get('tracking').trackEvent({
              event: 'Updated Profile',
              category: 'user',
              action: 'updated-profile'
            });
          }).catch(function (errors) {
            _this.messages.showErrors(errors);
          });
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });

        callback(promise);
        return promise;
      },


      password: function password(callback) {
        var _this2 = this;

        var user = this.get('model');

        var promise = this.isPasswordValid(user).then(function () {
          return user.saveNewPassword().then(function () {
            user.setProperties({ oldPassword: null, newPassword: null });
            _this2.messages.showSuccess('Your password has been updated!');
            _this2.get('tracking').trackEvent({
              event: 'Updated Password',
              category: 'user',
              action: 'updated-password'
            });
          }).catch(function (errors) {
            return _this2.messages.showAPIError(errors);
          });
        }).catch(function (errors) {
          return _this2.messages.showErrors(errors);
        });

        callback(promise);

        return promise;
      },

      deleteUser: function deleteUser() {
        var _this3 = this;

        var userFullName = this.get('user.fullName');
        var userEmail = this.get('user.email');

        if (confirm('Are you sure you want to delete your user account? There\'s no going back')) {
          this.get('user').destroyRecord().then(function () {
            _this3.get('tracking').trackEvent({
              event: 'Closed Producer Account',
              category: 'account',
              action: 'closed',
              name: userFullName,
              email: userEmail
            });
            _this3.get('session').invalidate();
          });
        }
      }
    },

    isPasswordValid: function isPasswordValid(user) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!user.get('isPasswordValid')) {
          return reject(user.get('passwordValidationErrors'));
        }
        return resolve();
      });
    }
  });

  exports.default = UserProfileController;
});