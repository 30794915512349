define('candidio-app/helpers/lower-case', ['exports', 'ember-string-helpers/helpers/lower-case'], function (exports, _lowerCase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _lowerCase.default;
    }
  });
  Object.defineProperty(exports, 'lowerCase', {
    enumerable: true,
    get: function () {
      return _lowerCase.lowerCase;
    }
  });
});