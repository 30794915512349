define('candidio-app/controllers/user/notifications', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    tracking: Ember.inject.service(),

    user: Ember.computed.alias('model'),

    actions: {

      save: function save() {
        var _this = this;

        var user = this.get('user'),
            userId = user.get('id');

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('users', userId), {
          type: 'PUT',
          data: JSON.stringify({
            data: {
              preferences: this.get('user.preferences')
            }
          })
        }).then(function () {
          _this.get('tracking').trackEvent({
            event: 'Updated Notification Settings',
            category: 'user',
            action: 'updated-notification-settings'
          });
          _this.messages.showInfo("Your notification settings have been updated.");
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });
      }
    }
  });
});