define('candidio-app/routes/workspace/productions/production/development', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('workspace.productions.production');
    }
  });
});