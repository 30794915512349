define('candidio-app/controllers/workspace/productions/production/production', ['exports', 'candidio-app/mixins/description-sorting', 'candidio-app/mixins/save-description-task', 'candidio-app/mixins/save-production-task'], function (exports, _descriptionSorting, _saveDescriptionTask, _saveProductionTask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_descriptionSorting.default, _saveDescriptionTask.default, _saveProductionTask.default, {
    shotSorting: ['position'],

    sortedShots: Ember.computed.sort('model.descriptions', 'shotSorting'),

    uploadURL: Ember.computed('model.id', function () {
      return window.location.origin + '/public-upload/' + this.get('model.id');
    })
  });
});