define('candidio-app/components/scotty-dropzone', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['c__scotty-dropzone'],
    classNameBindings: ['draggingOver:is__dragging', 'isDragging::is-hidden'],
    isDragging: false,
    draggingOver: false,

    // We need this to calculate cancelled drags.
    // See http://stackoverflow.com/questions/11341277/detecting-a-cancelled-drag-and-drop-action-when-uploading-files
    // for details on the "interesting" implementation of HTML5 drap/drop
    counter: 0,

    didInsertElement: function didInsertElement() {
      this._bindDragEvents();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._unbindDragEvents();
      this._super.apply(this, arguments);
    },
    dragEnter: function dragEnter(e) {
      e.preventDefault();
      this.set('draggingOver', true);
    },
    dragLeave: function dragLeave(e) {
      e.preventDefault();
      this.set('draggingOver', false);
    },
    dragOver: function dragOver(e) {
      if (e.dataTransfer) {

        var effect;
        try {
          effect = e.dataTransfer.effectAllowed;
        } catch (_error) {
          return;
        }

        // IE10 and IE 11 has a bug where you can't access effectAllowed without getting an exception.
        // We swallow this exception. More details: https://github.com/enyo/dropzone/issues/441
        if (effect === 'move' || effect === 'linkMove') {
          e.dataTransfer.dropEffect = 'move'; // for FF (only move allowed)
        } else {
          e.dataTransfer.dropEffect = 'copy'; // for Chrome
        }
      }
      return false;
    },
    dragEnd: function dragEnd(e) {
      this._handleDragEnd(e);
    },
    drop: function drop(e) {
      this._handleDragEnd(e);
    },
    _handleDragEnd: function _handleDragEnd(e) {
      e.preventDefault(); // stops browser from redirecting
      var files = e.dataTransfer.files;
      return this.get('dropped')(files);
    },
    _bindDragEvents: function _bindDragEvents() {
      var $document = Ember.$(document);
      $document.on('dragenter', Ember.run.bind(this, this._setDragging));
      $document.on('dragend drop', Ember.run.bind(this, this._setNotDragging));
      $document.on('dragleave', Ember.run.bind(this, this._setNotDragging));
      $document.on('dragover', function (e) {
        if (e.dataTransfer) {
          e.dataTransfer.dropEffect = 'none';
          e.preventDefault();
        }
      });
    },
    _unbindDragEvents: function _unbindDragEvents() {
      var $document = Ember.$(document);
      $document.off('dragenter dragend drop dragleave dragover');
    },
    _setDragging: function _setDragging() {
      this.incrementProperty('counter');
      this.set('isDragging', true);
    },
    _setNotDragging: function _setNotDragging() {
      this.decrementProperty('counter');
      if (this.get('counter') === 0) {
        this.set('isDragging', false);
      }
    }
  });
});