define('candidio-app/transforms/moment-date', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        var transformed = moment.utc(serialized).local();
        return transformed.isValid() ? transformed : null;
      }
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized && moment.isMoment(deserialized)) {
        if (deserialized.isValid()) {
          return moment(deserialized).utc().format('YYYY-MM-DD HH:mm:ss');
        }
        return null;
      }
      return deserialized;
    }
  });
});