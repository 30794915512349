define('candidio-app/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    beforeModel: function beforeModel() /*transition*/{
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('login');
      } else {
        var workspaces = this.get('current.user.workspaces');
        if (workspaces.get('length') > 1) {
          return this.transitionTo('workspaces');
        }
        return this.transitionTo('workspace.index', workspaces.get('firstObject'));
      }
    }
  });
});