define('candidio-app/routes/workspace/productions/production/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var model = this.modelFor('workspace.productions.production');

      if (model.get('hasAcceptedDeliverable')) {
        return this.transitionTo('workspace.productions.production.distribution');
      }

      if (model.get('isSubmitted')) {
        return this.transitionTo('workspace.productions.production.post-production');
      }

      return this.transitionTo('workspace.productions.production.development');
    }
  });
});