define('candidio-app/controllers/invite', ['exports', 'candidio-app/utils/candidio-paths', 'candidio-app/mixins/validation-engine'], function (exports, _candidioPaths, _validationEngine) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_validationEngine.default, {

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    tracking: Ember.inject.service(),

    token: '',
    submitting: false,

    validationType: 'invite',

    actions: {
      createAccount: function createAccount(callback) {
        var _this = this;

        var firstName = this.get('model.firstName');
        var lastName = this.get('model.lastName');
        var email = this.get('model.email');
        var password = this.get('model.password');

        var model = this.get('model');

        var promise = this.validate({ model: model }).then(function () {
          return _this.get('ajax').request((0, _candidioPaths.default)().url.api('invites'), {
            type: 'POST',
            data: JSON.stringify({
              data: {
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                token: _this.get('token')
              }
            })
          }).then(function () {
            _this.get('tracking').trackEvent({
              event: 'Created Account',
              category: 'account',
              action: 'created',
              name: firstName + ' ' + lastName,
              location: 'Accept Invite Form'
            });
            return _this.get('session').authenticate('authenticator:oauth2', email, password);
          }).catch(function (errors) {
            return _this.messages.showAPIError(errors);
          });
        }).catch(function (errors) {
          return _this.messages.showErrors(errors);
        });
        callback(promise);
        return promise;
      }
    }

  });
});