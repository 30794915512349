define('candidio-app/mixins/pagination-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultPaginationSettings, PaginationRoute;

  defaultPaginationSettings = {
    limit: 10,
    page: 1
  };

  PaginationRoute = Ember.Mixin.create({

    /**
     * Sets up pagination details
     * @param {object} settings specifies additional pagination details
     */
    setupPagination: function setupPagination(settings) {
      settings = settings || {};
      for (var key in defaultPaginationSettings) {
        if (defaultPaginationSettings.hasOwnProperty(key)) {
          if (!settings.hasOwnProperty(key)) {
            settings[key] = defaultPaginationSettings[key];
          }
        }
      }

      this.set('paginationSettings', settings);
      this.controller.set('paginationSettings', settings);
    }

  });

  exports.default = PaginationRoute;
});