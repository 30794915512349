define('candidio-app/routes/admin/templates/industries/industry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('industry', params.industry_id);
    },

    renderTemplate: function renderTemplate() {
      this.render('admin.templates.industries.new', {
        controller: 'admin/templates/industries/industry'
      });
    }
  });
});