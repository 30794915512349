define('candidio-app/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    adapterContext: Ember.inject.service(),
    tracking: Ember.inject.service(),

    title: function title(tokens) {
      return tokens.reverse().join(' - ') + ' - Candidio';
    },

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super(transition);
      if (Ember.get(this, 'session.isAuthenticated')) {
        return this._populateCurrentUser().then(function (user) {
          _this._identifyUser(user);
        });
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      return this._populateCurrentUser().then(function (user) {

        var workspace = user.get('workspaces.firstObject');
        var attemptedTransition = _this2.get('session.attemptedTransition');

        _this2._identifyUser(user);

        /** If the user was trying to access a particular route, try it again */
        if (attemptedTransition) {
          attemptedTransition.retry();
          return _this2.get('session').set('attemptedTransition', null);
        }

        // If the user hase more than one workspace or a workspace invite, we'll show the workspace chooser,
        // otherwise we'll send them directly to their workspace
        if (user.get('workspaces.length') > 1 || user.get('workspaces.length') === 0 || !Ember.isNone(user.get('workspaces').findBy('isInvite', true))) {
          return _this2.transitionTo('workspaces');
        }

        return _this2.transitionTo('workspace.index', workspace);
      });
    },


    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },


      openModal: function openModal(modalName, model) {
        modalName = 'modals/' + modalName;
        // We don't always require a modal to have a controller
        // so we're skipping asserting if one exists

        if (this.controllerFor(modalName, true)) {
          this.controllerFor(modalName).set('model', model);
        }
        return this.render(modalName, {
          into: 'application',
          outlet: 'modal'
        });
      },

      closeModal: function closeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }
    },

    _populateCurrentUser: function _populateCurrentUser() {
      var _this3 = this;

      return this.store.findRecord('user', 'me', {
        adapterOptions: {
          query: {
            include: 'workspaces'
          }
        }
      }).then(function (user) {
        _this3.get('current').set('user', user);
        return user;
      });
    },
    _identifyUser: function _identifyUser(user) {
      // Setup Tracking & Analytics
      if (!Ember.isEmpty(user) && !user.get('isAdmin')) {
        Ember.get(this, 'tracking').identifyUser(user.getProperties('id', 'userHash', 'firstName', 'lastName', 'email', 'createdAt', 'isAdmin', 'isBeta'));
      }
    }
  });
});