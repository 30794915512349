define('candidio-app/components/workspace-user-tr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    user: null,
    isShowingRemoveModal: false,
    canManage: Ember.computed.reads('user.canManageCurrentWorkspace'),
    isCurrentUser: Ember.computed('user.id', function () {
      return this.get('current.user.id') === this.get('user.id');
    }),
    actions: {
      toggleRemoveModal: function toggleRemoveModal() {
        this.toggleProperty('isShowingRemoveModal');
      }
    }
  });
});