define('candidio-app/components/search-bar', ['exports', 'candidio-app/utils/candidio-paths', 'ember-inflector'], function (exports, _candidioPaths, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    classNames: ['search'],
    placeholder: 'Search',
    searchResource: 'workspaces',
    searchTerm: '',
    showSearchList: false,
    selectedIndex: -1,
    searchResultList: null,
    isLoading: false,

    searchEndpoint: Ember.computed('searchResource', function () {
      var endpoint = '';
      switch (this.get('searchResource')) {
        case 'workspaces':
        case 'users':
          endpoint = (0, _candidioPaths.default)().url.api(this.get('searchResource')) + '?limit=15&search=';
          break;
        case 'projects':
        case 'productions':
          endpoint = (0, _candidioPaths.default)().url.api(this.get('searchResource')) + '?include=workspace&limit=15&search=';
          break;
      }
      return endpoint;
    }),

    selectedResult: Ember.computed('selectedIndex', function () {
      if (this.get('selectedIndex') >= 0) {
        return this.get('searchResultList').objectAt(this.get('selectedIndex'));
      } else {
        return null;
      }
    }),

    resultCount: Ember.computed('searchResultList', function () {
      return this.get('searchResultList.length');
    }),

    searchTermObserver: Ember.observer('searchTerm', function () {
      this.set('selectedIndex', -1);

      /* We only want to search for results if we have a word 3 characters in length */
      if (!/\w{3,}/.test(this.get('searchTerm'))) {
        this.set('searchResultList', null);
      } else {
        this.set('isLoading', true);
        Ember.run.debounce(this, this.perform, 300);
      }
    }),

    perform: function perform() {
      var _this2 = this;

      return this.get('ajax').request(this.get('searchEndpoint') + '%2A' + this.get('searchTerm') + '%2A', {
        type: 'GET'
      }).then(function (resp) {
        _this2.set('searchResultList', resp.data);
        _this2.set('showSearchList', true);
        _this2.set('isLoading', false);
      });
    },


    keyDown: function keyDown(event) {
      if (event.keyCode === 13) {
        this.send('searchEnter');
      } else if (event.keyCode === 40) {
        //downkey
        this.send('moveDown');
      } else if (event.keyCode === 38) {
        //upkey
        this.send('moveUp');
      } else if (event.keyCode === 27) {
        //escape
        this.send('clear');
      } else if (event.ctrlKey === true) {
        if (event.keyCode === 78) {
          // CTRL + n
          this.send('moveDown');
        } else if (event.keyCode === 80) {
          // CTRL + p
          this.send('moveUp');
        }
      } else {
        return true;
      }
      return false;
    },

    /* eslint-disable */
    checkForFocus: Ember.on('focusOut', function () {
      var _this = this;
      Ember.run.later(function () {
        if (!Ember.isEmpty(_this.get('element')) && !Ember.$.contains(_this.get('element'), document.activeElement)) {
          _this.set('showSearchList', false);
        }
      }, 300);
    }),
    /* eslint-enable */

    actions: {
      clickResult: function clickResult(result) {
        var pluralResource = this.get('searchResource');
        var singularResource = (0, _emberInflector.singularize)(pluralResource);
        this.set('showSearchList', false);
        if (result === null) {
          result = this.get('searchResultList').objectAt(0);
        }
        if (result) {
          this.get('_targetObject').transitionToRoute('admin.' + pluralResource + '.' + singularResource, result.id);
          this.set('searchTerm', '');
        }
      },
      clear: function clear() {
        this.set('searchTerm', '');
        this.set('selectedIndex', -1);
      },
      searchEnter: function searchEnter() {
        this.send('clickResult', this.get('selectedResult'));
      },
      moveUp: function moveUp() {
        if (this.get('selectedIndex') > 0) {
          this.decrementProperty('selectedIndex');
        }
      },
      moveDown: function moveDown() {
        if (this.get('selectedIndex') < this.get('resultCount')) {
          this.incrementProperty('selectedIndex');
        }
      }
    }
  });
});