define('candidio-app/components/candidio-video-player', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['c__video-player'],

    mp4src: null,
    webmsrc: null,
    poster: null,

    autoplay: false,

    isPlaying: false,

    // didInsertElement() {
    //   this._super(...arguments);
    //   this.set('videoElement', this.$('video')[0]);

    //   // Do we have access to the Fullscreen API?
    //   this.set('fullscreenEnabled', !!(document.fullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled || document.webkitSupportsFullscreen || document.webkitFullscreenEnabled || document.createElement('video').webkitRequestFullScreen));

    //   // Disable native video controls
    //   this.get('videoElement').controls = false;
    // },

    actions: {
      togglePlay: function togglePlay() {
        this._togglePlay();
      },
      mute: function mute() {
        this._toggleMute();
      },
      fullscreen: function fullscreen() {
        this._handleFullscreen();
      }
    },

    _toggleMute: function _toggleMute() {
      this.get('videoElement').muted = !this.get('videoElement').muted;
    },
    _togglePlay: function _togglePlay() {
      var videoElement = this.get('videoElement');

      if (videoElement.paused || videoElement.ended) {
        this.set('isPlaying', true);
        return videoElement.play();
      }

      this.set('isPlaying', false);
      return videoElement.pause();
    },
    _isFullscreen: function _isFullscreen() {
      return !!(document.fullScreen || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
    },
    _handleFullscreen: function _handleFullscreen() {
      var videoContainer = this.element;
      if (this._isFullscreen()) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullScreen) {
          videoContainer.webkitRequestFullScreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }
      }
    }
  });
});