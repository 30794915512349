define('candidio-app/services/scotty-uploader', ['exports', 'candidio-app/config/environment', 'ember-uuid/utils/uuid-generator'], function (exports, _environment, _uuidGenerator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      window.onbeforeunload = function (e) {
        if (_this.get('isUploading')) {
          e = e || window.event;
          // IE and FF < 4
          if (e) {
            e.returnValue = 'You have uploads in progress.';
          }
          return 'You have uploads in progress.';
        }
      };

      this.progress = {};
    },


    /**
     * Array of active uploader objects. These get removed from the array
     * as they complete.
     *
     * [
     *   {
     *     id: 'uuid',
     *     uploader: {},
     *     progress: {
     *       loaded: 0,
     *       total: 0
     *     },
     *     context: {
     *       model: model,
     *       location: "completed|other|audio"
     *     }
     *   }
     * ]
     */
    uploaders: Ember.A(),

    isUploading: Ember.computed.notEmpty('uploaders'),

    /**
     * Upload a hash of files
     * @param  {Array}  files An array of files to upload
     * @return {void}
     */
    uploadFiles: function uploadFiles() {
      var files = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      for (var i = 0; i < files.length; i++) {
        this.uploadFile(files.item(i));
      }
      return this.start();
    },


    /**
     * Trigger a file upload.
     * @param  {File}   file      HTML File Object
     * @param  {object} settings  JSON object of settings to override
     * @param  {object} callbacks JSON object of callback methods to be called
     */
    uploadFile: function uploadFile(file, path, context, callbacks) {
      var _this2 = this;

      callbacks.onProgress = callbacks.onProgress || function () {};
      callbacks.onCancel = callbacks.onCancel || function () {};
      callbacks.onComplete = callbacks.onComplete || function () {};
      callbacks.onError = callbacks.onError || function () {};
      context = context || {};

      var uploader = void 0;

      var uploaders = Ember.get(this, 'uploaders');
      var uploadId = _uuidGenerator.default.v4();
      var folder = 'c3/uploads/' + _environment.default.environment + '/' + path + '/';

      /** Sanitize the file name by replacing invalid chars */
      var fileName = this._sanitizeAndTimestampFilename(file.name);

      var uploaderSettings = {
        bucket: _environment.default.scottyUploader.bucket,
        region: _environment.default.scottyUploader.region,
        key: '' + folder + fileName || '' + _environment.default.scottyUploader.folder + fileName,
        signatureBackend: _environment.default.scottyUploader.signatureHost,
        initHeadersPath: "/get_init_headers",
        listHeadersPath: "/get_list_headers",
        chunkHeadersPath: "/get_chunk_headers",
        completeHeadersPath: "/get_complete_headers",
        ssl: _environment.default.scottyUploader.ssl || false,
        useWebWorkers: _environment.default.scottyUploader.useWebWorkers || false,
        workerFilePath: '/javascripts/dist/basic_s3_worker.js',
        uploaderFilePath: '/javascripts/dist/basic_s3_uploader.js',
        onStart: function onStart() {

          //
          // {
          //   id: string,
          //   uploader: uploader ojb,
          //   progress: hash,
          //   context: {
          //     model: model,
          //     location: "completed|other-content|audio"
          // }
          //
          // {{#uploads-in-progress contextModel=model contextLocation="completed"}}
          //   {{#each uploads as |upload|}}
          //    ...
          //   {{/each}}
          // {{/uploads-in-progress}}
          //

          uploaders.pushObject({
            id: uploadId,
            uploader: uploader,
            progress: { loaded: 0, total: 0 },
            context: {
              model: Ember.get(context, 'model'),
              location: Ember.get(context, 'location')
            }
          });
        },
        onProgress: function onProgress(loaded, total) {
          var i = _this2._findUploaderById(uploadId);
          var progress = uploaders.objectAt(i).progress;
          Ember.set(progress, 'loaded', loaded);
          Ember.set(progress, 'total', total);
          callbacks.onProgress.call(uploader, loaded, total);
        },
        onComplete: function onComplete() {
          var i = _this2._findUploaderById(uploadId);
          uploaders.removeObject(uploaders.objectAt(i));
          callbacks.onComplete.call(uploader, _this2._buildResponseObject(uploader));
        },
        onCancel: function onCancel() {
          var i = _this2._findUploaderById(uploadId);
          uploaders.removeObject(uploaders.objectAt(i));
          callbacks.onCancel.call(uploader);
        },
        onError: function onError(errorCode, description) {
          callbacks.onError.call(uploader, errorCode, description);
          uploader.cancelUpload();
        }
      };

      uploader = new bs3u.Uploader(file, uploaderSettings);
      uploader.startUpload();
    },

    /**
     * Find the uploader by it's id and return it's index in the `uploaders` array
     * @param  {String} id The UUID of the upload
     * @return {int}    index
     */
    _findUploaderById: function _findUploaderById(id) {
      return _.findIndex(Ember.get(this, 'uploaders'), { 'id': id });
    },


    /**
     * Build the object the API expects to create an asset.
     * @param  {Object} uploader The uploader class
     * @return {Object}          Hash of the properties required by API
     */
    _buildResponseObject: function _buildResponseObject(uploader) {
      var fileName = uploader.file.name.split(/\.(?=[^.]*$)/)[0],
          fileType = uploader.file.name.split(/\.(?=[^.]*$)/)[1];
      return {
        bucket: uploader.settings.bucket,
        object_key: uploader.settings.key,
        file_name: fileName,
        file_type: fileType,
        file_path: uploader.settings.host + "/" + uploader.settings.key,
        size: uploader.file.size,
        mimetype: uploader.file.type || 'application/octet-stream'
      };
    },


    /**
     * Sanitize the filename by removing invalid characters and
     * prefix with the current timestamp.
     * @param  {String} filename The file name
     * @return {String}          The sanitized file name
     */
    _sanitizeAndTimestampFilename: function _sanitizeAndTimestampFilename(filename) {
      return new Date().getTime() + "_" + filename.replace(/[\\/?:!'()*,]/g, '-');
    }
  });
});