define('candidio-app/validators/new-producer', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('firstName', 'lastName', 'email', 'workspaceName'),
                validationErrors = [];

            if (!validator.isLength(data.firstName, 1, 150)) {
                validationErrors.push({
                    message: "Gotta enter a first name."
                });
            }

            if (!validator.isLength(data.lastName, 1, 150)) {
                validationErrors.push({
                    message: "And a last name!"
                });
            }

            if (!validator.isEmail(data.email)) {
                validationErrors.push({
                    message: 'Something is wacky with that email address.'
                });
            }

            if (!validator.isLength(data.workspaceName, 1)) {
                validationErrors.push({
                    message: 'Hey, make sure you enter a workspace name.'
                });
            }

            return validationErrors;
        }
    });
});