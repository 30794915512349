define('candidio-app/components/candidio-select-industry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    industries: Ember.computed(function () {
      return this.store.findAll('industry');
    })
  });
});