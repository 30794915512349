define('candidio-app/controllers/admin/workspaces/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['page', 'limit', 'sort'],
    page: 1,
    limit: 10,
    sort: 'name',
    metaData: Ember.computed.alias('model.meta.pagination'),
    totalPages: Ember.computed.alias('model.meta.pagination.total_pages'),

    actions: {

      prevPage: function prevPage() {
        this.decrementProperty('page');
      },

      nextPage: function nextPage() {
        this.incrementProperty('page');
      }

    }
  });
});