define('candidio-app/models/template', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // Attributes
    description: _emberData.default.attr('string'),
    editorPick: _emberData.default.attr('boolean'),
    imageUrl: _emberData.default.attr('string'),
    isPublished: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    popularity: _emberData.default.attr('number'),
    template: _emberData.default.attr(),
    createdAt: _emberData.default.attr('moment-date'),
    updatedAt: _emberData.default.attr('moment-date'),

    // Relationships
    industry: _emberData.default.belongsTo('industry', { async: false }),
    templateType: _emberData.default.belongsTo('templateType', { async: false }),
    workspace: _emberData.default.belongsTo('workspace', { async: false }),

    // Computed Properties
    brolls: Ember.computed.filterBy('template.descriptions', 'type', 'broll'),
    interviews: Ember.computed.filterBy('template.descriptions', 'type', 'interview'),
    templateImage: Ember.computed(function () {
      return Ember.String.htmlSafe(this.get('imageUrl'));
    })

  });
});