define('candidio-app/validators/forgotten', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ForgottenValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('email'),
                validationErrors = [];

            if (!validator.isEmail(data.email)) {
                validationErrors.push({
                    message: 'Invalid Email'
                });
            }

            return validationErrors;
        }
    });

    exports.default = ForgottenValidator;
});