define('candidio-app/components/candidio-redactor-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CandidioRedactorEditorComponent = Ember.Component.extend({
    tagName: 'div',
    init: function init() {
      this._super();

      this.on("focusOut", this, this._elementValueDidChange);
      this.on("change", this, this._elementValueDidChange);
      this.on("paste", this, this._elementValueDidChange);
      this.on("cut", this, this._elementValueDidChange);
      this.on("input", this, this._elementValueDidChange);
    },
    _updateElementValue: Ember.observer(function () {
      var $el, value;
      value = Ember.get(this, "body");
      $el = this.$().context;
      if (typeof value === 'undefined') {
        return $el.innerHTML = '';
      }
      if ($el && value !== $el.innerHTML) {
        return $el.innerHTML = value;
      }
    }, "body"),
    _elementValueDidChange: function _elementValueDidChange() {
      var $el;
      $el = this.$().context;
      return Ember.set(this, "body", $el.innerHTML);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$().redactor({
        toolbarFixedBox: true,
        buttons: ['bold', 'italic', 'underline', 'deleted', 'unorderedlist', 'orderedlist', 'outdent', 'indent', 'alignment', 'horizontalrule'],
        blurCallback: function blurCallback() {
          self.sendAction();
        }
      });
      this._updateElementValue();
    }
  });

  exports.default = CandidioRedactorEditorComponent;
});