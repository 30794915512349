define('candidio-app/serializers/video-series', ['exports', 'candidio-app/serializers/embedded-records-mixin', 'candidio-app/serializers/application'], function (exports, _embeddedRecordsMixin, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_embeddedRecordsMixin.default, {

    /*
      assets       : hasMany('asset', { async: false }),
      createdBy    : belongsTo('user', { async: false }),
      collaborators: hasMany('user' { async: false }),
      exampleVideo : belongsTo('asset', { async: false }),
      productions  : hasMany('production', { async: false }),
      template     : belongsTo('template', { async: false }),
      workspace    : belongsTo('workspace', { async: false })
     */

    attrs: {
      assets: { deserialize: 'records', serialize: false },
      collaborators: { deserialize: 'records', serialize: false },
      createdBy: { deserialize: 'records', serialize: false },
      exampleVideo: { deserialize: 'records', serialize: 'id' },
      productions: { deserialize: 'records', serialize: false },
      template: { deserialize: 'records', serialize: 'id' },
      workspace: { deserialize: 'records', serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false }
    }
  });
});