define('candidio-app/authorizers/application', ['exports', 'ember-simple-auth/authorizers/oauth2-bearer'], function (exports, _oauth2Bearer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2Bearer.default.extend({
    authorize: function authorize(data, block) {
      block('Content-Type', 'application/json');
      this._super(data, block);
    }
  });
});