define('candidio-app/services/pusher', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  /* eslint-disable ember/no-function-prototype-extensions */

  var __bind = function __bind(fn, me) {
    return function () {
      return fn.apply(me, arguments);
    };
  };

  var PusherController = Ember.Service.extend({

    // Need to track
    // 1) channel object
    // 2) event bindings which consist of
    //    - handler
    //    - event name
    //    - a unique string representing the target
    //
    //  bindings: {
    //    'channel-one': {
    //      channel: Pusher.Channel,
    //      eventBindings: {
    //        Ember.Route.toString(): [
    //          { handler: Function, eventName: String },
    //          { handler: Function, eventName: String }
    //        ]
    //      }
    //    }
    //  }
    //
    //  wire(target, channelName, events)
    //  ================
    //  Initialize object in bindings if it's empty, with eventBindings: {}
    //  If eventBindings.length for the current target is 0
    //    connect to the channel
    //    store channel in the hash
    //  For each event in events
    //    bind the channel to the eventName
    //    store the handler and eventName in the eventBindings array for this channel and controller
    //    the key for storing is in target._pusherTargetId()
    //    (we store the eventName for when we might want to programmatically unwire)
    //
    //
    //  unwire(route):
    //  =================
    //  get the channel object
    //  for each  handler, eventName in eventBindings for the current route
    //    call channel.unbind(eventName, handler)
    //  delete the routes record in EventBindings
    //  if eventBindings for this channel is empty
    //    unsubscribe from the channel
    //    delete the channel from bindings

    connection: null,
    isDisconnected: true,
    isConnected: Ember.computed.not('isDisconnected'),

    init: function init() {
      this._super();
      this.set('bindings', {});

      /** Bail early if testing */
      // if (config.environment === 'test') { return; }

      if (_environment.default.environment !== 'test') {
        var options = _environment.default.PUSHER;
        var pusher = new Pusher(options.KEY, options.CONNECTION);
        this.didCreatePusher(pusher);
      }
    },

    // instantiatePusher: observer('current.user', function () {
    //   if (this.get('session.isAuthenticated')) {
    //     let options = config.PUSHER;
    //     let pusher = new Pusher(options.KEY, options.CONNECTION);
    //     this.didCreatePusher(pusher);
    //   }
    // }),

    // Called by the application initializer
    didCreatePusher: function didCreatePusher(pusher) {
      this.set('connection', pusher);
      pusher.connection.bind('connected', __bind(this._didConnect, this));
      pusher.connection.bind('disconnected', __bind(this._didDisconnect, this));
      pusher.connection.bind('unavailable', __bind(this._didDisconnect, this));
    },

    // @events a hash in the form { channel-name: ['event1', 'event2'] }
    // @target any object that responds to send() and _pusherEventsId()
    wire: function wire(target, channelName, events) {
      if (_environment.default.environment === 'test') {
        return false;
      }

      (false && !(!!target._pusherEventsId) && Ember.assert("Did you forget to extend the PusherBindings mixin in " + "your class receiving events?", !!target._pusherEventsId));

      var channel = this.connectChannel(channelName),
          bindings = this.get('bindings'),
          targetId = target._pusherEventsId();

      // Setup the eventBindings array for this target
      if (!bindings[channelName].eventBindings[targetId]) {
        bindings[channelName].eventBindings[targetId] = [];
      }

      // Iterate over the events and bind them
      events.forEach(function (eventName) {
        var normalizedEventName = Ember.String.camelize(eventName),
            handler;
        handler = function handler(data) {
          if (target.get('logPusherEvents')) {
            /* eslint-disable no-console */
            console.log(target.constructor.toString() + ": Pusher event received", eventName, data);
            /* eslint-enable no-console */
          }
          target.send(normalizedEventName, data);
        };
        channel.bind(eventName, handler);
        bindings[channelName].eventBindings[targetId].pushObject({
          handler: handler,
          eventName: eventName
        });
      });
    },

    connectChannel: function connectChannel(channelName) {
      var pusher = this.get('connection'),
          bindings = this.get('bindings');
      if (!bindings[channelName]) {
        bindings[channelName] = { eventBindings: {} };
      }
      if (Ember.isEmpty(Object.keys(bindings[channelName].eventBindings))) {
        bindings[channelName].channel = pusher.subscribe(channelName);

        // Spit out a bunch of logging if asked
        if (this.namespace && this.namespace.PUSHER_OPTS.logAllEvents) {
          bindings[channelName].channel.bind_all(function (eventName, data) {
            /* eslint-disable no-console */
            console.log("Pusher event received on " + channelName + ":", eventName, data);
            /* eslint-enable no-console */
          });
        }
      }
      return bindings[channelName].channel;
    },

    unwire: function unwire(target, channelName) {
      var pusher = this.get('connection'),
          bindings = this.get('bindings'),
          targetId = target._pusherEventsId(),
          channel = bindings[channelName].channel;

      // Unbind all the events for this target
      for (var binding in bindings[channelName].eventBindings[targetId]) {
        channel.unbind(binding.eventName, binding.handler);
      }
      delete bindings[channelName].eventBindings[targetId];

      // Unsubscribe from the channel if this is the last thing listening
      if (Object.keys(bindings[channelName].eventBindings).length === 0) {
        pusher.unsubscribe(channelName);
        delete bindings[channelName];
        return true;
      }
      return false;
    },

    channelFor: function channelFor(channelName) {
      // debugger;
      return this.get('bindings')[channelName].channel;
    },

    socketId: function () {
      try {
        return this.get('connection').connection.socket_id;
      } catch (error) {
        /* eslint-disable no-console */
        console.warn(error);
        /* eslint-enable no-console */
      }
    }.on('isDisconnected'),

    _didConnect: function _didConnect() {
      this.set('isDisconnected', false);
    },

    _didDisconnect: function _didDisconnect() {
      this.set('isDisconnected', true);
    }

  });

  exports.default = PusherController;
});