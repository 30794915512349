define('candidio-app/routes/admin/workspaces/workspace/templates/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    adapterContext: Ember.inject.service(),

    model: function model() {
      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.query('template', {
        limit: 100,
        include: ['template_type', 'workspace', 'industry'].join(',')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('workspace', this.modelFor('admin.workspaces.workspace'));
    }
  });
});