define('candidio-app/routes/admin/templates/template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('template', params.template_id, {
        adapterOptions: {
          query: {
            include: ['template_type', 'workspace', 'industry'].join(',')
          }
        }
      });
      // return this.repository.template.getTemplateById(params.template_id);
    },

    renderTemplate: function renderTemplate() {
      this.render('admin.templates.new', {
        controller: 'admin/templates/template'
      });
    }
  });
});