define('candidio-app/validators/create-workspace', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var CreateWorkspaceValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('company'),
                validationErrors = [];

            if (!validator.isLength(data.company, 1)) {
                validationErrors.push({
                    message: 'Enter your workspace name...or else.'
                });
            }

            return validationErrors;
        }
    });

    exports.default = CreateWorkspaceValidator;
});