define('candidio-app/controllers/workspaces/index', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),

    actions: {

      acceptInvite: function acceptInvite(workspace) {
        var self = this;
        // PUT /workspaces/{id}/users/{id}/attach

        workspace.set('isInvite', false);

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', self.get('current.user.id'), 'attach'), {
          type: 'PUT'
        });
      },

      declineInvite: function declineInvite(workspace) {
        var self = this;
        // DELETE /workspaces/{id}/users/{id}/detach

        // Remove from UI
        this.get('current.user.workspaces').removeObject(workspace);

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', self.get('current.user.id'), 'detach'), {
          type: 'DELETE'
        });
      }

    }

  });
});