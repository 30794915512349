define('candidio-app/components/sharable-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'li',
    classNames: ['u__media in-list u__media--vertically-centered'],

    user: null,
    workspace: null,

    /** Video Series or Production to share */
    model: null,

    isOwner: Ember.computed(function () {
      return this.get('user.id') === this.get('model.createdBy.id');
    }),

    canViewAll: Ember.computed.reads('user.canManageCurrentWorkspace'),

    isShared: Ember.computed('model.collaborators', function () {
      var collaborators = Ember.get(this, 'model.collaborators');
      return Ember.isEmpty(collaborators) ? false : collaborators.includes(Ember.get(this, 'user'));
    }),

    actions: {
      share: function share(callback) {
        this.get('share')(callback, this.get('user'));
      },
      remove: function remove(callback) {
        this.get('remove')(callback, this.get('user'));
      }
    }
  });
});