define("candidio-app/utils/split-filename", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = splitFilename;
  function splitFilename(file) {
    return {
      name: file.split(/\.(?=[^.]*$)/)[0],
      type: file.split(/\.(?=[^.]*$)/)[1]
    };
  }
});