define('candidio-app/routes/workspace/productions/production/production', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    adapterContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    tracking: Ember.inject.service(),

    model: function model() {
      return this.modelFor('workspace.productions.production');
    },
    _uploadFiles: function _uploadFiles(files, description) {
      var _this = this;

      _.forEach(files, function (file) {
        return _this._uploadFile(file, description);
      });
    },
    _uploadFile: function _uploadFile(file, description) {
      var _this2 = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');
      var path = workspace.get('id') + '/' + production.get('id') + '/assets';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, { model: description }, {
        onComplete: function onComplete(res) {
          return _this2._completeFileUpload(res, description);
        },
        onError: function onError() {
          return _this2.messages.showError('Error uploading your file.');
        }
      });
    },
    _attachAssetToDescription: function _attachAssetToDescription(asset, description) {
      var _this3 = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');

      if (asset.get('description.id') === description.get('id')) {
        return;
      }

      description.get('assets').pushObject(asset);

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'assets', asset.get('id'), 'descriptions', description.get('id'), 'attach'), {
        type: 'PUT'
      }).then(function () {
        _this3.get('tracking').trackEvent({
          event: 'Added Asset to Shot',
          category: 'production',
          action: 'added-asset-to-shot',
          location: 'production',
          productionTitle: production.get('title')
        });
      }).catch(function (errors) {
        return _this3.messages.showAPIError(errors);
      });
    },
    _completeFileUpload: function _completeFileUpload(payload, description) {
      var _this4 = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');

      var asset = this.store.createRecord('asset', {
        bucket: payload.bucket,
        fileName: payload.file_name,
        fileType: payload.file_type,
        objectKey: payload.object_key,
        size: payload.size,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype,
        description: description,
        workspace: workspace
      });

      this.get('adapterContext').setContext({ workspace: workspace });

      return asset.save().then(function (asset) {
        _this4.get('tracking').trackEvent({
          event: 'Uploaded Asset',
          category: 'asset',
          action: 'uploaded',
          productionName: production.get('title'),
          shot: description.get('fields.field1'),
          mimetype: asset.get('mimetype'),
          size: asset.get('size')
        });
      });
    },


    actions: {
      attachAssetToDescription: function attachAssetToDescription(assetId, description) {
        var asset = this.store.peekRecord('asset', assetId);
        return this._attachAssetToDescription(asset, description);
      },
      deleteAsset: function deleteAsset(asset) {
        var _this5 = this;

        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');

        if (!confirm('Are you sure you want to delete this file?')) {
          return false;
        }

        this.get('adapterContext').setContext({ workspace: workspace });
        return asset.destroyRecord().then(function () {
          _this5.get('tracking').trackEvent({
            event: 'Deleted Asset',
            category: 'production',
            action: 'deleted-asset',
            location: 'storyboard',
            productionTitle: production.get('title')
          });
        }).catch(function (errors) {
          return _this5.messages.showAPIError(errors);
        });
      },
      uploadFiles: function uploadFiles() {
        return this._uploadFiles.apply(this, arguments);
      }
    }
  });
});