define('candidio-app/helpers/candidio-gt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.candidioGt = candidioGt;
  function candidioGt(params) {
    return params[0] > params[1] ? params[2] || true : params[3] || false;
  }

  exports.default = Ember.Helper.helper(candidioGt);
});