define('candidio-app/controllers/admin/templates/industries/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.sortProperties = ['name'];
    },


    arrangedContent: Ember.computed.sort('model', 'sortProperties')
  });
});