define('candidio-app/components/search-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['selected'],
    selected: Ember.computed('selectedResult', 'result', function () {
      return this.get('selectedResult.id') === this.get('result.id');
    })
  });
});