define('candidio-app/components/avatar-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    classNames: ['c__avatar'],

    classNameBindings: ['avatarClass'],

    user: null,

    size: 'compact',

    // The tooltip will show the user's name on hover
    tooltip: false,

    avatar: Ember.computed.alias('user.avatar'),

    initials: Ember.computed('user.{firstName,lastName}', function () {
      if (Ember.isPresent(this.get('user.firstName'))) {
        var firstInitial = this.get('user.firstName').charAt(0).toUpperCase();
        var lastInitial = this.get('user.lastName').charAt(0).toUpperCase();
        return firstInitial + lastInitial;
      }
      return null;
    }),

    tooltipText: Ember.computed('user.firstName', function () {
      if (this.get('user.firstName') !== null) {
        return this.get('user.fullName');
      }
      return this.get('user.email');
    }),

    avatarClass: Ember.computed('size', function () {
      return 'm__' + this.get('size');
    }),

    avatarStyle: Ember.computed('avatar', function () {
      return Ember.String.htmlSafe('background-color: #' + this.get('avatar.color') + ';');
    }),

    avatarSource: Ember.computed('user', function () {
      var size = this.get('size');
      return Ember.String.htmlSafe(Ember.get(this, 'avatar.image_urls.' + size));
    }),

    userHasAvatar: Ember.computed.notEmpty('avatarSource.string')
  });
});