define('candidio-app/serializers/application', ['exports', 'ember-inflector', 'ember-data'], function (exports, _emberInflector, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({

    /* API attribute names are underscored and lower case */
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr).toLowerCase();
    },


    isNewSerializerAPI: true,

    /*
     * Overload serializer methods to make the root object 'data'
     */

    /* GET single record */
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      payload[primaryModelClass.modelName] = payload.data;
      delete payload.data;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },


    /* GET collection */
    normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      payload[(0, _emberInflector.pluralize)(primaryModelClass.modelName)] = payload.data;
      delete payload.data;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },


    /*
      Customize the root key serialized into JSON.
      Our API expects the root key to be `data`.
    */
    serializeIntoHash: function serializeIntoHash(hash, typeClass, snapshot, options) {
      hash['data'] = this.serialize(snapshot, options);
    },


    /**
     * Override keyForRelationship to append `_id` to serialized relationship keys
     */
    keyForRelationship: function keyForRelationship(key, typeClass, method) {
      if (method === 'serialize' && this.hasSerializeIdsOption(key)) {
        return Ember.String.underscore(key) + '_id';
      }
      return this._super.apply(this, arguments);
    }
  });
});