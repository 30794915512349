define('candidio-app/routes/admin/templates/types/type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('templateType', params.type_id);
    },

    renderTemplate: function renderTemplate() {
      this.render('admin.templates.types.new', {
        controller: 'admin/templates/types/type'
      });
    }
  });
});