define('candidio-app/initializers/lib-setup', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'lib-setup',

    initialize: function initialize() /* registry, application */{
      /** Addwindow.location.origin support fot IE
          http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer */
      if (!window.location.origin) {
        window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
    }
  };
});