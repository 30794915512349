define('candidio-app/routes/workspace', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    adapterContext: Ember.inject.service(),
    tracking: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return model.get('workspaceName');
    },


    model: function model(params) {
      return this.store.findRecord('workspace', params.workspace_id);
    },

    afterModel: function afterModel(workspace) {
      this.set('workspace', workspace);
      this.get('adapterContext').setContext({ workspace: workspace });
      this.store.query('user', { limit: 100 }).then(function (users) {
        workspace.get('users').pushObjects(users);
      });
      this._identifyWorkspace(workspace);
    },

    activate: function activate() {
      var workspace = this.modelFor(this.routeName);
      this._identifyWorkspace(workspace);
    },
    _identifyWorkspace: function _identifyWorkspace(workspace) {
      this.get('current').setCurrent({ workspace: workspace });

      // For GA, Intercom, & Mixpanel
      if (!this.get('current.user.isAdmin')) {
        this.get('tracking').identifyWorkspace({
          id: workspace.get('id'),
          name: workspace.get('workspaceName'),
          createdAt: workspace.get('createdAt'),
          phone: workspace.get('phone')
        });
      }
    },
    _clearWorkspace: function _clearWorkspace() {
      this.get('current').resetCurrent();
    }
  });
});