define('candidio-app/components/candidio-async-button', ['exports', 'candidio-app/components/async-button', 'candidio-app/templates/components/candidio-async-button'], function (exports, _asyncButton, _candidioAsyncButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _asyncButton.default.extend({
    layout: _candidioAsyncButton.default,
    classNames: ['btn', 'btn--async']
  });
});