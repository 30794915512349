define('candidio-app/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    flashMessages: Ember.inject.service(),
    scottyUploader: Ember.inject.service('scotty-uploader'),

    hideNav: Ember.computed.match('currentPath', /(invalid|login|invite|create|coaching|forgotten|reset|public-upload|loading)/),

    // Check if the user is on private browsing mode
    privateBrowsing: Ember.computed(function () {
      return !Modernizr.localstorage;
    }),

    // Always scroll to top on path change
    currentPathChanged: Ember.observer('currentPath', function () {
      return window.scrollTo(0, 0);
    }),

    actions: {
      showWorkspaceSetupMessage: function showWorkspaceSetupMessage() {
        this.set('showWorkspaceSetupMessage', true);
      },
      hideWorkspaceSetupMessage: function hideWorkspaceSetupMessage() {
        this.set('showWorkspaceSetupMessage', false);
      },


      goTo: function goTo(route, context) {
        this.transitionToRoute(route, context);
      }

    }
  });
});