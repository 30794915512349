define('candidio-app/adapters/template', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/templates{/id}{?query*}',

    findRecordUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/templates/{id}{?include}';
      }

      return '{+host}/{+namespace}/templates/{id}{?include}';
    }),

    queryUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/templates{?query*}';
      }

      return '{+host}/{+namespace}/templates{?query*}';
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      include: function include(type, id, snapshot) {
        var include = void 0;
        if (snapshot) {
          include = Ember.get(snapshot, 'adapterOptions.query.include');
        }
        if (include) {
          return include;
        }
      }
    }
  });
});