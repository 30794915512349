define('candidio-app/controllers/workspace/productions/production/pre-production', ['exports', 'candidio-app/mixins/save-brief-task', 'candidio-app/mixins/save-description-task', 'candidio-app/mixins/description-sorting'], function (exports, _saveBriefTask, _saveDescriptionTask, _descriptionSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var dateFormat = 'MMMM D, YYYY';

  exports.default = Ember.Controller.extend(_descriptionSorting.default, _saveBriefTask.default, _saveDescriptionTask.default, {

    shotSorting: ['position'],
    sortedShots: Ember.computed.sort('model.descriptions', 'shotSorting'),

    goalDate: Ember.computed('model.goalDate', function () {
      var modelGoalDate = moment(this.get('model.goalDate'));
      if (!modelGoalDate.isValid()) {
        return null;
      }
      return modelGoalDate.format(dateFormat);
    }),

    actions: {
      updateVideoLength: function updateVideoLength(lengthAdjustment, lengthReason) {
        var brief = this.get('model.brief');
        brief.set('meta.lengthAdjustment', lengthAdjustment);
        brief.set('meta.lengthReason', lengthReason);
        return this.get('saveBrief').perform(brief);
      }
    }
  });
});