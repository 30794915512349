define('candidio-app/routes/invalid', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        redirect: function redirect() {
            this.messages.showError('Invalid URL specified.');
            this.transitionTo('workspaces');
        },
        model: function model() {
            return null;
        }
    });
});