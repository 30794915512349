define('candidio-app/models/deliverable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    isAccepted: attr('boolean'),
    isDelivered: attr('boolean'),
    notesForProducer: attr('string'),
    rating: attr('string'),
    createdAt: attr('moment-date'),
    updatedAt: attr('moment-date'),

    assets: hasMany('asset', { async: false }),
    createdBy: belongsTo('user', { async: false }),
    production: belongsTo('production', { async: false }),
    revision: belongsTo('revision', { async: false })
  });
});