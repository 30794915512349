define('candidio-app/components/password-input-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c__password-input'],
    inputId: null,
    value: "",
    placeholder: "Password",

    isPasswordVisible: Ember.computed.equal('inputType', 'input'),

    inputType: 'password',

    actions: {
      togglePasswordVisibility: function togglePasswordVisibility() {
        if (this.get('inputType') === 'password') {
          return this.set('inputType', 'input');
        }
        return this.set('inputType', 'password');
      }
    }
  });
});