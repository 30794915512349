define('candidio-app/controllers/admin/templates/industries/industry', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    name: Ember.computed.alias('model.name'),
    description: Ember.computed.alias('model.description'),
    isPublished: Ember.computed.alias('model.isPublished'),
    onEdit: true,

    actions: {
      saveIndustry: function saveIndustry(callback) {
        var _this = this;

        var promise = this.get('model').save();
        callback(promise);
        return promise.then(function () {
          return _this.messages.showSuccess('Saved!');
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });
      },
      deleteIndustry: function deleteIndustry(callback) {
        var _this2 = this;

        if (confirm("Are you absolutely sure you want to delete this industry?")) {
          var promise = this.get('model').destroyRecord().then(function () {
            _this2.messages.showSuccess('Industry has been deleted');
            _this2.transitionToRoute('admin.templates.industries');
          });
          callback(promise);
          return callback;
        }
        return callback(Ember.RSVP.reject());
      }
    }

  });
});