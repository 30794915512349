define('candidio-app/routes/workspace/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      return this.transitionTo('workspace.video-series', this.modelFor('workspace'));
    }
  });
});