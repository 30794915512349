define('candidio-app/validators/invite', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var InviteValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('firstName', 'lastName', 'email', 'password'),
                validationErrors = [];

            if (!validator.isLength(data.firstName, 1, 150)) {
                validationErrors.push({
                    message: "Didn't your mama name you? Enter your first name."
                });
            }

            if (!validator.isLength(data.lastName, 1, 150)) {
                validationErrors.push({
                    message: "Only Madonna is allowed one name. Enter your last name."
                });
            }

            if (!validator.isEmail(data.email)) {
                validationErrors.push({
                    message: 'Something is wacky with that email address.'
                });
            }

            if (!validator.isLength(data.password, 8)) {
                validationErrors.push({
                    message: 'Hey, make sure you enter at least 8 characters for your password.'
                });
            }

            return validationErrors;
        }
    });

    exports.default = InviteValidator;
});