define('candidio-app/components/template-form-component', ['exports', 'candidio-app/mixins/description-sorting'], function (exports, _descriptionSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_descriptionSorting.default, {

    scottyUploader: Ember.inject.service(),

    hasWorkspace: false,

    actions: {
      addShot: function addShot() {
        this.get('model.template.descriptions').addObject(Ember.Object.create({
          type: 'broll',
          fields: {
            field1: '',
            field2: '',
            meta: []
          }
        }));
      },
      movedShot: function movedShot(shot, oldIndex, newIndex) {
        var shots = this.get('model.template.descriptions');
        shots.removeAt(oldIndex);
        shots.insertAt(newIndex, shot);
      },
      removeShot: function removeShot(shot) {
        this.get('model.template.descriptions').removeObject(shot);
      },
      uploadFiles: function uploadFiles(files) {
        var _this = this;

        _.forEach(files, function (file) {
          return _this.uploadFile(file);
        });
      },
      removeImage: function removeImage() {
        this.set('model.imageUrl', null);
      },
      saveTemplate: function saveTemplate(callback) {
        this.sendAction('saveTemplate', callback, this.get('model')); // eslint-disable-line
      },
      deleteTemplate: function deleteTemplate() {
        this.sendAction('deleteTemplate', this.get('model')); // eslint-disable-line
      }
    },

    uploadFile: function uploadFile(file) {
      var _this2 = this;

      var path = 'template-images';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, {}, {
        onComplete: function onComplete(res) {
          return _this2.completeFileUpload(res);
        }
      });
    },
    completeFileUpload: function completeFileUpload(res) {
      this.set('model.imageUrl', res.file_path);
    }
  });
});