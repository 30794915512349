define("candidio-app/utils/format-response-for-push", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = formatResponseForPush;
  function formatResponseForPush(type, rawPayload) {
    var payload = _.assign({}, rawPayload);
    payload[type] = payload.data;
    delete payload.data;
    return payload;
  }
});