define('candidio-app/models/asset', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    bucket: _emberData.default.attr('string'),
    objectKey: _emberData.default.attr('string'),
    fileName: _emberData.default.attr('string'),
    fileType: _emberData.default.attr('string'),
    size: _emberData.default.attr('number'),
    videoDuration: _emberData.default.attr('number'),
    isStock: _emberData.default.attr('boolean'),
    isTranscoded: _emberData.default.attr('boolean'),
    linkDownload: _emberData.default.attr('string'),
    linkThumbnail: _emberData.default.attr('string'),
    linkFullsize: _emberData.default.attr('string'),
    linkPreviewMp4: _emberData.default.attr('string'),
    linkPreviewWebm: _emberData.default.attr('string'),
    isCompletedProduction: _emberData.default.attr('boolean'),
    mimetype: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('moment-date'),
    updatedAt: _emberData.default.attr('moment-date'),

    deliverable: _emberData.default.belongsTo('deliverable', { async: false }),
    description: _emberData.default.belongsTo('description', { async: false }),
    production: _emberData.default.belongsTo('production', { async: false }),
    revision: _emberData.default.belongsTo('revision', { async: false }),
    videoSeries: _emberData.default.belongsTo('videoSeries', { async: false, inverse: 'assets' }),

    workspace: _emberData.default.belongsTo('workspace', { async: false }),

    fullFileName: Ember.computed('fileName', 'fileType', function () {
      return this.get('fileName') + '.' + this.get('fileType');
    }),

    formattedSize: Ember.computed('size', function () {
      if (Ember.isPresent(this.get('size'))) {
        var i = -1,
            fileSizeInBytes = this.get('size'),
            byteUnits = ['kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
      }
      return null;
    }),

    linkTorrent: Ember.computed('linkDownload', function () {
      var splitLink = this.get('linkDownload').split('?');
      return splitLink[0] + '?torrent';
    }),

    fileTypeLowerCase: Ember.computed('fileType', function () {
      return Ember.isPresent(this.get('fileType')) ? this.get('fileType').toLowerCase() : null;
    }),
    isVideo: Ember.computed.match('mimetype', /^video\//),
    isImage: Ember.computed.match('mimetype', /^image\//),
    isAudio: Ember.computed.match('mimetype', /^audio\//),
    isOther: Ember.computed('isVideo', 'isImage', 'isAudio', function () {
      return !_.some([this.get('isVideo'), this.get('isImage'), this.get('isAudio')]);
    }),
    isPlayable: Ember.computed.or('isVideo', 'isAudio'),

    hasPreview: Ember.computed.or('linkPreviewMp4', 'linkPreviewWebm', 'linkFullsize'),
    hasThumbnail: Ember.computed.notEmpty('linkThumbnail'),
    hasDescription: Ember.computed.notEmpty('description'),

    assetType: Ember.computed('isVideo', 'isImage', 'isAudio', function () {
      return this.get('isVideo') ? 'video' : this.get('isImage') ? 'image' : this.get('isAudio') ? 'audio' : this.get('isSlide') ? 'display' : 'file';
    }),

    cssLinkThumbnail: Ember.computed('linkThumbnail', function () {
      return Ember.isEmpty(this.get('linkThumbnail')) ? null : 'background-image: url(' + this.get("linkThumbnail") + ')';
    })
  });
});