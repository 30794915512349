define('candidio-app/controllers/admin/index', ['exports', 'candidio-app/mixins/pagination-controller'], function (exports, _paginationController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginationController.default, {

    queryParams: ['sort', 'direction'],
    sort: 'created_at',
    direction: 'desc',

    loadMoreFunction: function loadMoreFunction(paginationSettings) {
      return this.store.query('action', paginationSettings);
    }

  });
});