define('candidio-app/components/scotty-uploader-box', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    contextModel: null,
    contextLocation: null,
    isHovering: false,

    mouseEnter: function mouseEnter() {
      if (!isMobile.any) {
        this.set('isHovering', true);
      }
    },
    mouseLeave: function mouseLeave() {
      if (!isMobile.any) {
        this.set('isHovering', false);
      }
    },


    actions: {
      upload: function upload(files) {
        this.get('upload')(files, this.get('uploadContext'));
      }
    }

  });
});