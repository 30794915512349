define('candidio-app/components/focus-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    attributeBindings: ["select"],
    classNames: ['focus-input'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        _this.$().focus();
        var select = _this.get("select");
        if (select !== "false") {
          _this.$().select();
        }
      });
    },
    click: function click() {
      var select = this.get("select");
      if (select !== "false") {
        this.$().select();
      }
    }
  });
});