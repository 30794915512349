define('candidio-app/controllers/admin/workspaces/workspace/video-series/video-series', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    createProductionsCount: 1,
    productionTemplate: Ember.computed.reads('model.template')
  });
});