define('candidio-app/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    contentType: 'application/json',
    trustedHosts: [/\.candidio\./, /candidio-api\./],
    headers: Ember.computed('session.isAuthenticated', {
      get: function get() {
        var headers = {};
        this.get('session').authorize('authorizer:application', function (headerName, headerValue) {
          return headers[headerName] = headerValue;
        });
        return headers;
      }
    })
  });
});