define('candidio-app/controllers/admin/users/user', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    user: Ember.computed.alias('model'),

    actions: {
      save: function save(value) {
        var _this = this;

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('users', this.get('model.id')), {
          type: 'PUT',
          data: JSON.stringify({
            data: {
              is_beta: value
            }
          })
        }).then(function (resp) {
          return _this.pushUserPayload(resp);
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });
      },


      toggleAdmin: function toggleAdmin(callback) {
        var _this2 = this;

        var newIsAdmin = !this.get('model.isAdmin');
        this.set('model.isAdmin', newIsAdmin);

        return callback(this.get('model').save().then(function () {
          return _this2.messages.showSuccess(_this2.get('model.firstName') + ' is ' + (newIsAdmin ? '' : 'not ') + 'an admin');
        }).catch(function (errors) {
          return _this2.messages.showAPIError(errors);
        }));
      },

      resetPassword: function resetPassword(callback) {
        var _this3 = this;

        var email = this.get('user.email');
        var promise = this.get('ajax').request((0, _candidioPaths.default)().url.api('users', 'forgot-password'), {
          type: 'POST',
          data: JSON.stringify({
            data: {
              email: email
            }
          })
        });
        callback(promise);

        return promise.then(function () {
          return _this3.messages.showSuccess('Password reset email has been sent to ' + email + '.');
        }).catch(function (errors) {
          return _this3.messages.showAPIError(errors);
        });
      },


      deleteUser: function deleteUser(callback) {
        var self = this;
        var promise = this.get('ajax').request((0, _candidioPaths.default)().url.api('users', self.get('model.id')), {
          type: 'DELETE'
        });
        callback(promise);
        if (confirm('Are you absolutely sure you want to obliterate this user?')) {
          return promise.then(function () {
            self.get('model').deleteRecord();
            self.messages.showInfo('User has been deleted');
            self.transitionToRoute('admin.users');
          }).catch(function (errors) {
            self.messages.showAPIError(errors);
          });
        }
      }
    }

  });
});