define('candidio-app/adapters/brief', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/{pathForType}{/id}{?query*}',

    createRecordUrlTemplate: '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/briefs',

    updateRecordUrlTemplate: '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/briefs/{briefId}',

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      productionId: function productionId() {
        return this.get('adapterContext.production.id');
      },
      briefId: function briefId() {
        return this.get('adapterContext.production.brief.id');
      }
    }

  });
});