define('candidio-app/initializers/detect-firefox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // Remove the base tag on Firefox, or your SVG icons may not work
  function initialize() {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      Ember.$('head base').remove();
    }
  }

  exports.default = {
    name: 'detect-firefox',
    initialize: initialize
  };
});