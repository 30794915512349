define('candidio-app/routes/workspace/video-series/video-series', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    adapterContext: Ember.inject.service(),
    ajax: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    tracking: Ember.inject.service(),

    titleToken: function titleToken(model) {
      return model.get('title');
    },
    model: function model(params) {
      var workspace = this.modelFor('workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.findRecord('video-series', params.video_series_id, {
        reload: true,
        adapterOptions: {
          query: {
            include: ['assets', 'created_by', 'example_video', 'collaborators', 'productions.template'].join(',')
          }
        }
      });
    },
    activate: function activate() {
      var workspace = this.modelFor('workspace');
      var videoSeries = this.modelFor(this.routeName);
      this.get('current').setCurrent({ workspace: workspace, videoSeries: videoSeries });
    },
    deactivate: function deactivate() {
      this.get('current').set('videoSeries', null);
    },


    actions: {
      inviteByEmail: function inviteByEmail(callback, email) {
        var _this = this;

        if (!validator.isEmail(email)) {
          this.messages.showError('Whoops! Something is wrong with the email address you entered.');
          return callback(Ember.RSVP.reject());
        }

        var promise = this.inviteByEmail(email).then(function (user) {
          return _this.shareWithUser(user);
        }).finally(function () {
          return _this.controller.set('email', '');
        });

        callback(promise);
        return promise;
      },


      shareWithUser: function shareWithUser(callback, user) {
        var promise = this.shareWithUser(user);
        callback(promise);
        return promise;
      },

      unshareWithUser: function unshareWithUser(callback, user) {
        var promise = this.unshareWithUser(user);
        callback(promise);
        return promise;
      },

      uploadBrandFiles: function uploadBrandFiles(files, model) {
        var _this2 = this;

        _.forEach(files, function (file) {
          return _this2.uploadBrandFile(file, model);
        });
      },
      deleteBrandFile: function deleteBrandFile(asset) {
        var workspace = this.modelFor('workspace');
        this.get('adapterContext').setContext({ workspace: workspace });
        return asset.destroyRecord();
      }
    },

    uploadBrandFile: function uploadBrandFile(file, model) {
      var _this3 = this;

      var workspace = this.modelFor('workspace');
      var path = workspace.get('id') + '/' + model.get('id') + '/assets';
      return this.get('scottyUploader').uploadFile(file, path, { model: model }, {
        onComplete: function onComplete(res) {
          return _this3.completeBrandFileUpload(res, model);
        },
        onError: function onError() {
          return _this3.messages.showError('Error uploading files.');
        }
      });
    },
    completeBrandFileUpload: function completeBrandFileUpload(payload, model) {
      var workspace = this.modelFor('workspace');

      var brandFile = this.store.createRecord('asset', {
        fileName: payload.file_name,
        fileType: payload.file_type,
        size: payload.size,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype,
        bucket: payload.bucket,
        objectKey: payload.object_key,
        videoSeries: model,
        workspace: workspace
      });

      // model.get('brandFiles').addObject(brandFile);
      this.get('adapterContext').setContext({ workspace: workspace });

      brandFile.save();
    },
    inviteByEmail: function inviteByEmail(email) {
      var _this4 = this;

      var workspace = this.modelFor('workspace');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', 'invite'), {
        type: 'POST',
        data: JSON.stringify({
          data: {
            email: email,
            can_manage: false
          }
        })
      }).then(function (res) {
        // Track the event
        _this4.get('tracking').trackEvent({
          event: 'Invited User',
          category: 'workspace',
          action: 'invited-user',
          invitedDate: moment(),
          inviteeEmail: email
        });

        var user = _this4.store.push(_this4.store.normalize('user', res.data));

        // Add user to the workspace
        workspace.get('users').pushObject(user);

        return user;
      }).catch(function (errors) {
        return _this4.messages.showAPIError(errors);
      });
    },
    shareWithUser: function shareWithUser(user) {
      var _this5 = this;

      var workspace = this.modelFor('workspace');
      var vs = this.controller.get('model');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', user.get('id'), 'video-series', vs.get('id'), 'attach'), {
        type: 'PUT',
        data: JSON.stringify({
          data: {
            workspace_id: workspace.get('id')
          }
        })
      }).then(function () {
        _this5.get('tracking').trackEvent({
          event: 'Shared Video Series',
          category: 'video-series',
          action: 'shared',
          sharedWith: user.get('fullName')
        });
        vs.get('collaborators').pushObject(user);
      }).catch(function (errors) {
        return _this5.messages.showAPIError(errors);
      });
    },
    unshareWithUser: function unshareWithUser(user) {
      var _this6 = this;

      var workspace = this.modelFor('workspace');
      var vs = this.controller.get('model');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', user.get('id'), 'video-series', vs.get('id'), 'detach'), {
        type: 'DELETE'
      }).then(function () {
        _this6.get('tracking').trackEvent({
          event: 'Unshared Video Series',
          category: 'video-series',
          action: 'unshared',
          sharedWith: user.get('fullName')
        });
        vs.get('collaborators').removeObject(user);
      }).catch(function (errors) {
        return _this6.messages.showAPIError(errors);
      });
    }
  });
});