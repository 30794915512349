define('candidio-app/controllers/workspace/productions/production/post-production', ['exports', 'candidio-app/utils/addweekdays', 'candidio-app/mixins/save-brief-task'], function (exports, _addweekdays, _saveBriefTask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_saveBriefTask.default, {
    /**
     * Editing production
     *   - no production deliverable
     *
     * Reviewing production deliverable
     *   - has production deliverable
     *   - no revisions
     *
     * Revision requested
     *   - has open revision
     *   - TODO: handle this in the route by redirecting to
     *     workspace.productions.production.revision
     *
     * Editing revision
     *   - most recent revision is closed
     *   - most recent revision has no deliverable
     *
     * Reviewing revision deliverable
     *   - most recent revision is closed
     *   - most recent revision has a deliverable
     *
     * Deliverable accepted
     *   - production in distribution
     */

    pellOptions: {
      actions: ['bold', 'italic', 'underline', 'strikethrough', 'heading1', 'heading2', 'paragraph', 'quote', 'olist', 'ulist', 'line', 'link']
    },

    deliveredDeliverables: Ember.computed.filterBy('model.deliverables', 'isDelivered', true),
    deliveredDeliverablesByMostRecent: Ember.computed.sort('deliveredDeliverables', function (a, b) {
      return a.get('createdAt').isBefore(b.get('createdAt')) ? 1 : -1;
    }),
    isProductionDelivered: Ember.computed.bool('deliveredDeliverables.length'),
    isProductionUndelivered: Ember.computed.not('isProductionDelivered'),

    hasSubmittedRevision: Ember.computed.bool('model.latestRevision'),
    hasNoSubmittedRevision: Ember.computed.not('hasSubmittedRevision'),

    deliveredRevisionDeliverables: Ember.computed.filterBy('model.latestRevision.deliverables', 'isDelivered', true),
    deliveredRevisionDeliverablesByMostRecent: Ember.computed.sort('deliveredRevisionDeliverables', function (a, b) {
      return a.get('createdAt').isBefore(b.get('createdAt')) ? 1 : -1;
    }),
    isRevisionDelivered: Ember.computed.bool('deliveredRevisionDeliverables.length'),
    isRevisionUndelivered: Ember.computed.not('isRevisionDelivered'),

    acceptedDeliverables: Ember.computed.filterBy('deliveredDeliverables', 'isAccepted', true),
    unacceptedDeliverables: Ember.computed.filterBy('deliveredDeliverables', 'isAccepted', false),
    hasAcceptedDeliverable: Ember.computed.bool('acceptedDeliverables.length'),
    hasNoAcceptedDeliverable: Ember.computed.not('hasAcceptedDeliverable'),
    previousUnacceptedDeliverables: Ember.computed('deliveredDeliverablesByMostRecent', function () {
      return this.get('deliveredDeliverablesByMostRecent').slice(1);
    }),
    hasPreviousUnacceptedDeliverables: Ember.computed.bool('previousUnacceptedDeliverables.length'),

    finalVideo: Ember.computed.reads('acceptedDeliverables.firstObject.assets.firstObject'),

    isEditingProduction: Ember.computed.and('model.isSubmitted', 'isProductionUndelivered', 'hasNoSubmittedRevision', 'hasNoAcceptedDeliverable'),
    isReviewingProduction: Ember.computed.and('isProductionDelivered', 'hasNoSubmittedRevision', 'hasNoAcceptedDeliverable'),
    isEditingRevision: Ember.computed.and('hasSubmittedRevision', 'isRevisionUndelivered', 'hasNoAcceptedDeliverable'),
    isReviewingRevision: Ember.computed.and('hasSubmittedRevision', 'isRevisionDelivered', 'hasNoAcceptedDeliverable'),
    isDeliverableAccepted: Ember.computed.reads('hasAcceptedDeliverable'),

    editedDate: Ember.computed('model.firstSubmittedAt', function () {
      // TODO: Update model.latestRevision.updatedAt to model.latestRevision.submittedAt, when available
      var submittedDate = this.get('isEditingRevision') ? this.get('model.latestRevision.updatedAt') : this.get('model.firstSubmittedAt') || moment();
      var turnaround = this.get('model.videoSeries.turnaround') || 5;
      return (0, _addweekdays.default)(submittedDate, turnaround);
    })
  });
});