define('candidio-app/mixins/pusher-bindings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    pusherSubscriptions: {},

    init: function init() {
      var target;
      this._super();
      if (!this.pusherSubscriptions) {
        return;
      }
      target = this;
      Object.keys(target.pusherSubscriptions).forEach(function (channelName) {
        var events = target.pusherSubscriptions[channelName];
        target.pusher.wire(target, channelName, events);
      });
    },

    willDestroy: function willDestroy() {
      var target;
      if (!this.pusherSubscriptions) {
        return;
      }
      target = this;
      Object.keys(target.pusherSubscriptions).forEach(function (channelName) {
        target.pusher.unwire(target, channelName);
      });
      this._super();
    },

    _pusherEventsId: function _pusherEventsId() {
      return this.toString();
    }

  });
});