define("candidio-app/utils/production-defaults", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = defaults;
  function defaults() {
    return {
      length: '3.0',
      tone: {
        action: false,
        brief: false,
        clean: false,
        clever: false,
        cool: false,
        emotional: false,
        fancy: false,
        fast: false,
        fresh: false,
        fun: false,
        funny: false,
        happy: false,
        inspiring: false,
        inviting: false,
        long: false,
        mysterious: false,
        powerful: false,
        serious: false,
        slow: false,
        thoughtProvoking: false
      },
      videoTypes: [{ label: "Testimonial", value: "testimonial" }, { label: "Product Promo", value: "productPromo" }, { label: "Tip/Tutorial", value: "tipTutorial" }, { label: "Behind the Scenes", value: "behindTheScenes" }, { label: "Other", value: "other" }],
      musicTypes: ['Acoustic', 'Blue grass', 'Corporate', 'Dance', 'Dramatic', 'Epic', 'Fun', 'Funk', 'Hard rock', 'Holiday', 'Inspirational', 'Jazzy', 'Piano', 'Pop', 'Powerful', 'Relaxing', 'Sad', 'Soft rock', 'Urban', 'Upbeat']
    };
  }
});