define('candidio-app/routes/workspace/productions/production/post-production', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    adapterContext: Ember.inject.service(),
    tracking: Ember.inject.service(),

    model: function model() {
      var production = this.modelFor('workspace.productions.production');

      if (Ember.isPresent(production.get('revisions').filterBy('isOpen', true))) {
        return this.transitionTo('workspace.productions.production.revision');
      }

      return production;
    },


    actions: {
      acceptDeliverable: function acceptDeliverable(callback, deliverable) {
        var _this = this;

        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');
        deliverable.set('isAccepted', true);
        this.get('adapterContext').setContext({ workspace: workspace });
        return callback(deliverable.save().then(function () {
          _this.trackAcceptedDeliverable(production);
          _this.transitionTo('workspace.productions.production.distribution');
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        }));
      },
      requestRevision: function requestRevision(callback) {
        var _this2 = this;

        var production = this.modelFor('workspace.productions.production');

        return callback(this._createRevision().then(function () {
          _this2.trackRequestedRevision(production);
          _this2.transitionTo('workspace.productions.production.revision');
        }));
      },
      submitForEditing: function submitForEditing(callback) {
        var _this3 = this;

        var workspace = this.modelFor('workspace');
        var production = this.modelFor('workspace.productions.production');

        production.set('isSubmitted', true);

        this.get('adapterContext').setContext({ workspace: workspace });
        return callback(production.save().then(function (prod) {
          return _this3.trackSubmitProduction(prod);
        }).catch(function (errors) {
          return _this3.messages.showAPIError(errors);
        }));
      }
    },

    _createRevision: function _createRevision() {
      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');
      var revision = this.store.createRecord('revision', {});

      this.get('adapterContext').setContext({ workspace: workspace, production: production });
      return revision.save().then(function (revision) {
        production.get('revisions').addObject(revision);
        return revision;
      });
    },
    generateProductionTrackingObject: function generateProductionTrackingObject(production) {
      return {
        value: production.get('title'),
        url: '' + window.location.origin + this.router.generate('admin.productions.production', production)
      };
    },
    trackAcceptedDeliverable: function trackAcceptedDeliverable(production) {
      this.get('tracking').trackEvent({
        event: 'Accepted Deliverable',
        category: 'deliverable',
        action: 'accepted',
        production: this.generateProductionTrackingObject(production)
      });
    },
    trackRequestedRevision: function trackRequestedRevision(production) {
      this.get('tracking').trackEvent({
        event: 'Requested Revision',
        category: 'production',
        action: 'requested-revision',
        production: this.generateProductionTrackingObject(production)
      });
    },
    trackSubmitProduction: function trackSubmitProduction(production) {
      this.get('tracking').trackEvent({
        event: 'Submitted Production',
        category: 'production',
        action: 'submitted',
        production: this.generateProductionTrackingObject(production)
      });
    }
  });
});