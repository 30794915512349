define('candidio-app/mixins/draggable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['draggable-item'],
    classNameBindings: ['isDragging'],
    attributeBindings: ['draggable'],
    draggable: 'true',
    isDragging: false,

    dragStart: function dragStart(event) {
      this.set('isDragging', true);
      event.dataTransfer.setData('text/data', this.get('content'));
    },

    drop: function drop() {
      this.set('isDragging', false);
    }
  });
});