define('candidio-app/controllers/admin/productions/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    queryParams: ['limit', 'page', 'sort', 'direction'],
    limit: 10,
    page: 1,
    sort: 'created_at',
    direction: 'desc',
    metaData: Ember.computed.alias('model.meta.pagination'),

    actions: {

      prevPage: function prevPage() {
        this.decrementProperty('page');
      },

      nextPage: function nextPage() {
        this.incrementProperty('page');
      }

    }
  });
});