define('candidio-app/routes/confirm-email', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      // Validate token
      if (!validator.isLength(params.token, 32, 32)) {
        this.messages.showError('Something went wrong. Please contact us at support@candidio.com.');
        this.transitionTo('login');
      }

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('users', 'confirm-email'), {
        type: 'POST',
        data: JSON.stringify({
          data: { 'token': params.token }
        })
      }).then(function (response) {
        if (_this.get('session').get('isAuthenticated')) {
          _this.messages.showSuccess('Your new email address is now confirmed.');
          _this.get('current.user').set('email', response.data.email);
          return _this.transitionTo('user.profile');
        }

        _this.messages.showInfo('Your can now login with your new email address: ' + response.data.email);
        return _this.transitionTo('login');
      }).catch(function (errors) {
        if (errors.http_code === 404) {
          _this.messages.showError('It looks like email confirmation token has been used.');
        } else {
          _this.messages.showAPIError(errors);
        }
        return _this.transitionTo('login');
      });
    }
  });
});