define("candidio-app/components/new-modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog"], function (exports, _modalDialog) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialog.default.extend({
    wrapperClassNames: "modal-background",
    containerClassNames: "modal",
    overlayClassNames: "modal-container show"
  });
});