define('candidio-app/controllers/admin/workspaces/workspace/index', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    tracking: Ember.inject.service(),

    savingWorkspace: false,

    actions: {
      saveFeatures: function saveFeatures(callback) {
        var _this = this;

        var promise = this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('model.id')), {
          type: 'PUT',
          data: JSON.stringify({
            data: {
              features: {
                projects: this.get('projects') !== this.get('defaultFeatures.projects') ? this.get('projects') : undefined,
                users: this.get('usersCount') !== this.get('defaultFeatures.users') ? parseInt(this.get('usersCount'), 10) : undefined,
                stockLibrary: this.get('stockLibrary') !== this.get('defaultFeatures.stockLibrary') ? this.get('stockLibrary') : undefined
              }
            }
          })
        }).then(function (res) {
          _this.pushWorkspacePayload(res);
          _this.messages.showSuccess('Workspace features updated!');
        }).catch(function (errors) {
          return _this.messages.showAPIError(errors);
        });

        callback(promise);

        return promise;
      },
      clearWorkspaceFeatures: function clearWorkspaceFeatures() {
        var _this2 = this;

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('model.id')), {
          type: 'PUT',
          data: JSON.stringify({ data: { features: {} } })
        }).then(function (res) {
          _this2.pushWorkspacePayload(res);
          _this2.messages.showSuccess('Workspace custom features have been reset to the current plan defaults.');
        }).catch(function (errors) {
          return _this2.messages.showAPIError(errors);
        });
      },


      deleteWorkspace: function deleteWorkspace() {
        if (confirm('Are you absolutely sure you want to blow away this workspace?')) {
          this.handleDelete();
        }
      }
    },

    pushWorkspacePayload: function pushWorkspacePayload(payload) {
      payload['workspace'] = payload.data;
      delete payload.data;
      this.store.pushPayload(payload);
    },

    handleDelete: function handleDelete() {
      var self = this,
          workspace = this.get('model');

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id')), {
        type: 'DELETE'
      }).then(function () {
        workspace.deleteRecord();
        self.messages.showInfo('The workspace has been deleted. BAM!');
        self.transitionToRoute('admin.workspaces');
      }).catch(function (errors) {
        self.messages.showAPIError(errors);
      });
    }

  });
});