define('candidio-app/models/production-snapshot', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    title: attr('string'),
    state: attr('string'),
    statusQueued: attr('boolean'),
    allowPublicUpload: attr('boolean'),
    allowPublicShotlist: attr('boolean'),
    goalDate: attr('moment-date'),
    createdAt: attr('moment-date'),
    firstSubmittedAt: attr('moment-date'),
    lastSubmittedAt: attr('moment-date'),

    brief: belongsTo('brief', { async: false }),
    assets: hasMany('asset', { async: false }),
    descriptions: hasMany('description', { async: false })
  });
});