define('candidio-app/models/user', ['exports', 'ember-data', 'model-fragments/attributes', 'candidio-app/models/actionable', 'candidio-app/utils/candidio-paths'], function (exports, _emberData, _attributes, _actionable, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _actionable.default.extend({

    ajax: Ember.inject.service(),
    current: Ember.inject.service(),

    avatar: _emberData.default.attr(),
    userHash: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    preferences: _emberData.default.attr(),
    permissions: (0, _attributes.fragmentArray)('permission'),
    isConfirmed: _emberData.default.attr('boolean'),
    isInvite: _emberData.default.attr('boolean', { defaultValue: false }),
    isBeta: _emberData.default.attr('boolean', { defaultValue: false }),
    /* TODO: This should be set to false in production */
    isAdmin: _emberData.default.attr('boolean', { defaultValue: true }),
    createdAt: _emberData.default.attr('moment-date'),
    updatedAt: _emberData.default.attr('moment-date'),

    // Relationships
    workspaces: _emberData.default.hasMany('workspace', { async: false }),

    // Computed Properties
    fullName: Ember.computed('firstName', 'lastName', function () {
      if (Ember.isEmpty(this.get('firstName'))) {
        return this.get('email');
      }
      return this.get('firstName') + ' ' + this.get('lastName');
    }),

    canManageCurrentWorkspace: Ember.computed('current.workspace', {
      get: function get() {
        var workspace = this.get('current.workspace');
        var user = this;

        if (user.get('isAdmin')) {
          return true;
        }

        if (!user.get('permissions.length') || Ember.isEmpty(workspace)) {
          return false;
        }

        return user.get('permissions').filterBy('workspaceId', workspace.get('id')).get('firstObject.canManage');
      },
      set: function set(key, value) {
        var workspace = this.get('current.workspace');
        var user = this;

        user.get('permissions').filterBy('workspaceId', workspace.get('id')).set('firstObject.canManage', value);
        return value;
      }
    }),

    canUseBetaFeatures: Ember.computed.or('isBeta'),

    isPasswordValid: Ember.computed.empty('passwordValidationErrors.[]'),

    saveNewPassword: function saveNewPassword() {
      return this.get('ajax').request((0, _candidioPaths.default)().url.api('users', this.get('id')), {
        type: 'PUT',
        data: JSON.stringify({
          data: {
            password: this.get('oldPassword'),
            new_password: this.get('newPassword')
          }
        })
      });
    },

    passwordValidationErrors: Ember.computed('oldPassword', 'newPassword', function () {
      var validationErrors = [];

      if (validator.isLength(this.get('oldPassword'), 0, 7)) {
        validationErrors.push({ message: 'Your old password should be 8 characters in length or more.' });
      }

      if (!validator.isLength(this.get('newPassword'), 8)) {
        validationErrors.push({ message: 'Your password is not long enough. It must be at least 8 characters long.' });
      }

      return validationErrors;
    }),

    savePermissions: function savePermissions() {
      var permissions = {
        workspace_id: this.get('current.workspace.id'),
        can_manage: this.get('canManageCurrentWorkspace')
      };

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('users', this.get('id')), {
        type: 'PUT',
        data: JSON.stringify({ data: { permissions: permissions } })
      });
    }
  });
});