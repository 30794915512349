define('candidio-app/adapters/template-type', ['exports', 'ember-inflector', 'candidio-app/adapters/application'], function (exports, _emberInflector, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return (0, _emberInflector.pluralize)(dasherized);
    }
  });
});