define('candidio-app/controllers/workspace/productions/production/distribution', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tracking: Ember.inject.service(),

    acceptedDeliverables: Ember.computed.filterBy('model.deliverables', 'isAccepted', true),
    finalVideo: Ember.computed.reads('acceptedDeliverables.firstObject.assets.firstObject')
  });
});