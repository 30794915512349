define('candidio-app/initializers/pusher', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  // import PusherService from 'candidio-app/services/pusher';

  function initialize(application) {
    application.inject('controller', 'pusher', 'service:pusher');
    application.inject('route', 'pusher', 'service:pusher');

    application.inject('service:pusher', 'session', 'service:session');
    application.inject('service:pusher', 'current', 'service:current');

    if (_.contains(['staging', 'development'], _environment.default.environment)) {
      Pusher.log = function (message) {
        if (window.console && window.console.log) {
          window.console.log(message);
        }
      };
    }
  }

  exports.default = {
    name: 'pusher',
    initialize: initialize
  };
});