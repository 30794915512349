define('candidio-app/adapters/application', ['exports', 'ember-data', 'candidio-app/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {

    authorizer: 'authorizer:application',
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_NAMESPACE,

    /*
      The Ember Data 2.0 default is to return false when where is at least one
      record in the store. We're doing this to preserve the current behavior.
     */
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },


    /*
      The Ember Data 2.0 default is to return `true`.
      We're doing this to preserve the current behavior.
     */
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },


    // Override deleteRecord to disregard the response body on 2xx responses.
    // This is currently needed because the API is returning status 200 along
    // with the JSON object for the deleted entity and Ember expects an empty
    // response body for successful DELETEs.
    // Non-2xx (failure) responses will still work correctly as Ember will turn
    // them into rejected promises.
    deleteRecord: function deleteRecord() {
      var response = this._super.apply(this, arguments);

      return response.then(function () {
        return null;
      });
    }

  });
});