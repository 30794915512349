define('candidio-app/utils/object-transformations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.compact = compact;
  exports.without = without;
  exports.only = only;
  exports.underscoreKeys = underscoreKeys;
  exports.regularFormKeys = regularFormKeys;
  exports.momentValuesToISOString = momentValuesToISOString;
  exports.momentValuesToUnix = momentValuesToUnix;
  exports.limitToNProperties = limitToNProperties;
  function compact(objectInstance) {
    var compactedObject = {};

    for (var key in objectInstance) {
      var value = objectInstance[key];

      if (Ember.isPresent(value)) {
        compactedObject[key] = value;
      }
    }

    return compactedObject;
  }

  function without(originalObj, keysToRemove) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      if (keysToRemove.indexOf(key) === -1) {
        newObj[key] = originalObj[key];
      }
    });

    return newObj;
  }

  function only(originalObj, keysToRemain) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      if (keysToRemain.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    });

    return newObj;
  }

  function underscoreKeys(originalObj) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      newObj[key.underscore()] = originalObj[key];
    });

    return newObj;
  }

  function regularFormKeys(originalObj) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      var newKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
      });
      newObj[newKey] = originalObj[key];
    });

    return newObj;
  }

  function momentValuesToISOString(originalObj) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      if (moment.isMoment(originalObj[key])) {
        newObj[key] = moment(originalObj[key]).toISOString();
      } else {
        newObj[key] = originalObj[key];
      }
    });

    return newObj;
  }

  function momentValuesToUnix(originalObj) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      if (moment.isMoment(originalObj[key])) {
        newObj[key] = moment(originalObj[key]).utc().unix();
      } else {
        newObj[key] = originalObj[key];
      }
    });

    return newObj;
  }

  function limitToNProperties(originalObj, n) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);

    allKeys.forEach(function (key) {
      newObj[key] = originalObj[key];
      if (Object.keys(newObj).length === n) {
        return newObj;
      }
    });

    return newObj;
  }
});