define('candidio-app/components/candidio-modal-dialog', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CandidioModalDialogComponent = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$('.js-modal-container').addClass('show');
    },

    willDestroyElement: function willDestroyElement() {
      this.$('.js-modal-container').removeClass('show');
    },

    confirmaccept: 'confirmAccept',
    confirmreject: 'confirmReject',

    actions: {
      noBubble: function noBubble() {},
      closeModal: function closeModal() {
        this.sendAction(); // eslint-disable-line
      },
      confirm: function confirm(type) {
        this.sendAction('confirm' + type); // eslint-disable-line
        this.sendAction(); // eslint-disable-line
      }
    }

    // acceptButtonClass: Ember.computed('confirm.accept.buttonClass', function () {
    //     return this.get('confirm.accept.buttonClass') ? this.get('confirm.accept.buttonClass') : 'btn--primary btn--small';
    // }),
    //
    // rejectButtonClass: Ember.computed('confirm.reject.buttonClass', function () {
    //     return this.get('confirm.reject.buttonClass') ? this.get('confirm.reject.buttonClass') : 'btn--secondary btn--small';
    // })
  });

  exports.default = CandidioModalDialogComponent;
});