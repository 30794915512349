define('candidio-app/components/candidio-pagination-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.limitOpts = [10, 25, 50, 100];
    },


    /**
     * Needs metaData and limit
     */

    classNames: ['grid', 'pagination-controls'],

    actions: {

      prevPage: function prevPage() {
        this.sendAction('prevPage'); // eslint-disable-line
      },

      nextPage: function nextPage() {
        this.sendAction('nextPage'); // eslint-disable-line
      }

    }
  });
});