define('candidio-app/validators/login', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var LoginValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('identification', 'password'),
                validationErrors = [];

            if (!validator.isEmail(data.identification)) {
                validationErrors.push({
                    message: 'Invalid Email'
                });
            }

            if (!validator.isLength(data.password || '', 1)) {
                validationErrors.push({
                    message: 'Please enter a password'
                });
            }

            return validationErrors;
        }
    });

    exports.default = LoginValidator;
});