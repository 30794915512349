define('candidio-app/routes/invite', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    titleToken: "Create Account",

    model: function model(params) {
      var _this = this;

      if (!validator.isLength(params.token, 32, 64)) {
        this.transitionTo('login');
        this.messages.showError('Invalid invite token.');
      }
      this.set('token', params.token);
      return this.store.findRecord('invite', params.token).catch(function (errors) {
        if (errors.status === 404) {
          _this.messages.showError('Invalid invite token.');
          return _this.transitionTo('login');
        }
        _this.messages.showAPIError(errors);
        return _this.transitionTo('login');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.token = this.get('token');
    }
  });
});