define('candidio-app/adapters/video-series', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/video-series{/id}{?query*}',

    createRecordUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series';
      }
      return '{+host}/{+namespace}/video-series';
    }),

    findAllUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series{?include}';
      }
      return '{+host}/{+namespace}/video-series{?include}';
    }),

    findRecordUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series/{id}{?include}';
      }
      return '{+host}/{+namespace}/video-series/{id}{?include}';
    }),

    queryUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/video-series{?query*}';
      }
      return '{+host}/{+namespace}/video-series{?query*}';
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }
  });
});