define('candidio-app/models/industry', ['exports', 'ember-inflector', 'ember-data'], function (exports, _emberInflector, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector;
  inflector.irregular('industry', 'industries');

  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    isPublished: _emberData.default.attr('boolean'),

    // Relationships
    templates: _emberData.default.hasMany('template', { async: false }),
    workspaces: _emberData.default.hasMany('workspace', { async: false }),

    // Computed Properties
    firstTemplateImage: Ember.computed('templates.[]', function () {
      var imageUrl = this.get('templates.firstObject.imageUrl');
      if (Ember.isEmpty(imageUrl)) {
        return null;
      }
      return Ember.String.htmlSafe('background-image: url(' + imageUrl + ');');
    })
  });
});