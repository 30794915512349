define('candidio-app/components/radio-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var RadioButtonComponent = Ember.Component.extend({
    tagName: 'input',

    attributeBindings: ['type', 'checked'],

    // classNameBindings: ['active'],

    // active: Ember.computed.alias('checked'),

    type: 'radio',

    checked: Ember.ComputedProperty('value', 'name', function () {
      return this.get('value') === this.get('name');
    }),

    click: function click() {
      this.set('name', this.get('value'));
    }
  });

  exports.default = RadioButtonComponent;
});