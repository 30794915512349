define('candidio-app/models/action', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    action: attr('string'),
    createdAt: attr('moment-date'),
    updatedAt: attr('moment-date'),
    actionableDeletedAt: attr('moment-date'),

    // Relationships
    actionable: belongsTo('actionable', { async: false, polymorphic: true }),
    user: belongsTo('user', { async: false }),
    workspace: belongsTo('workspace', { async: false })
  });
});