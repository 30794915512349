define('candidio-app/components/search-result-context', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['search-result__context'],
    objectType: Ember.computed(function () {
      return Ember.get(this, 'result.type');
    }),
    showContext: Ember.computed('objectType', function () {
      return this.get('objectType') !== 'workspace';
    }),
    contextText: Ember.computed('objectType', function () {
      if (this.get('objectType') === 'production' || this.get('objectType') === 'project') {
        return Ember.get(this, 'result.workspace.workspace_name');
      } else if (this.get('objectType') === 'user') {
        return Ember.get(this, 'result.email');
      }
      return null;
    })
  });
});