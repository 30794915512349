define('candidio-app/initializers/event-tracking', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var _config$tracking = _environment.default.tracking,
        _config$tracking$adap = _config$tracking.adapters,
        adapters = _config$tracking$adap === undefined ? {} : _config$tracking$adap,
        _config$tracking$logg = _config$tracking.logging,
        logging = _config$tracking$logg === undefined ? false : _config$tracking$logg;
    var _config$environment = _environment.default.environment,
        environment = _config$environment === undefined ? 'development' : _config$environment;
    var enabled = _environment.default.tracking.enabled;

    var options = { adapters: adapters, environment: environment, enabled: enabled, logging: logging };

    application.register('config:tracking', options, { instantiate: false });
    application.inject('service:tracking', 'options', 'config:tracking');
    application.inject('service:tracking', 'current', 'service:current');
  } /* jshint ignore:start */
  exports.default = {
    name: 'event-tracking',
    initialize: initialize
  };
});