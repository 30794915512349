define('candidio-app/models/template-collection', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    hierarchy: _emberData.default.attr('number'),
    isPublished: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),

    // Relationships
    templates: _emberData.default.hasMany('template', { async: false })
  });
});