define('candidio-app/controllers/admin/templates/types/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    typeName: null,
    typeDescription: null,
    typeIcon: null,

    actions: {
      saveTemplateType: function saveTemplateType(callback) {
        var self = this,
            data = this.getProperties('typeName', 'typeDescription', 'typeIcon', 'typeIsPublished');

        var templateType = this.store.createRecord('templateType', {
          name: data.typeName,
          description: data.typeDescription,
          icon: data.typeIcon,
          isPublished: data.typeIsPublished
        });
        var promise = templateType.save();
        callback(promise);

        return promise.then(function () {
          self.transitionToRoute('admin.templates.types.index');
          self.setProperties({
            typeName: null,
            typeDescription: null,
            typeIcon: null,
            typeIsPublished: false
          });
        }).catch(function (errors) {
          self.messages.showAPIError(errors);
        });
      }
    }
  });
});