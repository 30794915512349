define('candidio-app/routes/admin/workspaces/workspace/video-series/video-series', ['exports', 'candidio-app/utils/production-defaults', 'candidio-app/mixins/dirty-confirm-route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _productionDefaults, _dirtyConfirmRoute, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _dirtyConfirmRoute.default, {
    adapterContext: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    model: function model(params) {
      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace });
      return this.store.findRecord('video-series', params.video_series_id, {
        reload: true,
        adapterOptions: {
          query: {
            include: ['assets', 'collaborators', 'created_by', 'example_video', 'productions', 'template', 'workspace'].join(',')
          }
        }
      });
    },
    afterModel: function afterModel() {
      var _this = this;

      this.get('adapterContext').resetContext();
      return this.store.query('template', { limit: 1000, include: 'workspace,industry' }).then(function (templates) {
        return _this.set('templates', templates);
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('templates', this.get('templates'));
    },
    activate: function activate() {
      var workspace = this.modelFor('admin.workspaces.workspace');
      var videoSeries = this.modelFor(this.routeName);
      this.get('current').setCurrent({ workspace: workspace, videoSeries: videoSeries });
    },

    actions: {
      save: function save(callback, model) {
        var _this2 = this;

        return callback(model.save().then(function () {
          return _this2.messages.showSuccess('Video series saved!');
        }));
      },
      uploadExample: function uploadExample(files, model) {
        var _this3 = this;

        var workspace = this.modelFor('admin.workspaces.workspace');
        var path = workspace.get('id') + '/' + model.get('id') + '/assets';
        return this.get('scottyUploader').uploadFile(files[0], path, { model: model }, {
          onComplete: function onComplete(res) {
            return _this3.completeExampleVideoUpload(res, model);
          },
          onError: function onError() {
            return _this3.messages.showError('Error uploading your file.');
          }
        });
      },
      createProductions: function createProductions(callback, num) {
        return callback(this._createProductions(num));
      }
    },

    completeExampleVideoUpload: function completeExampleVideoUpload(payload, model) {
      var workspace = this.modelFor('admin.workspaces.workspace');

      var exampleVideo = this.store.createRecord('asset', {
        fileName: payload.file_name,
        fileType: payload.file_type,
        size: payload.size,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype,
        bucket: payload.bucket,
        objectKey: payload.object_key
      });

      model.set('exampleVideo', exampleVideo);

      // TODO: Add videoSeries to context
      this.get('adapterContext').setContext({ workspace: workspace });

      return exampleVideo.save();
    },
    _createProductions: function _createProductions(n) {
      var _this4 = this;

      var workspace = this.modelFor('admin.workspaces.workspace');
      var videoSeries = this.get('currentModel');
      var promises = this._buildPromisesHash(n);

      this.get('adapterContext').setContext({ workspace: workspace, videoSeries: videoSeries });
      return Ember.RSVP.hash(promises).then(function () {
        return _this4.messages.showSuccess('Productions created!');
      }).finally(function () {
        return _this4.controller.set('productionTemplate', videoSeries.get('template'));
      });
    },


    /* jshint ignore:start */
    _buildPromisesHash: function _buildPromisesHash(n) {
      var _this5 = this;

      var promises = {};
      while (n > 0) {
        promises['promise' + n] = this.store.createRecord('production', {
          title: "",
          state: "Planning",
          template: this.controller.get('productionTemplate')
        }).save().then(function (production) {
          _this5.get('currentModel.productions').addObject(production);

          // We need to create an empty brief if we're creating the production
          // without a template, or else we might die.
          if (Ember.isEmpty(production.get('template'))) {
            return _this5.createEmptyBrief(production);
          }
        });

        n--;
      }
      return promises;
    },

    /* jshint ignore:end */

    createEmptyBrief: function createEmptyBrief(production) {
      var workspace = this.modelFor('admin.workspaces.workspace');
      this.get('adapterContext').setContext({ workspace: workspace, production: production });
      return this.store.createRecord('brief', {
        meta: {
          length: (0, _productionDefaults.default)().length,
          music: false,
          musicType: null,
          musicProvider: null
        }
      }).save();
    }
  });
});