define('candidio-app/components/file-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c__file-input'],
    attributeBindings: ['accept'],
    tagName: 'span',
    tabindex: 0,
    multiple: true,
    model: null,

    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      var deviceAgent = navigator.userAgent.toLowerCase();
      var iOS = /(ipad|iphone|ipod)/i.test(deviceAgent);

      /** Disable multiple file uploads on non-iOS 9 devices */
      if (iOS) {
        this.set('multiple', false);
      }
    },


    /**
     * Listens for change events on the native file input and dispatches
     * the corresponding action up the context chain.
     *
     * @private
     * @method
     * @param {$.Event} e Native change event
     */
    change: function change(e) {
      this.get('action')(e.target.files, this.get('model'));
    },


    randomId: Ember.computed(function () {
      return Math.random().toString(36).substring(7);
    })
  });
});