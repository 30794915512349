define('candidio-app/services/messages', ['exports', 'ember-ajax/errors'], function (exports, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
   * Fire on-screen notifications from controllers and routes with `this.messages.showErrors(errors)`
   * or the other available methods.
   *
   * This module uses [ember-cli-growl](https://github.com/ngenworks/ember-cli-growl) to display
   * notifications.
   *
   * Options (with defaults):
   *   {
   *     fadeIn: true,          // Fad new messages into view
   *     closeIn: 5000,         // milliseconds until the message auto-closes
   *     clickToDismiss: false, // If set to `true` the message will not close until it is clicked.
   *     twitch: false          // If set to `true` the message will animate
   *   }
   */

  var Messages = Ember.Service.extend({

    flashMessages: Ember.inject.service(),

    showError: function showError(message) {
      var _this = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (Ember.typeOf(message) === 'string') {
        return this.get('flashMessages').error(message, options);
      } else if (Ember.typeOf(message) === 'object') {
        var msg = Ember.get(message, 'detail.error.message');

        if (msg && Ember.typeOf(msg) === 'string') {
          this.get('flashMessages').error(msg, options);
        } else if (msg && Ember.typeOf(msg) === 'object') {
          _.forEach(message.detail.error.message, function (val) {
            return _.forEach(val, function (msg) {
              return _this.get('flashMessages').error(msg, options);
            });
          });
        }
      }
    },
    showErrors: function showErrors(errors) {
      var _this2 = this;

      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      _.forEach(errors, function (error) {
        _this2.showError(error, options);
      });
    },


    showAPIError: function showAPIError(resp, options) {
      options = options || {};

      options.defaultErrorText = options.defaultErrorText || 'There was a problem on the server, please try again.';

      if ((0, _errors.isAjaxError)(resp)) {
        this.showErrors(resp.errors);
      } else {
        this.showError(options.defaultErrorText);
      }
    },
    showInfo: function showInfo(message, options) {
      options = options || {};

      this.get('flashMessages').info(message, options);
    },
    showAlert: function showAlert(message, options) {
      options = options || {};

      this.get('flashMessages').warning(message, options);
    },
    showSuccess: function showSuccess(message, options) {
      options = options || {};

      this.get('flashMessages').success(message, options);
    }
  });

  exports.default = Messages;
});