define('candidio-app/models/brief', ['exports', 'ember-data', 'model-fragments/attributes'], function (exports, _emberData, _attributes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    purpose: attr('string'),
    purposeBecause: attr('string'),
    endScreen: attr('string'),
    instructions: attr('string'),
    meta: (0, _attributes.fragment)('brief-meta'),
    createdAt: attr('moment-date'),
    updatedAt: attr('moment-date')
  });
});