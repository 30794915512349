define('candidio-app/router', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    tracking: Ember.inject.service(),
    navigation: Ember.inject.service(),
    didTransition: function didTransition() {
      this._trackPage();
      this._super.apply(this, arguments);
    },
    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = document.location.pathname;
        var title = document.title;
        Ember.get(_this, 'tracking').trackPage(['intercom', 'googleAnalytics'], { page: page, title: title });
      });
    }
  });

  Router.map(function () {
    // Sign-in or sign-up related
    this.route('login');
    this.route('forgotten');
    this.route('reset', { path: '/reset/:token' });
    this.route('confirm-email', { path: '/confirm-email/:token' });
    this.route('activate-account', { path: '/activate/:token' });

    // Route only invoked from an invite
    this.route('invite', { path: '/invite/:token' });

    this.route('workspaces', {}, function () {
      this.route('workspace', { path: '/:workspace_id', resetNamespace: true }, function () {
        this.route('video-series', { path: '/video-series' }, function () {
          this.route('video-series', { path: '/:video_series_id' });
        });

        this.route('productions', function () {
          this.route('production', { path: '/:production_id' }, function () {
            this.route('development');
            this.route('pre-production');
            this.route('production');
            this.route('post-production');
            this.route('revision');
            this.route('distribution');

            /** `/review` is kept to prevent email links from breaking */
            this.route('review');
          });
        });

        this.route('my-brand');
        this.route('users');
      });
    });

    // user/profile|notifications
    this.route('user', function () {
      this.route('profile');
      this.route('notifications');
    });

    // Public uploads
    this.route('public-upload', { path: '/public-upload/:production_id' });

    // Administration
    this.route('admin', function () {
      this.route('workspaces', { path: '/workspaces' }, function () {
        this.route('workspace', { path: '/:workspace_id' }, function () {
          this.route('users');
          this.route('notes');
          this.route('templates', function () {
            this.route('new');
            this.route('template', { path: '/:template_id' });
          });
          this.route('video-series', function () {
            this.route('video-series', { path: '/:video_series_id' });
            this.route('new');
          });

          this.route('productions', function () {
            this.route('production', function () {});
          });
        });
      });

      this.route('productions', function () {
        this.route('production', { path: '/:production_id' });
      });

      this.route('users', { path: '/users' }, function () {
        this.route('user', { path: '/:user_id' });
      });

      // admin/templates - list all templates
      this.route('templates', function () {
        this.route('documentation');
        this.route('new');
        this.route('template', { path: '/:template_id' });

        // admin/templates/types - list all template types
        this.route('types', function () {
          this.route('new');
          this.route('type', { path: '/:type_id' });
        });

        // admin/templates/industries - list all industries
        this.route('industries', function () {
          this.route('new');
          this.route('industry', { path: '/:industry_id' });
        });
      });
      this.route('new-producer');
    });

    this.route('invalid', {
      path: '*:'
    });
    this.route('coaching');
  });

  exports.default = Router;
});