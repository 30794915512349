define('candidio-app/controllers/admin/templates/types/type', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    typeName: Ember.computed.alias('model.name'),
    typeDescription: Ember.computed.alias('model.description'),
    typeIcon: Ember.computed.alias('model.icon'),
    typeIsPublished: Ember.computed.alias('model.isPublished'),
    onEdit: true,

    actions: {
      saveTemplateType: function saveTemplateType() {
        var _this = this;

        return this.get('ajax').request((0, _candidioPaths.default)().url.api('template-types', this.get('model.id')), {
          type: 'PUT',
          data: { data: {
              name: this.get('typeName'),
              description: this.get('typeDescription'),
              icon: this.get('typeIcon'),
              is_published: this.get('typeIsPublished')
            } }
        }).then(function () {
          _this.messages.showSuccess('Template type has been updated');
          _this.transitionToRoute('admin.templates.types');
        }).catch(function (errors) {
          _this.messages.showAPIError(errors);
        });
      },
      deleteTemplateType: function deleteTemplateType() {
        var confirm = confirm('Are you absolutely sure you want to delete this template type?');
        if (confirm) {
          this._deleteTemplateType();
        }
      }
    },

    _deleteTemplateType: function _deleteTemplateType() {
      var _this2 = this;

      this.get('model').destroyRecord().then(function () {
        _this2.messages.showSuccess('Template type has been deleted');
        _this2.transitionToRoute('admin.templates.types');
      });
    }
  });
});