define('candidio-app/utils/addweekdays', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = addWeekdays;
  // Source: http://stackoverflow.com/a/20948347/144609
  // with amendments to allow negative days
  function addWeekdays(date, days) {
    var inc = days / Math.abs(days);
    date = moment(date); // use a clone
    while (days !== 0) {
      date = date.add(inc, 'days');
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= inc;
      }
    }
    return date;
  }
});