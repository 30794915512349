define('candidio-app/adapters/description', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/{pathForType}{/id}{?query*}',

    findAllUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/descriptions{?include}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/descriptions{?include}';
      } else {
        return '{+host}/{+namespace/descriptions{?include}';
      }
    }),

    createRecordUrlTemplate: '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/descriptions',

    updateRecordUrlTemplate: '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/descriptions/{id}',

    queryUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/descriptions{?query*}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/descriptions{?query*}';
      } else {
        return '{+host}/{+namespace/descriptions{?query*}';
      }
    }),

    deleteRecordUrlTemplate: Ember.computed('adapterContext.{workspace,production}', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'production')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/productions/{productionId}/descriptions/{id}';
      } else if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/descriptions/{id}';
      } else {
        return '{+host}/{+namespace}/descriptions/{id}';
      }
    }),

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      productionId: function productionId() {
        return this.get('adapterContext.production.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }
  });
});