define('candidio-app/models/video-series', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = _emberData.default.Model.extend({
    audience: attr('string'),
    internalNotes: attr('string'),
    isPublished: attr('boolean'),
    synopsis: attr('string'),
    title: attr('string'),
    meta: attr('json', {
      defaultValue: {
        tone: {
          action: false,
          brief: false,
          clean: false,
          clever: false,
          cool: false,
          emotional: false,
          fancy: false,
          fast: false,
          fresh: false,
          fun: false,
          funny: false,
          happy: false,
          inspiring: false,
          inviting: false,
          long: false,
          mysterious: false,
          powerful: false,
          serious: false,
          slow: false,
          thoughtProvoking: false
        }
      }
    }),
    turnaround: attr('number'),
    videoLength: attr('number'),
    createdAt: attr('moment-date'),
    updatedAt: attr('moment-date'),

    createdBy: belongsTo('user', { async: false }),
    exampleVideo: belongsTo('asset', { async: false }),
    template: belongsTo('template', { async: false }),
    workspace: belongsTo('workspace', { async: false }),
    assets: hasMany('asset', { async: false }),
    collaborators: hasMany('user', { async: false }),
    productions: hasMany('production', { async: false }),

    hasTone: Ember.computed.notEmpty('formattedTone'),
    formattedTone: Ember.computed('meta.tone', function () {
      var result = [];
      _.each(this.get('meta.tone'), function (v, k) {
        if (v) {
          result.push(k);
        }
      });
      if (result.length === 3) {
        return result[0] + ', ' + result[1] + ' and ' + result[2];
      } else if (result.length === 2) {
        return result[0] + ' and ' + result[1];
      } else if (result.length === 1) {
        return '' + result[0];
      } else {
        return null;
      }
    })
  });
});