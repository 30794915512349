define('candidio-app/repositories/base', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BaseRepository = Ember.Object.extend({

    // This must be set in extending classes to query the correct adapter
    resource: null,

    appendResources: function appendResources() {
      this.clearAppendedResources();

      var adapter = this.getAdapter();
      var adapterNamespace = adapter.get('namespace');
      var prefix = [adapterNamespace];

      if (arguments.length) {
        // For each argument, lookup the related id. Push both to the namespace
        for (var i = 0; i < arguments.length; i++) {
          prefix.push(arguments[i]);
        }
        adapter.set('namespace', prefix.join('/'));
      }
    },

    clearAppendedResources: function clearAppendedResources() {
      var adapter = this.getAdapter();
      adapter.set('namespace', _environment.default.APP.API_NAMESPACE);
    },

    setupIncludes: function setupIncludes() {
      this.clearIncludes();

      var adapter = this.getAdapter();

      if (arguments.length && Ember.typeOf(arguments[0]) === 'array') {
        return adapter.set('includes', arguments[0]);
      }

      return adapter.set('includes', this.defaultIncludes);
    },

    clearIncludes: function clearIncludes() {
      var adapter = this.getAdapter();
      adapter.set('includes', []);
    },

    getAdapter: function getAdapter() {
      return this.store.adapterFor(this.get('resource'));
    },
    markModelAsSaved: function markModelAsSaved(model) {
      model.send('willCommit');
      model.set('_attributes', {});
      model.send('didCommit');
    }
  });

  exports.default = BaseRepository;
});