define('candidio-app/controllers/workspace/users', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    adapterContext: Ember.inject.service(),
    ajax: Ember.inject.service(),

    email: null,
    canManage: false,

    invalidEmail: Ember.computed('email', function () {
      return !validator.isEmail(this.get('email'));
    }),

    actions: {
      inviteUser: function inviteUser(callback) {
        var _this = this;

        var promise = this.submitInviteForm();

        callback(promise);

        return promise.then(function (res) {
          var user = _this.store.push(_this.store.normalize('user', res.data));
          _this.get('model').addObject(user);
          _this.resetInviteForm();
        });
      },
      removeUser: function removeUser(user) {
        this.get('model').removeObject(user);
        return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', this.get('current.workspace.id'), 'users', user.get('id'), 'detach'), {
          type: 'DELETE'
        });
      },
      updatePermissions: function updatePermissions(user, canManage) {
        var workspace = this.get('current.workspace');
        this.get('adapterContext').setContext({ workspace: workspace });
        user.set('canManageCurrentWorkspace', canManage);
        return user.savePermissions();
      }
    },

    submitInviteForm: function submitInviteForm() {
      var workspace = this.get('current.workspace');

      var _getProperties = this.getProperties('email', 'canManage'),
          email = _getProperties.email,
          canManage = _getProperties.canManage;

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('workspaces', workspace.get('id'), 'users', 'invite'), {
        type: 'POST',
        data: JSON.stringify({ data: { email: email, can_manage: canManage } })
      });
    },
    resetInviteForm: function resetInviteForm() {
      this.setProperties({
        email: null,
        canManage: false
      });
    }
  });
});