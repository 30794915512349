define('candidio-app/adapters/user', ['exports', 'candidio-app/adapters/application', 'ember-data-url-templates'], function (exports, _application, _emberDataUrlTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(_emberDataUrlTemplates.default, {
    adapterContext: Ember.inject.service(),

    urlTemplate: '{+host}/{+namespace}/users{/id}{?query*}',

    // TODO: Can we switch this to exclude `/invite`?
    createRecordUrlTemplate: '{+host}/{+namespace}/workspaces/{workspaceId}/users/invite',

    findRecordUrlTemplate: '{+host}/{+namespace}/users/{id}{?include}',

    queryUrlTemplate: Ember.computed('adapterContext.workspace', function () {
      var adapterContext = this.get('adapterContext');

      if (Ember.get(adapterContext, 'workspace')) {
        return '{+host}/{+namespace}/workspaces/{workspaceId}/users{?query*}';
      }
      return '{+host}/{+namespace}/users{?query*}';
    }),

    updateRecordUrlTemplate: '{+host}/{+namespace}/users/{id}',

    urlSegments: {
      workspaceId: function workspaceId() {
        return this.get('adapterContext.workspace.id');
      },
      include: function include(type, id, snapshot) {
        var include = Ember.get(snapshot, 'adapterOptions.query.include');
        if (include) {
          return include;
        }
      }
    }
  });
});