define('candidio-app/components/candidio-shot-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['content-item', 'content-item--shot-list'],
    classNameBindings: ['contentType'],

    // This flag used to disabled all editing of this shot
    disabled: false,

    isInterview: Ember.computed.equal('contentType', 'interview'),

    isBroll: Ember.computed.equal('contentType', 'broll'),

    actions: {

      openModal: function openModal(modalDescription, model) {
        this.sendAction('openModal', modalDescription, model); // eslint-disable-line
      },

      saveDescription: function saveDescription() {
        this.sendAction('save', this.get('description')); // eslint-disable-line
      },

      deleteDescription: function deleteDescription() {
        if (confirm("Are you sure you want to delete this shot?")) {
          this.sendAction('delete', this.get('description')); // eslint-disable-line
        }
      }
    }
  });
});