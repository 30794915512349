define('candidio-app/controllers/public-upload', ['exports', 'candidio-app/utils/candidio-paths'], function (exports, _candidioPaths) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    ajax: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    tracking: Ember.inject.service(),

    hasAssets: false,

    processingUpload: false,

    sortKey: ['position'],
    sortedDescriptions: Ember.computed.sort('descriptions', 'sortKey'),

    actions: {

      addToDescriptionUploaded: function addToDescriptionUploaded(description, asset) {
        description.get('assets').addObject(asset);
      },

      uploadFiles: function uploadFiles(files, description) {
        var _this = this;

        _.forEach(files, function (file) {
          return _this.uploadFile(file, description);
        });
      }
    },

    uploadFile: function uploadFile(file, description) {
      var _this2 = this;

      var path = this.get('model.workspace.id') + '/' + this.get('model.id') + '/assets';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, { model: description }, {
        onComplete: function onComplete(res) {
          return _this2.completeFileUpload(res, description);
        }
      });
    },
    completeFileUpload: function completeFileUpload(payload, description) {
      var _this3 = this;

      this.store.createRecord('asset', {
        description: description,
        fileName: payload.file_name,
        fileType: payload.file_type,
        size: payload.size,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype
      });

      return this.get('ajax').request((0, _candidioPaths.default)().url.api('public', 'assets'), {
        type: 'POST',
        data: JSON.stringify({
          data: Ember.merge(payload, {
            production_id: this.get('model.id'),
            workspace_id: this.get('model.workspace.id'),
            description_id: description.get('id')
          })
        })
      }).then(function () {
        _this3.get('tracking').trackEvent({
          event: 'Uploaded Crowdsourced Asset',
          category: 'production',
          action: 'uploaded-crowdsourced-asset',
          location: 'public-upload',
          productionTitle: _this3.get('model.title'),
          shot: description.get('fields.field1')
        });
      });
    }
  });
});