define('candidio-app/utils/candidio-paths', ['exports', 'candidio-app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var makeRoute = function makeRoute(root, args) {
    var parts = Array.prototype.slice.call(args, 0).join('/'),
        route = [root, parts].join('/');

    if (route.slice(-1) !== '/') {
      route += '/';
    }

    return route;
  };

  function candidioPaths() {
    var apiHost = _environment.default.APP.API_HOST,
        apiNamespace = _environment.default.APP.API_NAMESPACE,
        apiRoot = apiHost + '/' + apiNamespace;

    return {
      apiRoot: apiRoot,

      url: {
        api: function api() {
          return makeRoute(apiRoot, arguments);
        }
      }
    };
  }

  exports.default = candidioPaths;
});