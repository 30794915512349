define("candidio-app/serializers/brief-meta", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    attrs: {
      lengthAdjustment: "length_adjustment",
      lengthReason: "length_reason"
    }
  });
});