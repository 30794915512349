define('candidio-app/controllers/modals/asset-preview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AssetPreviewController = Ember.Controller.extend({

    asset: Ember.computed.alias('model')

  });

  exports.default = AssetPreviewController;
});