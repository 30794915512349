define('candidio-app/blueprints/ember-string-helpers', ['exports', 'ember-string-helpers/blueprints/ember-string-helpers'], function (exports, _emberStringHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberStringHelpers.default;
    }
  });
});