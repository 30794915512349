define('candidio-app/components/file-upload-progress-component', ['exports', 'candidio-app/utils/upload-progress'], function (exports, _uploadProgress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['c__file-upload-progress', 'z__2'],
    classNameBindings: ['showFileInfo:c__file-upload-progress--has-info'],

    /** @type {Boolean} Setting to true will show file info and progress next to thumb */
    showFileInfo: false,

    loaded: Ember.computed.readOnly('uploader.progress.loaded'),
    total: Ember.computed.readOnly('uploader.progress.total'),
    file: Ember.computed.readOnly('uploader.uploader.file'),

    /** Establish the filetype */
    mimeType: Ember.computed.readOnly('file.type'),
    isVideo: Ember.computed.match('mimeType', /^video\//),
    isAudio: Ember.computed.match('mimeType', /^audio\//),
    isImage: Ember.computed.match('mimeType', /^image\//),

    icon: Ember.computed(function () {
      if (this.get('isVideo')) {
        return 'file-video';
      }
      if (this.get('isAudio')) {
        return 'file-music';
      }
      if (this.get('isImage')) {
        return 'file-photo';
      }
      return 'file-new';
    }),

    progress: Ember.computed('loaded', 'total', function () {
      return (0, _uploadProgress.default)(this.get('loaded'), this.get('total'));
    }),

    formattedProgress: Ember.computed('progress', function () {
      return this.get('progress') + '%';
    }),

    progressMeterWidth: Ember.computed('progress', function () {
      return new Ember.String.htmlSafe("width: " + this.get('progress') + '%');
    }),

    actions: {
      cancel: function cancel() {
        this.get('uploader.uploader').cancelUpload();
      }
    }

  });
});