define('candidio-app/adapters/action', ['exports', 'candidio-app/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // buildURL: function(type, id, record) {
    //   var url = this._super(type, id, record);
    //   return url + '?include=workspace,user';
    // }
  });
});