define('candidio-app/components/candidio-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['tooltip'],

    classNameBindings: ['underlined'],

    underlined: false,

    attributeBindings: ['title'],

    updateTitle: Ember.observer('title', function () {
      this.$().tooltipster('content', this.get('title'));
    }),

    /**
       * Set the position of the tooltip.
       * Default: 'top'
       * Options: [right, left, top, top-right, top-left, bottom, bottom-right, bottom-left]
       * @type {String}
       */
    position: 'top',

    /**
     * Determines how the tooltip will animate in and out.
     * Default: 'fade'
     * Options: [fade, grow, swing, slide, fall]
     * @type {String}
     */
    animation: 'fade',

    /**
     * Delay how long it takes (in milliseconds) for the tooltip to start animating in.
     * Default: 200
     * @type {Number}
     */
    delay: 300,

    /**
     * Set the theme used for your tooltip.
     * Default: 'tooltipster-default'
     * @type {String}
     */
    theme: 'candidio-tooltip',

    /**
     * Set how tooltips should be activated and closed.
     * Default: 'hover'
     * Options: [hover, click]
     * @type {String}
     */
    triggerEvent: 'hover',

    didInsertElement: function didInsertElement() {
      this._initializeTooltipster();
    },


    _initializeTooltipster: function _initializeTooltipster() {
      var self = this,
          tooltipOptions = {
        position: self.get('position'),
        animation: self.get('animation'),
        delay: self.get('delay'),
        theme: self.get('theme'),
        trigger: self.get('triggerEvent')
      };

      this.$().tooltipster(tooltipOptions);
    }

  });
});