define('candidio-app/routes/workspace/video-series/index', ['exports', 'ember-infinity/mixins/route', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _route, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _route.default, {
    adapterContext: Ember.inject.service(),

    perPageParam: 'limit',
    pageParam: 'page',
    totalPagesParam: 'meta.pagination.total_pages',

    model: function model() {
      var workspace = this.modelFor('workspace');
      this.get('adapterContext').setContext({ workspace: workspace });

      return this.infinityModel('videoSeries', {
        startingPage: 1,
        perPage: 5,
        sort: 'title',
        direction: 'asc' //,
        //include: [
        // 'assets',
        // 'created_by',
        // 'example_video',
        // 'collaborators',
        //'productions'
        //].join(',')
      });
    },

    activate: function activate() {
      /*
        We have to set the current workspace here, because when transitioning from
        a child route of the workspace, we may clear the workspace.
      */
      var workspace = this.modelFor('workspace');
      this.get('current').setCurrent({ workspace: workspace });
    }
  });
});