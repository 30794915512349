define('candidio-app/validators/reset', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var ResetValidator = Ember.Object.create({
        check: function check(model) {
            var data = model.getProperties('password', 'passwordConfirmation'),
                validationErrors = [];

            if (!validator.isLength(data.password, 8)) {
                validationErrors.push({
                    message: 'Password must be at least 8 characters long.'
                });
            }

            if (!validator.isLength(data.passwordConfirmation, 8)) {
                validationErrors.push({
                    message: "Please confirm your new password."
                });
            }

            if (!validator.matches(data.password, data.passwordConfirmation)) {
                validationErrors.push({
                    message: "Your passwords don't match."
                });
            }

            return validationErrors;
        }
    });

    exports.default = ResetValidator;
});