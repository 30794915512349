define('candidio-app/routes/workspace/productions/production/revision', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    adapterContext: Ember.inject.service(),
    scottyUploader: Ember.inject.service(),
    tracking: Ember.inject.service(),

    model: function model() {
      return this.modelFor('workspace.productions.production').get('latestRevision');
    },
    completeFileUpload: function completeFileUpload(payload, revision) {
      var _this = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');

      var asset = this.store.createRecord('asset', {
        bucket: payload.bucket,
        fileName: payload.file_name,
        fileType: payload.file_type,
        linkDownload: payload.file_path,
        mimetype: payload.mimetype,
        objectKey: payload.object_key,
        size: payload.size,
        revision: revision,
        workspace: workspace
      });

      this.get('adapterContext').setContext({ workspace: workspace });
      return asset.save().then(function (asset) {
        _this.get('tracking').trackEvent({
          event: 'Uploaded Asset to Revision',
          category: 'asset',
          action: 'uploaded',
          production: _this.generateProductionTrackingObject(production),
          mimetype: asset.get('mimetype'),
          size: asset.get('size')
        });
      }).catch(function (errors) {
        return _this.messages.showAPIError(errors);
      });
    },
    deleteAsset: function deleteAsset(asset) {
      var _this2 = this;

      if (!confirm('Are you sure you want to delete this file?')) {
        return false;
      }
      var workspace = this.modelFor('workspace'),
          production = this.modelFor('workspace.productions.production');

      this.get('adapterContext').setContext({ workspace: workspace });

      return asset.destroyRecord().then(function () {
        _this2.get('tracking').trackEvent({
          event: 'Deleted Asset',
          category: 'revision',
          action: 'deleted-asset',
          location: 'revision',
          production: _this2.generateProductionTrackingObject(production)
        });
      }).catch(function (errors) {
        return _this2.messages.showAPIError(errors);
      });
    },
    generateProductionTrackingObject: function generateProductionTrackingObject(production) {
      return {
        value: production.get('title'),
        url: '' + window.location.origin + this.router.generate('admin.productions.production', production)
      };
    },
    submitRevision: function submitRevision() {
      var _this3 = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');
      var revision = this.modelFor(this.routeName);
      revision.set('isOpen', false);
      this.get('adapterContext').setContext({ workspace: workspace, production: production });
      return revision.save().then(function () {
        _this3.get('tracking').trackEvent({
          event: 'Submitted Revision',
          category: 'revision',
          action: 'submitted-revision',
          location: 'revision',
          production: _this3.generateProductionTrackingObject(production)
        });
        _this3.transitionTo('workspace.productions.production.post-production');
      }).catch(function (errors) {
        return _this3.messages.showAPIError(errors);
      });
    },
    uploadFile: function uploadFile(file, revision) {
      var _this4 = this;

      var workspace = this.modelFor('workspace');
      var production = this.modelFor('workspace.productions.production');
      var path = workspace.get('id') + '/' + production.get('id') + '/assets';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, { model: revision }, {
        onComplete: function onComplete(res) {
          return _this4.completeFileUpload(res, revision);
        },
        onError: function onError() {
          return _this4.messages.showError('Error uploading your file.');
        }
      });
    },


    actions: {
      deleteAsset: function deleteAsset(asset) {
        return this.deleteAsset(asset);
      },
      submitRevision: function submitRevision(callback) {
        return callback(this.submitRevision());
      },
      uploadFiles: function uploadFiles(files, revision) {
        var _this5 = this;

        _.forEach(files, function (file) {
          return _this5.uploadFile(file, revision);
        });
      }
    }

  });
});