define('candidio-app/controllers/admin/templates/template', ['exports', 'candidio-app/mixins/description-sorting'], function (exports, _descriptionSorting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_descriptionSorting.default, {

    scottyUploader: Ember.inject.service(),

    name: Ember.computed.alias('model.name'),
    description: Ember.computed.alias('model.description'),
    template: Ember.computed.alias('model.template'),
    type: Ember.computed.alias('model.templateType'),
    industry: Ember.computed.alias('model.industry'),
    workspace: Ember.computed.readOnly('model.workspace'),
    isPublished: Ember.computed.alias('model.isPublished'),
    editorPick: Ember.computed.alias('model.editorPick'),
    imageUrl: Ember.computed.alias('model.imageUrl'),
    onEdit: true,

    shots: Ember.computed.alias('template.descriptions'),

    sortedShots: Ember.computed.alias('template.descriptions'),

    init: function init() {
      this._super.apply(this, arguments);
      this.descriptionTypes = [{ text: "B-Roll", id: "broll" }, { text: "Interview", id: "interview" }];
    },


    actions: {
      addShot: function addShot() {
        this.get('template.descriptions').addObject({
          type: 'broll',
          fields: {
            field1: '',
            field2: '',
            meta: []
          }
        });
      },
      movedShot: function movedShot(shot, oldIndex, newIndex) {
        var shots = this.get('template.descriptions');
        shots.removeAt(oldIndex);
        shots.insertAt(newIndex, shot);
      },
      removeShot: function removeShot(shot) {
        this.get('template.descriptions').removeObject(shot);
      },
      uploadFiles: function uploadFiles(files) {
        var _this = this;

        _.forEach(files, function (file) {
          return _this.uploadFile(file);
        });
      },
      removeImage: function removeImage() {
        this.set('imageUrl', null);
      },


      /*
        Override the same method on the DescriptionSortingMixin so position isn't saved
       */
      moveDescription: function moveDescription() {
        return;
      },
      saveTemplate: function saveTemplate(callback) {
        var _this2 = this;

        // Ensure all description positions are set

        // let data = this.getProperties('name', 'description', 'template', 'imageUrl',
        //   'type', 'industry', 'isPublished', 'editorPick');
        // let template = this.repository.template.saveTemplate(this.get('model.id'), {
        //   name: data.name,
        //   description: data.description,
        //   template: data.template,
        //   isPublished: data.isPublished,
        //   editorPick: data.editorPick,
        //   imageUrl: data.imageUrl,
        //   templateType: data.type,
        //   industry: data.industry
        // });
        var template = this.get('model').save();

        callback(template);

        return template.then(function () {
          _this2.messages.showSuccess('Template saved');
          _this2.transitionToRoute('admin.templates');
        }).catch(function (errors) {
          return _this2.messages.showAPIError(errors);
        });
      },
      destroy: function destroy() {
        var _this3 = this;

        if (confirm('Are you absolutely sure you want to delete this template?')) {
          return this.get('model').destroyRecord().then(function () {
            _this3.messages.showSuccess('Template has been deleted');
            _this3.transitionToRoute('admin.templates');
          }).catch(function (errors) {
            return _this3.messages.showAPIError(errors);
          });
        }
      }
    },

    uploadFile: function uploadFile(file) {
      var _this4 = this;

      var path = 'template-images';
      return Ember.get(this, 'scottyUploader').uploadFile(file, path, {}, {
        onComplete: function onComplete(res) {
          return _this4.completeFileUpload(res);
        }
      });
    },
    completeFileUpload: function completeFileUpload(res) {
      this.set('model.imageUrl', res.file_path);
    }
  });
});